import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import {RouteProps} from "react-router";
import {BookingQueryType, BookingsApi, GetBookingsResponse, StandardUser, Token} from "client";
import {defaultFrontendPagination, FrontendPaginationWithRenderKey} from "../components/tables/FrameOnePaginator";
import {addError} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageTabSwitcher from "../components/PageTabSwitcher";
import {useHistory} from "react-router-dom";
import LocalLoader from "../components/LocalLoader";
import OutsideButton2 from "../components/buttons/OutsideButton2";
import UserUpcomingCalendarList from "../components/calendars/UserUpcomingCalendarList";

const userBookingTypeMap: {[key: string]: Array<BookingQueryType>} = {
	upcoming: [
		BookingQueryType.UPCOMING,
		BookingQueryType.CURRENT,
	],
	history: [
		BookingQueryType.PAST,
	],
}

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: StandardUser;
}

/**
 * Essentially a copy of the PartnerCalendarPage; take a look at that component for thorough comments/documentation on this system.
 *
 * @param props
 * @constructor
 */
const UserCalendarPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const listType: string = query.get("list");

	const [bookings, setBookings] = useState<GetBookingsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPaginationWithRenderKey>(undefined);
	const [localLoading, setLocalLoading] = useState(false);

	useEffect(() => {
		if (
			listType !== "upcoming" &&
			listType !== "history" &&
			listType !== "current"
		) {
			history.replace("/user/calendar?list=upcoming");
		} else if (bookings !== undefined) {
			setBookings(undefined);
		}
	}, [listType]);

	useEffect(() => {
		if (bookings === undefined) {
			setFrontendPagination({
				...defaultFrontendPagination,
				key: frontendPagination?.key !== undefined ? frontendPagination?.key + 1 : 0,
			});
		}
	}, [bookings]);

	useEffect(() => {
		if (frontendPagination) {
			getCalendarData().then().catch();
		}
	}, [JSON.stringify(frontendPagination)]);

	async function getCalendarData(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new BookingsApi(getConfig(props.fullToken)).getOwnBookings({
				limit: 10,
				offset: frontendPagination.offset,
				type: userBookingTypeMap[listType],
			});

			if (bookings === undefined) {
				setBookings(res)
			} else {
				setBookings({
					...res,
					bookings: bookings.bookings.concat(res.bookings),
				});
			}
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	function onLoadMore(): void {
		setFrontendPagination({
			...frontendPagination,
			offset: frontendPagination.offset + 1,
		});
	}

	return (
		<Container className="authenticated-user-page">
			<h1>Calendar</h1>

			<PageTabSwitcher
				disabled={localLoading}
				tabs={[
					{
						label: "Upcoming",
						route: "/user/calendar?list=upcoming",
						selected: listType === "upcoming",
					},
					{
						label: "History",
						route: "/user/calendar?list=history",
						selected: listType === "history",
					},
				]}
			/>

			{(bookings === undefined && localLoading) && (
				<LocalLoader/>
			)}

			{bookings !== undefined && (
				<React.Fragment>
					{bookings?.bookings?.length > 0 ? (
						<React.Fragment>
							<UserUpcomingCalendarList bookings={bookings.bookings}/>

							{localLoading && (
								<LocalLoader/>
							)}

							<div className="calendar-single-button-controller">
								<OutsideButton2
									color={bookings?.paginationInfo?.enableNext ? "forestGreen" : "offWhite2"}
									outline={!bookings?.paginationInfo?.enableNext}
									onClick={onLoadMore}
									disabled={localLoading || !bookings?.paginationInfo?.enableNext}
								>
									{bookings?.paginationInfo?.enableNext ? "View More" : "End of List"}
								</OutsideButton2>
							</div>
						</React.Fragment>
					) : (
						<p className="empty-message my-5">
							No Data.
						</p>
					)}
				</React.Fragment>
			)}
		</Container>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser as StandardUser,
		...props,
	}
})(UserCalendarPage);
