"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetToJSON = exports.AssetFromJSONTyped = exports.AssetFromJSON = void 0;
const runtime_1 = require("../runtime");
function AssetFromJSON(json) {
    return AssetFromJSONTyped(json, false);
}
exports.AssetFromJSON = AssetFromJSON;
function AssetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'fileName': json['fileName'],
        'url': json['url'],
        'pipeURL': json['pipeURL'],
        'sourcePipeURL': json['sourcePipeURL'],
        'redirectURL': json['redirectURL'],
        'sourceRedirectURL': json['sourceRedirectURL'],
        'base64URL': json['base64URL'],
        'sourceBase64URL': json['sourceBase64URL'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'contentType': json['contentType'],
        'size': json['size'],
    };
}
exports.AssetFromJSONTyped = AssetFromJSONTyped;
function AssetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'fileName': value.fileName,
        'url': value.url,
        'pipeURL': value.pipeURL,
        'sourcePipeURL': value.sourcePipeURL,
        'redirectURL': value.redirectURL,
        'sourceRedirectURL': value.sourceRedirectURL,
        'base64URL': value.base64URL,
        'sourceBase64URL': value.sourceBase64URL,
        'name': value.name,
        'description': value.description,
        'contentType': value.contentType,
        'size': value.size,
    };
}
exports.AssetToJSON = AssetToJSON;
