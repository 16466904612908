import React from "react";
import { Link } from "react-router-dom";
import OutsideActivitiesButton from "../components/OutsideActivitiesButton";
import {RiCheckboxCircleFill} from "react-icons/all";

interface IProps {

}

const ResetSuccessfulPage: React.FC<IProps> = (props) => {

    return (
        <div className="reset-successful-page">
            <div className="reset-successful-page_container">

                <RiCheckboxCircleFill
                    color="forestGreen"
                    size={65}
                />

                <h1>Password reset successful!</h1>

                <p>
                    Click the button below to continue to the login page.
                </p>

                <div className="reset-successful-page_container">
                    <div className="reset-successful-page_container_login-button">
                        <OutsideActivitiesButton color="forestGreen">
                            <Link to="/">Go to Log in page</Link>
                        </OutsideActivitiesButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetSuccessfulPage;
