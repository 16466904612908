import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CreateEquipmentBody, EquipmentApi, PhoneNumberBody, Token} from "client";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import {Input, Label} from "reactstrap";
import OutsideActivitiesRadio from "../OutsideActivitiesRadio";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import GooglePlacesInput from "../inputs/GooglePlacesInput";
import CompanyPhoto from "../CompanyPhoto";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import FileInputButton from "../inputs/FileInputButton";
import AddEquipmentAdditionalImagesGallery from "./modalComponents/AddEquipmentAdditionalImagesGallery";
import {cloneDeep} from "lodash";
import DynamicStringEntry from "../inputs/DynamicStringEntry";

export interface ICreateEquipmentBodyFrontend extends Omit<CreateEquipmentBody, "attributes" |"thumbnailID" | "imageIDs"> {
	attributes: Array<[string, string]>;
	thumbnail: FileWithSRC;
	images: Array<FileWithSRC>;
}

export const defaultCreateEquipmentForm: ICreateEquipmentBodyFrontend = {
	isConsumable: false,
	quantity: undefined,
	availability: [],
	name: "",
	description: "",
	instructions: "",
	placeID: undefined,
	attributes: [["", ""]],
	thumbnail: undefined,
	images: [],
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const PartnerInventoryAddStockModal: React.FC<IProps> = (props) => {

	const [createForm, setCreateForm] = useState<ICreateEquipmentBodyFrontend>(defaultCreateEquipmentForm);

	/**
	 * Reset the form and call the function from props to close the modal.
	 *
	 */
	function closeHelper(): void {
		setCreateForm(defaultCreateEquipmentForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof ICreateEquipmentBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateForm({
				...createForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Custom onChange for the places autocomplete because of how we return the data from that component.
	 *
	 * @param placeID
	 */
	function setPlaceID(placeID: string): void {
		setCreateForm({
			...createForm,
			placeID: placeID,
		});
	}

	/**
	 * onChange for the consumable radio buttons.
	 *
	 * @param _consumable
	 */
	function consumableOnChange(_consumable: boolean): void {
		setCreateForm({
			...createForm,
			isConsumable: _consumable,
		});
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data for the quantity.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setCreateForm({
				...createForm,
				quantity: values.floatValue,
			});
		}
	}

	/**
	 * Custom onChange for the thumbnail input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onThumbnailChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setCreateForm({
			...createForm,
			thumbnail: (await addURLsToFiles(e?.target.files))[0],
		});
	}

	/**
	 * Custom onChange for the additional images input, to add usable URL.
	 *
	 * @param e
	 */
	async function onAdditionalImagesChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newImages = await addURLsToFiles(e?.target.files);

		setCreateForm({
			...createForm,
			images: createForm.images.concat(newImages),
		});
	}

	/**
	 * Splice the selected image from the array and update state.
	 *
	 * @param index
	 */
	function onRemoveAdditionalImage(index: number): void {
		const imagesCopy: Array<FileWithSRC> = cloneDeep(createForm?.images);
		imagesCopy.splice(index, 1);

		setCreateForm({
			...createForm,
			images: imagesCopy,
		});
	}

	// /**
	//  * Set new attributes.
	//  *
	//  * @param newAttribute
	//  */
	// function handleAddAttribute(newAttribute: string): void {
	// 	const clonedAttributes: { [key: string]: string; } = cloneDeep(createForm.attributes);
	// 	clonedAttributes[newAttribute] = newAttribute;
	//
	// 	setCreateForm({
	// 		...createForm,
	// 		attributes: clonedAttributes,
	// 	});
	// }
	//
	// /**
	//  * Delete the specified attribute.
	//  *
	//  * @param _attribute
	//  */
	// function handleRemoveAttribute(_attribute: string): void {
	// 	const clonedAttributes: { [key: string]: string; } = cloneDeep(createForm.attributes);
	// 	delete clonedAttributes[_attribute];
	//
	// 	setCreateForm({
	// 		...createForm,
	// 		attributes: clonedAttributes,
	// 	});
	// }

	/**
	 * Convert the assets to images in our system, then submit the createEquipment api with the returned asset ids,
	 * reset the form and call the onDone function from props on success.
	 *
	 * @param e
	 */
	async function submitNewStock(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new EquipmentApi(getConfig(props.fullToken)).createEquipment({
				createEquipmentBody: {
					isConsumable: createForm.isConsumable,
					quantity: createForm.quantity || undefined,
					availability: createForm.availability,
					name: createForm.name || undefined,
					description: createForm.description || undefined,
					instructions: createForm.instructions || undefined,
					placeID: createForm.placeID,
					attributes: {}, // todo
					thumbnailID: undefined, //todo
					imageIDs: undefined, // todo
				},
			});

			setCreateForm(defaultCreateEquipmentForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				title="Add new stock"
				toggle={closeHelper}
			/>

			<form onSubmit={submitNewStock}>
				<FrameModalBody>
					<div className="mb-3">
						<Label>
							Thumbnail
						</Label>
						<div className="d-flex flex-column align-items-center">
							<CompanyPhoto
								src={createForm.thumbnail && isFileWithSRC(createForm.thumbnail) && createForm.thumbnail.imageSRC as string}
								alt="Partner image"
								className="w-50 w-sm-25 mb-3"
							/>

							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={false}
								onChange={onThumbnailChange}
							>
								<OutsideActivitiesButton
									color="darkPurple"
									outline={true}
								>
									Upload Image
								</OutsideActivitiesButton>
							</FileInputButton>
						</div>
					</div>

					<Label>
						Name*
					</Label>
					<Input
						placeholder="Equipment Name..."
						value={createForm.name}
						onChange={dynamicOnChange("name")}
						className="mb-3"
					/>

					<Label>
						Description*
					</Label>
					<Input
						placeholder="Equipment Description..."
						value={createForm.description}
						onChange={dynamicOnChange("description")}
						className="mb-3"
					/>

					<div className="mb-3">
						<Label>
							Consumability*
						</Label>
						<OutsideActivitiesRadio
							<boolean>
							name="add-equipment-consumable-yes"
							onToggle={consumableOnChange}
							checked={createForm.isConsumable}
							value={true}
							className="mb-2"
						>
							Yes, this item is consumable
						</OutsideActivitiesRadio>
						<OutsideActivitiesRadio
							<boolean>
							name="add-equipment-consumable-no"
							onToggle={consumableOnChange}
							checked={!createForm.isConsumable}
							value={false}
						>
							No, this item is not consumable
						</OutsideActivitiesRadio>
					</div>

					<Label>
						Quantity*
					</Label>
					<NumberFormat
						placeholder="Equipment Quantity..."
						value={createForm.quantity}
						customInput={Input}
						allowNegative={false}
						decimalScale={0}
						onValueChange={numberFormatOnChange("nationalNumber")}
						className="mb-3"
					/>

					<Label>
						Availability*
					</Label>
					<p>
						TODO
					</p>

					<div className="mb-3">
						<Label>
							Location
						</Label>
						<GooglePlacesInput
							initialInputValue={createForm?.placeID}
							setPlaceID={setPlaceID}
						/>
					</div>

					<Label>
						Instructions
					</Label>
					<Input
						type="textarea"
						placeholder="Equipment Instructions..."
						value={createForm.instructions}
						onChange={dynamicOnChange("instructions")}
						className="mb-3"
					/>

					<div className="mb-3">
						<Label>
							Attributes
						</Label>
						{/*<DynamicStringEntry*/}
						{/*	placeholder="Enter Attributes..."*/}
						{/*	onAdd={handleAddAttribute}*/}
						{/*/>*/}
					</div>

					<div>
						<Label>
							Additional Images
						</Label>
						{(createForm?.images && createForm.images.length > 0) ? (
							<AddEquipmentAdditionalImagesGallery
								images={createForm.images}
								onClick={onRemoveAdditionalImage}
							/>
						) : (
							<p className="text-center mb-0 font-italic">
								No Additional Images.
							</p>
						)}

						<div className="d-flex flex-column align-items-center mt-3">
							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={true}
								onChange={onAdditionalImagesChange}
							>
								<OutsideActivitiesButton
									color="darkPurple"
									outline={true}
								>
									Add Images
								</OutsideActivitiesButton>
							</FileInputButton>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<OutsideActivitiesButton
						outline={true}
						color="grayscale200"
						onClick={closeHelper}
					>
						Cancel
					</OutsideActivitiesButton>

					<OutsideActivitiesButton
						type="submit"
						color="safetyOrange"
						onClick={submitNewStock}
					>
						Save and add
					</OutsideActivitiesButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(PartnerInventoryAddStockModal);
