"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCompaniesResponseToJSON = exports.GetCompaniesResponseFromJSONTyped = exports.GetCompaniesResponseFromJSON = void 0;
const _1 = require("./");
function GetCompaniesResponseFromJSON(json) {
    return GetCompaniesResponseFromJSONTyped(json, false);
}
exports.GetCompaniesResponseFromJSON = GetCompaniesResponseFromJSON;
function GetCompaniesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'companies': (json['companies'].map(_1.CompanyFromJSON)),
    };
}
exports.GetCompaniesResponseFromJSONTyped = GetCompaniesResponseFromJSONTyped;
function GetCompaniesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'companies': (value.companies.map(_1.CompanyToJSON)),
    };
}
exports.GetCompaniesResponseToJSON = GetCompaniesResponseToJSON;
