import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Equipment, EquipmentApi, GetEquipmentListResponse, PartnerAdmin, Token, User} from "client";
import {Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../../components/tables/FrameOnePaginator";
import PartnerInventoryAddStockModal from "../../components/modals/PartnerInventoryAddStockModal";
import { Link } from "react-router-dom";
import PartnerInventoryActionsCell from "../../components/tables/cells/PartnerInventoryActionsCell";
import PartnerInventoryItemNameCell from "../../components/tables/cells/PartnerInventoryItemNameCell";
import OutsideTableContainer from "../../components/tables/OutsideTableContainer";
import OutsideButton2 from "../../components/buttons/OutsideButton2";
import PartnerInventoryAvailabilityCell from "../../components/tables/cells/PartnerInventoryAvailabilityCell";
import PartnerInventoryAttributesCell from "../../components/tables/cells/PartnerInventoryAttributesCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: PartnerAdmin;
}

const PartnerInventoryPage: React.FC<IProps> = (props) => {

	const [equipment, setEquipment] = useState<GetEquipmentListResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		getEquipmentInventory().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Show or hide the Add Equipment modal.
	 *
	 */
	function toggleShowAddModal(): void {
		setShowAddModal(!showAddModal);
	}

	/**
	 * When done adding new equipment, close the modal and call the api again.
	 *
	 */
	function onDoneAddModal(): void {
		setShowAddModal(false);
		getEquipmentInventory().then().catch();
	}

	/**
	 * Call api to get list of equipment for Partner that the signed-in Partner Admin belongs to, save to state.
	 *
	 */
	async function getEquipmentInventory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentListForPartner({
				id: props.currentUser?.partner?._id,
				...frontendPagination,
			});

			setEquipment(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Renderer for the item name & image column.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderNameCell(value: never, equipment: Equipment): ReactNode {
		return (
			<PartnerInventoryItemNameCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the Scheduling/Availability cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderAvailabilityCell(value: never, equipment: Equipment): ReactNode {
		return (
			<PartnerInventoryAvailabilityCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the Attributes cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderAttributesCell(value: never, equipment: Equipment): ReactNode {
		return (
			<PartnerInventoryAttributesCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the action column for viewing & editing.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderActionCell(value: never, equipment: Equipment): ReactNode {
		return (
			<PartnerInventoryActionsCell equipment={equipment}/>
		);
	}

	return (
		<React.Fragment>
			<PartnerInventoryAddStockModal
				isOpen={showAddModal}
				onClose={toggleShowAddModal}
				onDone={onDoneAddModal}
			/>

			<Container className="authenticated-user-page">
				<div className="table-page-header-row" style={{marginBottom: "5rem !important"}}>
					<h1 className="table-page-header-row_title">Inventory</h1>

					<Link to="/partner/inventory/add">
						<OutsideButton2 color="safetyOrange">
							Add new item stock
						</OutsideButton2>
					</Link>
				</div>

				<div className="partner-inventory-page_table-container">
					<OutsideTableContainer
						data={equipment?.equipment}
						pagination={{
							...equipment?.paginationInfo,
							...frontendPagination,
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "",
								headerValue: "Item Name",
								showSortIcons: false,
								sortable: false,
								cellRender: renderNameCell,
							},
							{
								key: "quantity",
								headerValue: "Stock Count",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "availability",
								headerValue: "Scheduling",
								showSortIcons: false,
								sortable: false,
								cellRender: renderAvailabilityCell,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "justify-content-end",
							},
							{
								key: "attributes",
								headerValue: "Attributes",
								showSortIcons: false,
								sortable: false,
								cellRender: renderAttributesCell,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "justify-content-end",
							},
							{
								key: "",
								headerValue: "Actions",
								showSortIcons: false,
								sortable: false,
								cellRender: renderActionCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
						]}
					/>
				</div>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser as PartnerAdmin,
		...props,
	}
})(PartnerInventoryPage);
