"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerOptionToJSON = exports.AnswerOptionFromJSONTyped = exports.AnswerOptionFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AnswerOptionFromJSON(json) {
    return AnswerOptionFromJSONTyped(json, false);
}
exports.AnswerOptionFromJSON = AnswerOptionFromJSON;
function AnswerOptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'answer': json['answer'],
        'points': json['points'],
        'characteristic': !runtime_1.exists(json, 'characteristic') ? undefined : _1.CharacteristicFromJSON(json['characteristic']),
    };
}
exports.AnswerOptionFromJSONTyped = AnswerOptionFromJSONTyped;
function AnswerOptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'answer': value.answer,
        'points': value.points,
        'characteristic': _1.CharacteristicToJSON(value.characteristic),
    };
}
exports.AnswerOptionToJSON = AnswerOptionToJSON;
