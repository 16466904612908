import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Input, Label} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {CompanyAdmin, CreateStandardUserBody, PhoneNumberBody, Token, User, UsersApi} from "client";
import getConfig from "../../utils/getConfig";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {AiOutlineInfoCircle} from "react-icons/all";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import LabelWithIcon from "../LabelWithIcon";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import NumberFormat, {NumberFormatValues} from "react-number-format";

const defaultAddNewUserForm: CreateStandardUserBody = {
	companyID: "",
	firstName: "",
	lastName: "",
	email: "",
	phoneNumberBody: {
		countryCode: undefined,
		nationalNumber: "",
	},
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: CompanyAdmin;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const AddNewUserModal: React.FC<IProps> = (props: IProps) => {

	const [newUser, setNewUser] = useState<CreateStandardUserBody>(defaultAddNewUserForm);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setNewUser(defaultAddNewUserForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for updating the User
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof CreateStandardUserBody): (e?) => void {
		return (e) => {
			setNewUser({
				...newUser,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param dialCodePart
	 */
	function onCountryCodeChange(dialCodePart: string): void {
		setNewUser({
			...newUser,
			phoneNumberBody: {
				...newUser.phoneNumberBody,
				countryCode: dialCodePart,
			},
		});
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param values
	 */
	function onNationalNumberChange(values: NumberFormatValues): void {
		setNewUser({
			...newUser,
			phoneNumberBody: {
				...newUser.phoneNumberBody,
				nationalNumber: values.value,
			},
		});
	}

	/**
	 * Dynamic onChange for updating the User's phone number
	 *
	 * @param key
	 */
	function onChangeForPhoneNumber(key: keyof PhoneNumberBody): (e?) => void {
		return (e) => {
			setNewUser({
				...newUser,
				phoneNumberBody: {
					...newUser.phoneNumberBody,
					[key]: e.target.value.toUpperCase() || e.target.value,
				},
			})
		}
	}

	/**
	 * Call api to create new admin,
	 * reset form & close modal on success.
	 *
	 */
	async function submitAddNewUser(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).createStandardUser({
				createStandardUserBody: {
					companyID: props.currentUser.company._id || undefined,
					firstName: newUser.firstName || undefined,
					lastName: newUser.lastName || undefined,
					email: newUser.email || undefined,
					phoneNumberBody: (newUser?.phoneNumberBody?.countryCode || newUser?.phoneNumberBody?.nationalNumber) ? {
						countryCode: newUser.phoneNumberBody.countryCode || undefined,
						nationalNumber: newUser.phoneNumberBody.nationalNumber || undefined,
					} : undefined,
				},
			});

			setNewUser(defaultAddNewUserForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<OutsideActivitiesModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="md"
		>
			<form onSubmit={submitAddNewUser}>
				<OutsideActivitiesModalBody
					onToggle={closeHelper}
					className="admin-form-control"
				>
					<h2>Add New User</h2>

					<div className="mb-3">
						<Label>
							First name
						</Label>

						<Input
							placeholder="First Name..."
							value={newUser.firstName}
							onChange={dynamicOnChange("firstName")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Last name
						</Label>

						<Input
							placeholder="Last name..."
							value={newUser.lastName}
							onChange={dynamicOnChange("lastName")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Enter email address
						</Label>

						<Input
							type="email"
							placeholder="Email Address..."
							value={newUser.email}
							onChange={dynamicOnChange("email")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Enter Phone Number
						</Label>

						<div className="d-flex gap-2">
							<div className="user-first-time-form-page_country-code-input">
								<CountryCodeSelector
									value={newUser?.phoneNumberBody?.countryCode}
									valueKey="code"
									onChange={onCountryCodeChange}
								/>
							</div>

							<NumberFormat
								placeholder="(000) 000-0000"
								value={newUser.phoneNumberBody?.nationalNumber}
								customInput={Input}
								allowNegative={false}
								decimalScale={0}
								onValueChange={onNationalNumberChange}
								className="w-100"
							/>
						</div>
					</div>
				</OutsideActivitiesModalBody>

				<OutsideActivitiesModalFooter>
					<OutsideButton2
						type="submit"
						color="safetyOrange"
						onClick={submitAddNewUser}
					>
						Save and Add
					</OutsideButton2>
				</OutsideActivitiesModalFooter>
			</form>
		</OutsideActivitiesModal>
);
};

export default connect((store: IStore, props: IProps) =>
	{
		return {
			fullToken: store.metaStore.fullToken,
			currentUser: store.metaStore.currentUser,
			...props,
		}
	}
)(AddNewUserModal);
