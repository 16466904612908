import React from "react";
import classNames from "classnames";

interface IProps {
	children: string;
	onClick?: () => void;
	className?: string;
}

const OutsideTextButton2: React.FC<IProps> = (props) => {

	function onClickHelper(e): void {
		e.preventDefault();

		if (props.onClick) {
			props.onClick()
		}
	}

	return (
		<a
			href="#"
			onClick={onClickHelper}
			className={classNames("outside-text-button", props.className)}
		>
			{props.children}
		</a>
	);
};

export default OutsideTextButton2;
