import React from "react";
import {Bundle, CheckBundleAvailabilityResponse} from "client";
import OutsideButton2 from "./buttons/OutsideButton2";
import {FiChevronRight} from "react-icons/all";

interface IProps {
	bundle: Bundle;
	loading: boolean;
	availability: CheckBundleAvailabilityResponse;
	onSubmit: () => Promise<void>;
}

const ConfirmBookingBundleDetails: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card confirm-booking-bundle-details">
			<div className="outside-card-section">
				<div className="confirm-booking-bundle-details_thumbnail-container">
					<img
						src={props.bundle?.thumbnail?.url || "/images/placeholder.png"}
						alt={`${props.bundle?.name}`}
					/>
				</div>

				<h3 className="confirm-booking-bundle-details_bundle-name">
					{props.bundle?.name}
				</h3>

				<p className="confirm-booking-bundle-details_items-quantity">
					{(props.bundle?.items?.length + " item") + (props.bundle?.items?.length === 1 ? "" : "s")}
				</p>
			</div>

			<div className="outside-card-section confirm-booking-bundle-details_confirm-button-section">
				<OutsideButton2
					color={!props.availability?.available ? "outline-red" : "forestGreen"}
					rightIcon={FiChevronRight}
					disabled={props.loading || (props.availability && !props.availability.available)}
					onClick={props.onSubmit}
				>
					{props.loading ? "Confirming Availability..." : (props.availability?.available ? "Confirm Booking" : "Bundle Unavailable for Chosen Date")}
				</OutsideButton2>
			</div>
		</div>
	);
};

export default ConfirmBookingBundleDetails;
