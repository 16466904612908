"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquipmentBookingsResponseToJSON = exports.GetEquipmentBookingsResponseFromJSONTyped = exports.GetEquipmentBookingsResponseFromJSON = void 0;
const _1 = require("./");
function GetEquipmentBookingsResponseFromJSON(json) {
    return GetEquipmentBookingsResponseFromJSONTyped(json, false);
}
exports.GetEquipmentBookingsResponseFromJSON = GetEquipmentBookingsResponseFromJSON;
function GetEquipmentBookingsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'equipmentBookings': (json['equipmentBookings'].map(_1.EquipmentBookingFromJSON)),
    };
}
exports.GetEquipmentBookingsResponseFromJSONTyped = GetEquipmentBookingsResponseFromJSONTyped;
function GetEquipmentBookingsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'equipmentBookings': (value.equipmentBookings.map(_1.EquipmentBookingToJSON)),
    };
}
exports.GetEquipmentBookingsResponseToJSON = GetEquipmentBookingsResponseToJSON;
