import React, {ReactNode} from "react";
import {FiCheck} from "react-icons/all";
import classNames from "classnames";

interface IProps {
    children: ReactNode;
    onToggle: () => void;
    checked: boolean;
    disabled?: boolean;
}

const OutsideActivitiesCheckbox: React.FC<IProps> = (props) => {
    /**
     * onClick helper for when clicking on a part of the component
     * that is not directly the input itself.
     *
     * Don't call the toggle function if this input is disabled.
     *
     */
    function onClickHelper(): void {
        if (!props.disabled) {
            props.onToggle();
        }
    }

    return (
        <div
            className={classNames("outside-activities-checkbox", {
                "outside-activities-checkbox_disabled": props.disabled
            })}
            onClick={onClickHelper}
        >

            <div>
                <div className="pretty p-svg p-curve p-smooth">
                    <input
                        type="checkbox"
                        onChange={props.onToggle}
                        checked={props.checked}
                        disabled={props.disabled}
                    />

                    <div className="state p-forestGreen">
                        <FiCheck className="svg svg-icon"/>

                        <label>
                            {/*{props.children}*/}
                        </label>
                    </div>
                </div>
            </div>

            <div className={classNames({"state": props.checked})}>
                <label className="outside-activities-checkbox_real-label">
                    {props.children}
                </label>
            </div>
        </div>
    );
}

OutsideActivitiesCheckbox.defaultProps = {
    disabled: false,
};

export default OutsideActivitiesCheckbox;
