import React, {useState} from "react";
import {EquipmentBooking} from "client";
import moment, {Moment} from "moment";
import PartnerCalendarEquipmentItemAvailabilityModal from "../modals/PartnerCalendarEquipmentItemAvailabilityModal";

interface IProps {
	equipmentBooking: EquipmentBooking;
}

const PartnerUpcomingCalendarItem: React.FC<IProps> = (props) => {

	const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

	/**
	 * Hide or show the modal displaying the item's availability.
	 *
	 */
	function toggleAvailabilityModal(): void {
		setShowAvailabilityModal(!showAvailabilityModal);
	}

	const bookingDateStart: Moment = moment(props.equipmentBooking?.times?.start);
	const bookingDateEnd: Moment = moment(props.equipmentBooking?.times?.end);

	return (
		<React.Fragment>
			<PartnerCalendarEquipmentItemAvailabilityModal
				isOpen={showAvailabilityModal}
				onClose={toggleAvailabilityModal}
				equipment={props.equipmentBooking?.equipment}
			/>

			<div className="partner-upcoming-calendar-item">
				<div className="puci_date-and-image">
					<div className="puci_date-and-image_date-con">
						<h6 className="puci_date-and-image_date-con_month">
							{bookingDateStart.format("MMM")}
						</h6>

						<h1 className="puci_date-and-image_date-con_date">
							{bookingDateStart.format("DD")}
						</h1>

						<h6 className="puci_date-and-image_date-con_day">
							{bookingDateStart.format("ddd")}
						</h6>
					</div>

					<div className="puci_date-and-image_image-container">
						<img
							src={props.equipmentBooking?.equipment?.thumbnail?.url || process.env.PUBLIC_URL + "/images/placeholder.png"}
							alt="Equipment"
						/>
					</div>
				</div>

				<div className="puci_details">
					<b>
						{props.equipmentBooking?.equipment?.name}
					</b>
					<p>
						{`On loan from ${bookingDateStart.format("MMM D")} until ${bookingDateEnd.format("MMM D")}`}
					</p>
				</div>

				<div className="puci_schedule-link-container">
					<a
						href="#"
						onClick={toggleAvailabilityModal}
						className="puci_schedule-link-container_link"
					>
						View Item Schedule
					</a>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PartnerUpcomingCalendarItem;
