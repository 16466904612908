import React, {ReactNode} from "react";
import {Link, useHistory} from "react-router-dom";
import {User} from "client";
import classNames from "classnames";
import {BsCardImage, IoPersonOutline, RiLockPasswordLine, RiQuestionnaireLine} from "react-icons/all";
import {isFileWithSRC} from "../utils/fileTypeChecks";
import {isAsset} from "../utils/typeGuards";

interface INavBox {
    icon?: any;
    title: string;
    route: string;
}

interface IProps {
    // pages: Array<INavBox>
    user: User;
}
// todo routes
const accountPages: Array<INavBox> = [
    {
        icon: IoPersonOutline,
        title: "Personal Information",
        route: "/user/account/personal-info",
    },
    {
        icon: RiLockPasswordLine,
        title: "Password Settings",
        route: "/user/account/password-settings",
    },
    {
        icon: RiQuestionnaireLine,
        title: "Questionnaire Results",
        route: "/user/account/questionnaire-results",
    },
    // {
    //     icon: FiSettings,
    //     title: "User Settings",
    //     route: "/account/user-settings",
    // },
];

// TODO: style this component to make it presentable i.e. make the NavBox slimmer and not full width.

const NavBox: React.FC<IProps> = (props) => {

    const history = useHistory();
    const pageRoute = history.location.pathname;

    function createAccountPages(item: INavBox, index: number): ReactNode {

        const Icon: any = item.icon;
        const currentRoute: boolean = pageRoute === item.route;

        return (
            <li
                key={`nav-box-${index}`}
                className={classNames("nav-box_page-list_item", {"nav-box_page-list_item-selected": currentRoute})}
            >
                <div className={"nav-box_page-list_item_contents"}>
                    {Icon && <Icon/>}

                    <Link to={item.route}>{item.title}</Link>
                </div>

                <div className={classNames({"nav-box_page-list_item_indicator-selected" : currentRoute})}/>
            </li>
        );
    }

    return (
        <div className="nav-box">
            <div className="nav-box_header">
                <div className="nav-box_header_preview-container">
                    {isFileWithSRC(props.user?.image) ? (
                        <img
                            src={props.user?.image?.imageSRC as string}
                            alt="profile image"
                        />
                    ) : null}

                    {isAsset(props.user?.image) ? (
                        <img
                            src={props.user?.image?.url}
                            alt="profile image"
                        />
                    ) : null}

                    {props.user?.image === undefined && (
                        <BsCardImage
                            className="profile-image-picker_preview-container_placeholder"
                            color="white"
                        />
                    )}
                </div>

                <h2 className="nav-box_header-text">Account</h2>
            </div>

            <ul className="nav-box_page-list">
                {accountPages.map(createAccountPages)}
            </ul>
        </div>
    );
}

export default NavBox;
