import React, {ReactElement} from "react";
import {AiOutlineClockCircle, FiCheck, FiCheckCircle} from "react-icons/all";
import {Characteristic} from "client";
import IconHeaderLabel from "../labels/IconHeaderLabel";

interface IProps {
	characteristics: Array<Characteristic>
	description: string;
}

const ViewBundleCharacteristicsAndDescription: React.FC<IProps> = (props) => {

	function createImageLabel(item: Characteristic, index: number): ReactElement {
		return (
			<div
				key={`image-label-${index}`}
				className="history-view-item-info__icon-container-item"
			>
				<IconHeaderLabel
					icon={FiCheckCircle}
					header={item.name}
					subheader={item.description}
					invert={true}
				/>
			</div>
		);
	}

	return (
		<div className="outside-card history-view-item-info">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Info
				</h2>
			</div>

			<div className="outside-card-section">
				{props.characteristics?.length > 0 && (
					<div className="history-view-item-info__icon-container">
						{props.characteristics.map(createImageLabel)}
					</div>
				)}

				<div className="history-view-item-info__body-container">
					<h4>What to know</h4>
					<p>{props.description}</p>
				</div>
			</div>
		</div>
	);
};

ViewBundleCharacteristicsAndDescription.defaultProps = {
	characteristics: [],
};

export default ViewBundleCharacteristicsAndDescription;
