import React, {ReactNode, useLayoutEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {User, UserType} from "client";
import classNames from "classnames";
import outsideActivitiesLogo from "../../images/logoOA.svg";
import {GiHamburgerMenu} from "react-icons/all";
import {
	IOutsideActivitiesBottomNavbarNavItem,
	outsideActivitiesBottomNavigationLinks
} from "../../utils/getUserTypeSubNavigationItems";
import {Link, useHistory, useLocation} from "react-router-dom";
import {logout, toggleSideBar} from "../../redux/meta/MetaActions";
import {standardUserShouldCompleteFirstTimeForm} from "../../utils/standardUserShouldCompleteFirstTimeForm";
import {useWindowDimensions} from "../../utils/hooks/useWindowDimensions";

interface IProps {
	dispatch?: any;
	currentUser?: User;
	sidebarVisible?: boolean;
}

const NavigationManager: React.FC<IProps> = (props) => {

	const location = useLocation();
	const history = useHistory();
	const windowDimension = useWindowDimensions();

	useLayoutEffect(() => {
		if (windowDimension.width > 767 && props.sidebarVisible) {
			props.dispatch(toggleSideBar(false));
		}
	}, [windowDimension.width]);

	function toggleSideBarHelper(): void {
		props.dispatch(toggleSideBar(!props.sidebarVisible));
	}

	/**
	 * Dispatch logout in redux & send to login page.
	 *
	 */
	function logOutHelper(): void {
		props.dispatch(logout());
		history.push("/");
	}

	/**
	 * Render each navigation link, and dynamically style it based on if the user is on a matching route or not.
	 *
	 * @param link
	 * @param i
	 */
	function renderNavigationLinks(link: IOutsideActivitiesBottomNavbarNavItem, i: number): ReactNode {
		return (
			<Link
				key={`bottom-nav-link_${i}`}
				to={link.route}
				className={classNames("navigation-bottom-button", {
					"navigation-bottom-button_disabled": link.route === (location.pathname + location.search),
					"navigation-bottom-button_selectable": !link.route.includes(location.pathname) && !location.pathname.includes(link.route),
					"navigation-bottom-button_user-selected": (link.route.includes(location.pathname) || location.pathname.includes(link.route)) && props.currentUser?.type === UserType.STANDARDUSER,
					"navigation-bottom-button_admin-selected": (link.route.includes(location.pathname) || location.pathname.includes(link.route)) && (props.currentUser?.type === UserType.COMPANYADMIN || props.currentUser?.type === UserType.PARTNERADMIN),
				})}
			>
				{link.label}
			</Link>
		);
	}

	const renderBottomNavigation: boolean = props.currentUser && !standardUserShouldCompleteFirstTimeForm(props.currentUser);

	return (
		<React.Fragment>
			<div className="navigation-master">
				<div
					className={classNames("navigation-manager", {
						"navigation-manager_user": !props.currentUser || props.currentUser?.type === UserType.STANDARDUSER,
						"navigation-manager_admin": props.currentUser?.type === UserType.COMPANYADMIN || props.currentUser?.type === UserType.PARTNERADMIN,
					})}
				>
					<div className="container navigation-manager_container">
						<div className="navigation-manager_branding">
							<div className="navigation-manager_branding_logo-container">
								<img src={outsideActivitiesLogo} alt="Outside Activities"/>
							</div>

							<h1 className="navigation-manager_branding_title">
								Outside Activities
							</h1>
						</div>

						<div className="navigation-manager_user-controls">
							{props.currentUser && (
								<React.Fragment>
									<div
										className="navigation-manager_user-controls_hamburger-container"
										onClick={toggleSideBarHelper}
									>
										<GiHamburgerMenu/>
									</div>

									<div className="navigation-manager_user-controls_desktop-view">
										{(props.currentUser?.type === UserType.STANDARDUSER && !standardUserShouldCompleteFirstTimeForm(props.currentUser)) && (
											<React.Fragment>
												<Link to="/user/account/personal-info">
													Profile
												</Link>
											</React.Fragment>
										)}

										<a
											href="#"
											onClick={logOutHelper}
										>
											Log Out
										</a>
									</div>
								</React.Fragment>
							)}
						</div>
					</div>
				</div>

				{renderBottomNavigation && (
					<div className="navigation-bottom">
						<div className="container navigation-bottom_container">
							{outsideActivitiesBottomNavigationLinks[props.currentUser?.type]?.map(renderNavigationLinks)}
						</div>
					</div>
				)}
			</div>

			<div className="navigation-top-spacer"/>

			{renderBottomNavigation && (
				<div className="navigation-bottom-spacer"/>
			)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		currentUser: store.metaStore.currentUser,
		sidebarVisible: store.metaStore.sidebarVisible,
		...props,
	}
})(NavigationManager);
