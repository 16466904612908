import React from "react";
import placeholderImage from "../images/male-profile-picture-placeholder-vector-600w-450966901.webp";

interface IProps {
    name?: string;
    location?: string;
    image?: string;
}

const ProfileImage: React.FC<IProps> = (props) => {
    return (
        <div className="profile-image">
            <div className="profile-image_image-container">
                <div className="profile-image_image-container_inner">
                    <img src={props.image} alt="placeholder image"/>
                </div>
            </div>

            <p className="profile-image__name">
                {props.name}
            </p>

            <p className="profile-image__location">
                {props.location}
            </p>
        </div>
    );
}

ProfileImage.defaultProps = {
    image: placeholderImage,
};

export default ProfileImage;