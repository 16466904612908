"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
const _2 = require("./");
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'GOD_ADMIN') {
            return _2.GodAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'STANDARD_ADMIN') {
            return _2.StandardAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'COMPANY_ADMIN') {
            return _2.CompanyAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'PARTNER_ADMIN') {
            return _2.PartnerAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'STANDARD_USER') {
            return _2.StandardUserFromJSONTyped(json, true);
        }
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': _1.UserTypeFromJSON(json['type']),
        'disabled': json['disabled'],
        'email': json['email'],
        'emailVerified': json['emailVerified'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : _1.PhoneNumberFromJSON(json['phoneNumber']),
        'phoneNumberVerified': json['phoneNumberVerified'],
        'image': !runtime_1.exists(json, 'image') ? undefined : _1.AssetFromJSON(json['image']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': _1.UserTypeToJSON(value.type),
        'disabled': value.disabled,
        'email': value.email,
        'emailVerified': value.emailVerified,
        'phoneNumber': _1.PhoneNumberToJSON(value.phoneNumber),
        'phoneNumberVerified': value.phoneNumberVerified,
        'image': _1.AssetToJSON(value.image),
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
exports.UserToJSON = UserToJSON;
