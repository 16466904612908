import {UserType} from "client";
import {ReactNode} from "react";
import {BsGear, HiOutlineClipboardList, RiCalendarEventLine, RiHome4Line, BiBuildings, FaHiking} from "react-icons/all";

export interface IOutsideActivitiesBottomNavbarNavItem {
	label: string;
	route: string;
	icon: ReactNode;
	activeClassName?: string;
}

type OutsideActivitiesUserNavigationMap = {
	[key in UserType]?: Array<IOutsideActivitiesBottomNavbarNavItem>;
}

export const outsideActivitiesBottomNavigationLinks: OutsideActivitiesUserNavigationMap = {
	[UserType.STANDARDUSER]: [
		{
			label: "Home",
			route: "/user/home",
			icon: RiHome4Line,
		},
		{
			label: "Calendar",
			route: "/user/calendar",
			icon: RiCalendarEventLine,
		},
		{
			label: "Questionnaire",
			route: "/user/questionnaire/question",
			icon: HiOutlineClipboardList,
		},
	],
	[UserType.COMPANYADMIN]: [
		// {
		// 	label: "Home",
		// 	route: "/company/home",
		// 	icon: RiHome4Line,
		// },
		{
			label: "Users",
			route: "/company/users",
			icon: BsGear,
		},
		// {
		// 	label: "Company",
		// 	route: "/company/profile",
		// 	icon: BiBuildings,
		// },
	],
	[UserType.PARTNERADMIN]: [
		// {
		// 	label: "Home",
		// 	route: "/partner/home",
		// 	icon: RiHome4Line,
		// },
		{
			label: "Calendar",
			route: "/partner/calendar?list=upcoming",
			icon: RiCalendarEventLine,
		},
		{
			label: "Inventory",
			route: "/partner/inventory",
			icon: FaHiking,
		},
	],
};
