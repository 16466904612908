import React, {useEffect, useState} from "react";
import {Answer, QuestionsApi, Token, User, UsersApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import getConfig from "../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";
import QuestionAnswerSelection from "../inputs/QuestionAnswerSelection";
import {find, findIndex} from "lodash";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
    answer: Answer;
}

const EditAnswerModal: React.FC<IProps> = (props) => {

    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number>(undefined);

    useEffect(() => {
        if (props.answer) {
            setSelectedAnswerIndex(findIndex(props.answer?.question?.answerOptions, ["answer", props.answer?.answer]));
        }
    }, [JSON.stringify(props.answer)]);

    async function submitUpdatedAnswer(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new QuestionsApi(getConfig(props.fullToken)).updateAnswer({
                id: props.answer?._id,
                createAnswerBody: {
                    answer: props.answer?.question?.answerOptions?.[selectedAnswerIndex]?.answer,
                },
            });

            props.onDone();
        } catch(e) {
            props.dispatch(addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    return (
        <OutsideActivitiesModal
            isOpen={props.isOpen}
            toggle={props.onClose}
        >
            <OutsideActivitiesModalBody onToggle={props.onClose}>
                <h3 className="mb-3">
                    Update Answer
                </h3>

                <QuestionAnswerSelection
                    answers={props.answer?.question?.answerOptions}
                    selectedAnswer={selectedAnswerIndex}
                    onSelectAnswer={setSelectedAnswerIndex}
                />
            </OutsideActivitiesModalBody>

            <OutsideActivitiesModalFooter>
                <OutsideButton2
                    color="offWhite2"
                    onClick={props.onClose}
                >
                    Cancel
                </OutsideButton2>

                <OutsideButton2
                    color="forestGreen"
                    onClick={submitUpdatedAnswer}
                >
                    Save Answer
                </OutsideButton2>
            </OutsideActivitiesModalFooter>
        </OutsideActivitiesModal>
    );
}

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(EditAnswerModal);
