import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Container, Input, Label} from "reactstrap";
import OutsideActivitiesLake from "../images/outside-activities-lake.jpg";
import OutsideActivitiesKayak from "../images/outside-activities-ocean-kayak.jpg";
import {LoginBody, Token, TokensApi, UsersApi, UserType} from "client";
import {
	addError,
	decrementLoading,
	incrementLoading,
	login,
	logout,
	updateCurrentUser
} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import OutsideButton2 from "../components/buttons/OutsideButton2";

const defaultLoginBody: LoginBody = {
	email: "",
	password: "",
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const Login: React.FC<IProps> = (props) => {

	const history = useHistory();

	const [checked, setChecked] = useState<boolean>(false);
	const [form, setForm] = useState<LoginBody>(defaultLoginBody);

	useEffect(() => {
		if (props.fullToken) {
			verifyTokenExpiry(props.fullToken).then().catch();
		}
	}, [JSON.stringify(props.fullToken)]);

	/**
	 * Checks if the existing token is expired.
	 * If it is, dispatch the redux logout action but don't
	 * do anything from the user's perspective - they must log in again.
	 *
	 * If their token ISN'T expired, replace current index in history with the dashboard route.
	 *
	 * @param fullToken
	 */
	async function verifyTokenExpiry(fullToken: Token): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TokensApi(getConfig(fullToken)).checkTokenExpiration({
				tokenBody: {
					token: fullToken?.token,
				}
			})

			if (res.expired) {
				await props.dispatch(logout());
			} else {
				const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
				props.dispatch(updateCurrentUser(userRes.user));
				redirectHelper(userRes.user.type);
			}
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Submit the form values to the login api, and if successful, use the token response to fetch the user's profile,
	 * save to redux, and send the user to the home page.
	 *
	 * @param e
	 */
	async function submitLogin(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig()).login({
				loginBody: {
					email: form?.email,
					password: form?.password,
				},
			});

			props.dispatch(login(res));
			const userRes = await new UsersApi(getConfig(res)).getProfile();
			props.dispatch(updateCurrentUser(userRes.user));
			redirectHelper(userRes.user.type);
		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function redirectHelper(userType: UserType): void {
		switch (userType) {
			case UserType.STANDARDUSER:
				history.push("/user/home");
				break;
			case UserType.COMPANYADMIN:
				history.push("/company/users");
				break;
			case UserType.PARTNERADMIN:
				// history.push("/partner/home");
				history.push("/partner/calendar");
				break;
		}
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function changeHandler(key: keyof LoginBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	if (props.fullToken) {
		return null;
	}

	return (
		<React.Fragment>
			<div className="login-page">
				<div className="login-page_images login-page_images_media-helper">
					<div className="login-page_images_top">
						<img
							src={OutsideActivitiesKayak}
							alt="outside activities"
						/>
					</div>

					<div className="login-page_images_bottom">
						<img
							src={OutsideActivitiesLake}
							alt="outside activities"
						/>
					</div>
				</div>

				<Container className="login-page-body-container">
					<div
						className="login-page_terminal"
					>
						<div className="login-page_terminal_header">
							<h1>
								Log in
							</h1>

							<p>
								Welcome back! Log in to your account to continue to Outside Activities.
							</p>
						</div>

						<form
							className="login-page_terminal_form"
							onSubmit={submitLogin}
						>
							<div className="login-page_terminal_form-input">
								<Label className="label-p2">Email</Label>
								<Input
									type="email"
									name="login-email-input"
									placeholder="Enter email address"
									value={form.email}
									onChange={changeHandler("email")}
								/>
							</div>

							<div className="login-page_terminal_form-input">
								<Label className="label-p2">Password</Label>
								<Input
									type="password"
									name="login-password-input"
									placeholder="Enter password"
									value={form.password}
									onChange={changeHandler("password")}
								/>
							</div>

							<div className="login-page_terminal_form_check-and-reset">
								<div/>

								<Link to="/forgot-password">
									Forgot password?
								</Link>
							</div>

							<OutsideButton2
								type="submit"
								color="forestGreen"
								className="login-page_terminal_form_button"
								onClick={submitLogin}
							>
								Log in
							</OutsideButton2>
						</form>

						{/*<h4 className="login-page_terminal_footer">*/}
						{/*	<span>Don’t have an account?</span>*/}
						{/*	<Link to="/signup">Sign up</Link>*/}
						{/*</h4>*/}
					</div>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(Login);
