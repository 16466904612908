import React, {ReactNode} from "react";
import {EquipmentBooking} from "client";
import moment, {Moment} from "moment";
import classNames from "classnames";
import PartnerUpcomingCalendarItem from "./PartnerUpcomingCalendarItem";

interface IProps {
	equipmentBookings: Array<EquipmentBooking>;
}

const PartnerUpcomingCalendarList: React.FC<IProps> = (props) => {

	/**
	 * Iterate over the bookings, while keeping an object to track which months have any associated entries,
	 * which is used as logic to dynamically render the month display when a new month is reached in the list.
	 * as well as used to influence the styling of the individual booking items.
	 *
	 * @param _equipmentBookings
	 */
	function renderCalendarItems(_equipmentBookings: Array<EquipmentBooking> = []): ReactNode {
		const establishedMonths: { [key: number]: true } = {};

		return _equipmentBookings.map((booking: EquipmentBooking, i: number) => {
			const bookingMoment: Moment = moment(booking.createdAt);
			const bookingStartOfMonth: number = bookingMoment.startOf("month").valueOf();

			let renderNewMonthHeader: boolean = false;
			if (!establishedMonths[bookingStartOfMonth]) {
				establishedMonths[bookingStartOfMonth] = true;
				renderNewMonthHeader = true;
			}

			return (
				<React.Fragment>
					{renderNewMonthHeader && (
						<div
							key={`partner-calendar-month-header_${i}`}
							className="calendar_section-header"
						>
							<b>
								{bookingMoment.format("MMMM YYYY")}
							</b>
						</div>
					)}

					<div
						key={`partner-calendar-item_${i}`}
						className={classNames("calendar_section-item", {
							"calendar_section-item-first-of-month": renderNewMonthHeader,
							"calendar_section-item-not-first-of-month": !renderNewMonthHeader,
						})}
					>
						<PartnerUpcomingCalendarItem equipmentBooking={booking}/>
					</div>
				</React.Fragment>
			);
		});
	}

	return (
		<div>
			{renderCalendarItems(props.equipmentBookings)}
		</div>
	);
};

PartnerUpcomingCalendarList.defaultProps = {
	equipmentBookings: [],
};

export default PartnerUpcomingCalendarList;
