import React, {ReactNode} from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {logout, toggleSideBar} from "../../redux/meta/MetaActions";
import {Token, User, UserType} from "client";
import SideBarButton from "./SideBarButton";
import {
    BiHelpCircle,
    BsGear,
    BsPerson,
    FiLogOut,
    FiX, HiOutlineClipboardList, RiCalendarEventLine,
    RiHistoryLine, RiHome4Line
} from "react-icons/all";
import classNames from "classnames";
import {
    IOutsideActivitiesBottomNavbarNavItem,
    outsideActivitiesBottomNavigationLinks
} from "../../utils/getUserTypeSubNavigationItems";
import {standardUserShouldCompleteFirstTimeForm} from "../../utils/standardUserShouldCompleteFirstTimeForm";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    currentUser?: User;
    sideBarVisible?: boolean;
}

interface ISideBarNavigationLink {
    title?: string;
    route: string;
    icon?: ReactNode;
    activeClassName?: string;
}

const navigationLinks: Array<ISideBarNavigationLink> = [
    {
        title: "Home",
        route: "/home",
        icon: RiHome4Line,
        activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
    },
    {
        title: "Calendar",
        route: "/user/calendar",
        icon: RiCalendarEventLine,
        activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
    },
    {
        title: "History",
        route: "/user/bookings/view",
        icon: RiHistoryLine,
        activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
    },
    {
        title: "Questionnaire",
        route: "/user/questionnaire/question",
        icon: HiOutlineClipboardList,
        activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
    },
];

const userLinks: Array<ISideBarNavigationLink> = [
    {
        title: "Profile",
        route: "/profile",
        icon: BsPerson,
    },
    {
        title: "Account",
        route: "/account",
        icon: BsGear,
    },
    {
        title: "Help",
        route: "/help",
        icon: BiHelpCircle,
    },
]

const SideBarInner: React.FC<IProps> = (props: IProps) => {

    const history = useHistory();
    const location = useLocation();

    /**
     * Helps toggle the side bar.
     *
     */
    function closeSideBarHelper(): void {
        if (props.sideBarVisible) {
            props.dispatch(toggleSideBar(false));
        }
    }

    /**
     * Handle the "Log Out" button click.
     * Dispatch redux logout action,
     * send user to "/" route.
     *
     * @param e
     */
    function logoutDispatcher(e?): void {
        if (e) {
            e.preventDefault();
        }

        closeSideBarHelper();
        props.dispatch(logout());
        history.push("/");
    }

    /**
     * Render each of the navigation links.
     *
     * @param link
     * @param i
     */
    function createSideBarButtons(link: IOutsideActivitiesBottomNavbarNavItem, i: number): ReactNode {
        const selected: boolean = location.pathname?.indexOf(link.route) > -1;

        return (
            <Link
                key={`side-bar-navigation-link_${i}`}
                to={link.route}
                className={classNames("sidebar_inner_content_button-list-container_link", selected && link.activeClassName)}
            >
                <SideBarButton
                    className={classNames("sidebar_inner_content_button-list-container_button", {
                        "side-bar-button_user-selected": (link.route.includes(location.pathname) || location.pathname.includes(link.route)),
                        "side-bar-button_admin-selected": (link.route.includes(location.pathname) || location.pathname.includes(link.route)) && (props.currentUser?.type === UserType.COMPANYADMIN || props.currentUser?.type === UserType.PARTNERADMIN),
                    })}
                    icon={link.icon}
                >
                    {link.label}
                </SideBarButton>
            </Link>
        );
    }

    return (
        <React.Fragment>
            <div className="sidebar_inner">
                <div className="sidebar_inner_content">
                    <div className="sidebar_inner_content_title-container">
                        <h3 className="sidebar_inner_content_title-container_title">
                            Navigation
                        </h3>
                    </div>

                    <div className="sidebar_inner_content_button-list-container">
                        {(props.currentUser?.type === UserType.STANDARDUSER) && (
                            <Link
                                to="/user/account/personal-info"
                                className={classNames("sidebar_inner_content_button-list-container_link")}
                            >
                                <SideBarButton
                                    className={classNames("sidebar_inner_content_button-list-container_button", {
                                        "side-bar-button_user-selected": (location.pathname.includes("/user/account")) && props.currentUser?.type === UserType.STANDARDUSER,
                                    })}
                                    icon={BsPerson}
                                    active={location.pathname?.indexOf("/user/account") > -1}
                                    onClick={closeSideBarHelper}
                                >
                                    Profile
                                </SideBarButton>
                            </Link>
                        )}
                        {outsideActivitiesBottomNavigationLinks[props.currentUser?.type]?.map(createSideBarButtons)}
                    </div>

                    <div className="sidebar_inner_content_button-list-container">

                        <SideBarButton
                            className="sidebar_inner_content_button-list-container_button"
                            icon={FiLogOut}
                            onClick={logoutDispatcher}
                        >
                            Log Out
                        </SideBarButton>
                    </div>

                    <div className="sidebar_inner_content_close-button">
                        <SideBarButton
                            className="sidebar_inner_content_button-list-container_button"
                            icon={FiX}
                            onClick={closeSideBarHelper}
                        >
                            Close menu
                        </SideBarButton>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        ...props,
        fullToken: store.metaStore.fullToken,
        currentUser: store.metaStore.currentUser,
        sideBarVisible: store.metaStore.sidebarVisible,
    }
})(SideBarInner);
