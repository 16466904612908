import React, {useEffect, useState} from "react";
import {Booking, BookingsApi, Bundle, Token} from "client";
import {RouteProps} from "react-router";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Container} from "reactstrap";
import BreadCrumbs from "../../components/BreadCrumbs";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {placeholderData} from "./HistoryPage";
import ViewHistoryItemBookingDetails from "../../components/history/ViewHistoryItemBookingDetails";
import ViewHistoryItemInfo from "../../components/history/ViewBundleCharacteristicsAndDescription";
import ViewHistoryBundleThumbnail from "../../components/history/ViewHistoryBundleThumbnail";
import ViewBookingDetailsBundleItems from "../../components/history/ViewBookingDetailsBundleItems";
import getConfig from "../../utils/getConfig";
import {useHistory} from "react-router-dom";
import moment from "moment";
import ConfirmBookingBundleDetails from "../../components/ConfirmBookingBundleDetails";
import ViewBookingBundleDetails from "../../components/ViewBookingBundleDetails";

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
}

const HistoryViewHistoryItem: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const bookingID: string = query.get("booking");

	const [booking, setBooking] = useState<Booking>(undefined);

	useEffect(() => {
		if (bookingID) {
			getBooking().then().catch();
		} else {
			history.replace("/user/calendar?list=upcoming");
		}
	}, []);

	/**
	 * Call api on load to get the bundle specified in the query.
	 *
	 */
	async function getBooking(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BookingsApi(getConfig(props.fullToken)).getBooking({
				id: bookingID,
			});

			setBooking(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	if (!booking) {
		return null;
	}

	return (
		<Container className="authenticated-user-page">
			<BreadCrumbs
				crumbs={[
					{
						label: (booking.current || booking.times.start >= moment().valueOf()) ? "Upcoming Bookings" : "Past Bookings",
						route: (booking.current || booking.times.start >= moment().valueOf()) ? "/user/calendar?list=upcoming" : "/user/calendar?list=history",
					},
					{
						label: booking?.bundle?.name,
						route: `user/bookings/view?booking=${bookingID}`,
					},
				]}
			/>

			<h1 className="mb-4">{booking?.bundle?.name}</h1>

			<div className="card-page-split-layout-container">
				<div className="card-page-col-left">
					<h2 className="m-0">Details</h2>
					<ViewHistoryItemBookingDetails
						startDate={moment(booking?.times?.start).format("LL")}
						endDate={moment(booking?.times?.end).format("LL")}
					/>

					<ViewHistoryItemInfo
						characteristics={booking?.bundle?.requiredCharacteristics}
						description={booking?.bundle?.description}
					/>

					<ViewBookingDetailsBundleItems items={booking?.bundle?.items}/>
				</div>

				<div className="card-page-col-right">
					<h2 className="m-0">Bundle</h2>
					<ViewBookingBundleDetails bundle={booking?.bundle}/>
				</div>
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(HistoryViewHistoryItem);
