import React, {ReactNode} from "react";
import {svgSizer} from "../utils/svgSizer";

interface IProps {
    children: string;
    color?: string;
    icon?: ReactNode;
    size?: string | number;
}

const Notification: React.FC<IProps> = (props) => {
    const Icon: any = props.icon;

    return (
        <div className={`notification-${props.color}`}>
            <div
                className={`notification-${props.color}__icon`}
                style={svgSizer(props.size)}
            >
                {Icon && <Icon/>}
            </div>

            <div className={`notification-${props.color}__message`}>
                {props.children}
            </div>
        </div>
    );
}

Notification.defaultProps = {
    color: "blueLight",
    size: 25,
};

export default Notification;