import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	active: boolean;
	onClick?: () => void;
}

const OutsidePaginatorButton: React.FC<IProps> = (props) => {

	function onClickHelper(): void {
		if (props.onClick) {
			props.onClick();
		}
	}

	return (
		<button
			disabled={props.onClick === undefined || props.active}
			onClick={onClickHelper}
			className={classNames("outside-paginator-button", {
				"outside-paginator-button_clickable": props.onClick !== undefined,
				"outside-paginator-button_active": props.active,
			})}
		>
			{props.children}
		</button>
	);
};

export default OutsidePaginatorButton;
