import React from "react";
import {Token, User, UsersApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import getConfig from "../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import {generateUserFullName} from "../../utils/generateUserFullName";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	user: User;
	isOpen: boolean;
    onClose: () => void;
	onDone: () => void;
}

const DeleteUserModal: React.FC<IProps> = (props) => {

	async function confirmDelete(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).disableUser({id: props.user?._id});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<OutsideActivitiesModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<OutsideActivitiesModalBody onToggle={props.onClose}>
                <h3>
	                Confirm Deletion
                </h3>

				<p>
                    {`Are you sure you want to delete ${generateUserFullName(props.user)}? This action cannot be undone.`}
				</p>
			</OutsideActivitiesModalBody>

            <OutsideActivitiesModalFooter>
                <OutsideButton2
                    color="offWhite2"
                    onClick={props.onClose}
                >
                    Cancel
                </OutsideButton2>

                <OutsideButton2
                    color="safetyOrange"
                    onClick={confirmDelete}
                >
                    Yes, Delete
                </OutsideButton2>
            </OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(DeleteUserModal);
