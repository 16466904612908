import React from "react";
import {valueOrBust} from "../../utils/valueOrBust";
import {parseBooleanToYesNo} from "../../utils/parseBooleanToYesNo";

interface IProps {
	name: string;
	description: string;
	isConsumable: boolean;
	quantity: number;
	instructions: string;
}

const ViewInventoryItemDetails: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card view-inventory-item-details">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Details
				</h2>
			</div>

			<div className="outside-card-section">
				<b>Name</b>
				<p>{valueOrBust(props.name)}</p>

				<b>Description</b>
				<p>{valueOrBust(props.description)}</p>

				<b>Consumable</b>
				<p>{parseBooleanToYesNo(props.isConsumable)}</p>

				<b>Quantity</b>
				<p>{valueOrBust(props.quantity)}</p>

				<b>Instructions</b>
				<p>{valueOrBust(props.instructions)}</p>
			</div>
		</div>
	);
};

export default ViewInventoryItemDetails;
