import React from "react";
import {Booking} from "client";
import moment, {Moment} from "moment";
import { Link } from "react-router-dom";
import IconHeaderLabel from "../labels/IconHeaderLabel";
import {AiOutlineClockCircle} from "react-icons/all";

interface IProps {
	booking: Booking;
}

const UserUpcomingCalendarItem: React.FC<IProps> = (props) => {

	const bookingDateStart: Moment = moment(props.booking?.times?.start);
	const bookingDateEnd: Moment = moment(props.booking?.times?.end);

	return (
		<div className="user-upcoming-calendar-item">
			<div className="uuci_date-and-image">
				<div className="uuci_date-and-image_date-con">
					<h6 className="uuci_date-and-image_date-con_month">
						{bookingDateStart.format("MMM")}
					</h6>

					<h1 className="uuci_date-and-image_date-con_date">
						{bookingDateStart.format("DD")}
					</h1>

					<h6 className="uuci_date-and-image_date-con_day">
						{bookingDateStart.format("ddd")}
					</h6>
				</div>

				<div className="uuci_date-and-image_image-container">
					<img
						src={props.booking?.bundle?.thumbnail?.url || process.env.PUBLIC_URL + "/images/placeholder.png"}
						alt="Bundle"
					/>
				</div>
			</div>

			<div className="uuci_details">
				<p className="uuci_details_item-quantity">
					{props.booking?.bundle?.items?.length + " item" + (props.booking?.bundle?.items?.length === 1 ? "" : "s")}
				</p>
				<h4>
					{props.booking?.bundle?.name}
				</h4>
				{props.booking?.current ? (
					<div className="uuci_details_happening-now">
						<IconHeaderLabel
							icon={AiOutlineClockCircle}
							header={`${bookingDateStart.format("D MMM YYYY")} → ${bookingDateEnd.format("D MMM YYYY")}`}
							subheader="(Happening Now)"
							invert={true}
						/>
					</div>
				) : (
					<IconHeaderLabel
						icon={AiOutlineClockCircle}
						header={`${bookingDateStart.format("D MMM YYYY")} → ${bookingDateEnd.format("D MMM YYYY")}`}
						invert={true}
					/>
				)}
			</div>

			<div className="uuci_booking-deets-link-container">
				<Link
					to={`/user/bookings/view?booking=${props.booking?._id}`}
					className="uuci_booking-deets-link-container_link"
				>
					View Booking Details
				</Link>
			</div>
		</div>
	);
};

export default UserUpcomingCalendarItem;
