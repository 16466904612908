"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNewQuestionResponseToJSON = exports.GetNewQuestionResponseFromJSONTyped = exports.GetNewQuestionResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GetNewQuestionResponseFromJSON(json) {
    return GetNewQuestionResponseFromJSONTyped(json, false);
}
exports.GetNewQuestionResponseFromJSON = GetNewQuestionResponseFromJSON;
function GetNewQuestionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'outOfQuestions': json['outOfQuestions'],
        'question': !runtime_1.exists(json, 'question') ? undefined : _1.QuestionFromJSON(json['question']),
    };
}
exports.GetNewQuestionResponseFromJSONTyped = GetNewQuestionResponseFromJSONTyped;
function GetNewQuestionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'outOfQuestions': value.outOfQuestions,
        'question': _1.QuestionToJSON(value.question),
    };
}
exports.GetNewQuestionResponseToJSON = GetNewQuestionResponseToJSON;
