import React, {ChangeEventHandler} from "react";
import {Input, Label} from "reactstrap";
import {ICreateEquipmentBodyFrontend} from "../modals/PartnerInventoryAddStockModal";
import OutsideActivitiesRadio from "../OutsideActivitiesRadio";
import NumberFormat, {NumberFormatValues} from "react-number-format";

interface IProps {
	name: string;
	description: string;
	isConsumable: boolean;
	quantity: number;
	instructions: string;
	dynamicOnChange: (key: keyof ICreateEquipmentBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
	consumableOnChange: (_consumable: boolean) => void;
	numberFormatOnChange: (values: NumberFormatValues) => void
}

const AddInventoryDetails: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card add-inventory-details">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Details
				</h2>
			</div>

			<div className="outside-card-section">
				<Label>
					Name*
				</Label>
				<Input
					placeholder="Equipment Name..."
					value={props.name}
					onChange={props.dynamicOnChange("name")}
					className="mb-3"
				/>

				<Label>
					Description*
				</Label>
				<Input
					placeholder="Equipment Description..."
					value={props.description}
					onChange={props.dynamicOnChange("description")}
					className="mb-3"
				/>

				<div className="mb-3">
					<Label>
						Consumability*
					</Label>
					<OutsideActivitiesRadio
						<boolean>
						name="add-equipment-consumable-yes"
						onToggle={props.consumableOnChange}
						checked={props.isConsumable}
						value={true}
						className="mb-2"
					>
						Yes, this item is consumable
					</OutsideActivitiesRadio>
					<OutsideActivitiesRadio
						<boolean>
						name="add-equipment-consumable-no"
						onToggle={props.consumableOnChange}
						checked={!props.isConsumable}
						value={false}
					>
						No, this item is not consumable
					</OutsideActivitiesRadio>
				</div>

				<Label>
					Quantity*
				</Label>
				<NumberFormat
					placeholder="Equipment Quantity..."
					value={props.quantity}
					customInput={Input}
					allowNegative={false}
					decimalScale={0}
					onValueChange={props.numberFormatOnChange}
					className="mb-3"
				/>

				<Label>
					Instructions
				</Label>
				<Input
					type="textarea"
					placeholder="Equipment Instructions..."
					value={props.instructions}
					onChange={props.dynamicOnChange("instructions")}
					className="mb-3"
				/>
			</div>
		</div>
	);
};

export default AddInventoryDetails;
