import React, {useEffect, useRef, useState} from "react";
import Autocomplete from "react-google-autocomplete";

interface IProps {
    initialInputValue?: string;
    setPlaceID: (placeID) => void;
    placeholder?: string;
}

const GooglePlacesInput: React.FC<IProps> = (props) => {

    const [place, setPlace] = useState(undefined);
    const [autocomplete, setAutocomplete] = useState(props.initialInputValue);
    const inputRef = useRef<HTMLInputElement>();

    /**
     * Prevents selecting a place (or pressing "Enter" key at any point when typing) from submitting a parent form.
     *
     */
    useEffect(() => {
        if (inputRef) {
            inputRef.current.onkeydown = (e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            }
        }
    }, [inputRef]);

    useEffect(() => {
        if (place) {
            setAutocomplete(place.formatted_address);
            props.setPlaceID(place.place_id);
        }
    }, [JSON.stringify(place)]);

    /**
     * Helper for when clicking on a location in the dropdown
     *
     * @param place
     */
    function autocompletePlaceSelector(place): void {
        setPlace(place);
        setAutocomplete(place.formatted_address);
    }

    /**
     * Sets the text in the Autocomplete input field
     *
     * @param e
     */
    function autocompleteOnChangeHelper(e): void {
        setAutocomplete(e.target.value);
    }

    /**
     * Help reset the visuals for a better UX when input blurs.
     *
     */
    function onBlurHelper(): void {
        if (autocomplete === "") {
            setPlace(undefined);
        } else if (!place) {
            setAutocomplete(props.initialInputValue ? props.initialInputValue : "");
        } else if (place) {
            setAutocomplete(place.formatted_address);
        }
    }

    return (
        <Autocomplete
            placeholder={props.placeholder}
            options={{
                types: ["address"],
            }}
            apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
            onPlaceSelected={autocompletePlaceSelector}
            onChange={autocompleteOnChangeHelper}
            value={autocomplete}
            className="form-control"
            onBlur={onBlurHelper}
            ref={inputRef}
        />
    );
}

GooglePlacesInput.defaultProps = {
    placeholder: "Location...",
};

export default GooglePlacesInput;
