import React, {useState} from "react";
import moment from "moment";
import AddInventoryDateRangeModal from "../inventory/AddInventoryDateRangeModal";
import {TimeInterval} from "client";
import OutsideButton2 from "../buttons/OutsideButton2";
import {RiCalendar2Line} from "react-icons/all";
import OutsideDatePicker from "../inputs/OutsideDatePicker";

interface IProps {
	dateInterval: number;
	setDateInterval: (value: number) => void;
}

const HomepageSearch: React.FC<IProps> = (props) => {

	function onStartDateChange(d: Date): void {
		props.setDateInterval(moment(d).valueOf());
	}

	return(

		<div className="homepage-search__modal">

			<div className="homepage-search__modal-container">
				<p>Enter a date range:</p>

				<div className="homepage-search__modal-container__element">
					<div className="homepage-search__modal-container__element-text">
						<span className="homepage-search__modal-container__element-text__left">
							<OutsideDatePicker
								oneTap={true}
								ranges={[]}
								onChange={onStartDateChange}
								disabledDate={(d) => {
									return moment(d).valueOf() < moment().add(2, "day").startOf("day").valueOf()
								}}
								cleanable={false}
								value={moment(props.dateInterval).toDate()}
							/>

						</span>
					</div>

				</div>
			</div>

		</div>
	);
}

export default HomepageSearch;
