import React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Spinner} from "reactstrap";

interface IProps {
	loadingIncrement?: number;
}

const LoadingManager: React.FC<IProps> = (props: IProps) => {

	if (!props.loadingIncrement || props.loadingIncrement < 1) {
		return null;
	}

	return (
		<div className="loading-manager">
			<Spinner color="light"/>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		loadingIncrement: store.metaStore.loadingIncrement,
	}
})(LoadingManager);
