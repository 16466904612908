import React from "react";
import {PaginationInfo} from "client";
import {Input, Label} from "reactstrap";
import {paginationLimiterOptions} from "./FrameOnePaginatorLimiter";

interface IProps extends PaginationInfo {
	onChangeLimit: (newLimit: number) => void;
}

const OutsidePaginatorLimiter: React.FC<IProps> = (props) => {

	function onChangeHelper(e): void {
		props.onChangeLimit(parseInt(e?.target?.value))
	}

	return (
		<div className="outside-paginator_limiter-container">
			<div className="outside-paginator_limiter-container_inner">
				<Label className="outside-paginator_limiter-container_inner_label">
					{`Showing ${props.lowerRange} to ${props.upperRange} of ${props.total}`}
				</Label>

				<div className="outside-paginator_limiter-container_inner_input-container">
					<Label className="outside-paginator_limiter-container_inner_input-container_label">
						Show:
					</Label>
					<Input
						value={props.limit}
						type="select"
						onChange={onChangeHelper}
						className="outside-paginator_limiter-container_inner_input-container_select"
					>
						{paginationLimiterOptions?.map((option) => {
							return (
								<option value={option.value}>
									{option.label}
								</option>
							);
						})}
					</Input>
				</div>
			</div>
		</div>
	);
};

export default OutsidePaginatorLimiter;
