import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Token, User} from "client";
import {IStore} from "../redux/defaultStore";
import {useHistory, useLocation} from "react-router-dom";
import {standardUserShouldCompleteFirstTimeForm} from "../utils/standardUserShouldCompleteFirstTimeForm";

// Routes to be excluded from forcefully redirecting the user.
// I imagine we'd want to include TOS, PP, etc. that sort of thing here.
const excludedRoutes: string[] = [];

interface IProps {
	fullToken?: Token;
	currentUser?: User;
}

const StandardUserFirstTimeManager: React.FC<IProps> = (props) => {

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (
			props.fullToken &&
			standardUserShouldCompleteFirstTimeForm(props.currentUser) &&
			location.pathname !== "/user/first-time" &&
			excludedRoutes.indexOf(location.pathname) === -1
		) {
			history.replace("/user/first-time");
		}
	}, [props.fullToken, location.pathname]);

	return null;
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(StandardUserFirstTimeManager);
