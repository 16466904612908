import {Asset} from "client";

export type IFrontendFileType = FileWithSRC | Asset;
export type FileReaderResult = string | ArrayBuffer | null;

export class FileWithSRC extends File {
	public isFileWithSRC: true; // just some path of least resistance approach to make it easy to for the frontend to deal with editing collectibles (re-ordering existing assets with potentially "frontend-only" assets that have just been selected by the user)
	public imageSRC?: FileReaderResult;
	public videoSRC?: FileReaderResult;
}

/**
 * Takes a single file from an input and returns
 * an image that can be rendered.
 *
 * @param file
 */
export function renderSingleImageHelper(file: File): Promise<FileReaderResult> {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = function (): void {
			resolve(reader.result);
		};
		reader.readAsDataURL(file);
	});
}

/**
 * Takes array of files and returns array of images
 * that can be rendered on the page.
 *
 * Use this function if you just need the image sources.
 *
 * @param files
 */
export async function renderImagesHelper(files: Array<File> | FileList = []): Promise<Array<FileReaderResult>> {
	const promises = Array.from(files).map(renderSingleImageHelper);

	return await Promise.all(promises);
}

/**
 * Converts array of files from file input to format
 * of file with the source from FileReader.
 *
 * @param files
 */
export async function addBuffersToFiles(files: Array<File> | FileList = []): Promise<Array<FileWithSRC>> {
	const promises = Array.from(files).map(async (file: File): Promise<FileWithSRC> => {
		(file as FileWithSRC).imageSRC = await renderSingleImageHelper(file);
		return file as FileWithSRC;
	});

	return await Promise.all(promises);

}

/**
 * Converts array of files from file input to format
 * of file with the source from URL.createObjectURL.
 *
 * Appropriately adds the image source as a thumbnail
 * if the file is a video format.
 *
 * @param files
 */
export async function addURLsToFiles(files: Array<File> | FileList = []): Promise<Array<FileWithSRC>> {
	const promises = Array.from(files).map(async (file: File): Promise<FileWithSRC> => {

		(file as FileWithSRC).isFileWithSRC = true;

		if (file.type.includes("image")) {
			(file as FileWithSRC).imageSRC = URL.createObjectURL(file);
		} else if (file.type.includes("video")) {
			(file as FileWithSRC).videoSRC = URL.createObjectURL(file);
		}

		return file as FileWithSRC;
	});

	return Promise.all(promises);
}
