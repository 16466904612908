import React, {useState} from "react";
import OutsideDatePicker from "./inputs/OutsideDatePicker";
import OutsideButton2 from "./buttons/OutsideButton2";
import {BookingsApi, Bundle, CheckBundleAvailabilityResponse, Token} from "client";
import {AiFillHourglass, AiOutlineClockCircle, FiCheckCircle, IoHourglassOutline} from "react-icons/all";
import IconHeaderLabel from "./labels/IconHeaderLabel";
import moment from "moment";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import { Link } from "react-router-dom";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	bundle: Bundle;
}

const UserPreviewBookingAvailabilityChecker: React.FC<IProps> = (props) => {

	const [startTime, setStartTime] = useState<number>(undefined);
	const [localLoading, setLocalLoading] = useState(false);
	const [available, setAvailable] = useState<CheckBundleAvailabilityResponse>(undefined);

	/**
	 * Convert to ms for our form... Using moment here might not be needed but... y'know...
	 *
	 * @param d
	 */
	function onStartDateChange(d: Date): void {
		setStartTime(moment(d).valueOf());
		setAvailable(undefined);
	}

	/**
	 * Call the api to check if the bundle is available at the selected time & save response.
	 * Uses a local loading variable instead of Redux.
	 *
	 */
	async function checkAvailability(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new BookingsApi(getConfig(props.fullToken)).checkBundleAvailability({
				id: props.bundle?._id,
				bookBundleBody: {
					date: startTime,
				},
			});

			setAvailable(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	const bundleDurationInDays: number = (props.bundle.bookingTime / 1000 / 60 / 60 / 24);

	return (
		<div className="preview-avail-checker">
			<h3>
				Booking
			</h3>

			<p>
				Enter the desired start date for your booking, and we'll check to see if the bundle is available.
			</p>

			<div className="preview-avail-checker_date-row">
				<div className="preview-avail-checker_date-row_col preview-avail-checker_date-row_col-icon">
					<div>
						<IconHeaderLabel
							icon={AiOutlineClockCircle}
							header="Duration"
							subheader={bundleDurationInDays + " day" + (bundleDurationInDays === 1 ? "" : "s")}
							invert={true}
						/>
					</div>
				</div>

				<div className="preview-avail-checker_date-row_col preview-avail-checker_date-row_col-input">
					<OutsideDatePicker
						disabled={localLoading}
						oneTap={true}
						ranges={[]}
						onChange={onStartDateChange}
						disabledDate={(d) => {
							return moment(d).valueOf() < moment().add(2, "day").startOf("day").valueOf()
						}}
						cleanable={false}
						value={startTime !== undefined ? moment(startTime).toDate() : undefined}
					/>
				</div>
			</div>

			{(available !== undefined && !available.available) && (
				<p className="preview-avail-checker_no-message">
					This bundle is not available for the selected time. Please try another date.
				</p>
			)}

			{(available !== undefined && available.available) && (
				<p className="preview-avail-checker_yes-message">
					This bundle is available for the selected time.
				</p>
			)}

			{available?.available ? (
				<Link to={`/user/bookings/confirm?bundle=${props.bundle?._id}&start=${startTime}`}>
					<OutsideButton2
						color="forestGreen"
					>
						Book this Bundle
					</OutsideButton2>
				</Link>
			) : (
				<OutsideButton2
					color="forestGreen"
					disabled={startTime === undefined || localLoading}
					onClick={checkAvailability}
					leftIcon={localLoading && IoHourglassOutline}
				>
					{localLoading ? "Checking..." : "Check Availability"}
				</OutsideButton2>
			)}
		</div>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UserPreviewBookingAvailabilityChecker);
