import React, {ReactNode} from "react";
import {Modal} from "reactstrap";

interface IProps {
    isOpen: boolean;
    children: ReactNode;
    toggle?: () => void;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const OutsideActivitiesModal: React.FC<IProps> = (props) => {

    /**
     * Make sure the toggle exists in props before trying to call it.
     *
     */
    function toggleHelper(): void {
        if (props.toggle) {
            props.toggle();
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            fade={true}
            centered={true}
            size={props.size}
            toggle={toggleHelper}
            contentClassName="outside-activities-modal"
        >
            {props.children}
        </Modal>
    );
}

export default OutsideActivitiesModal;
