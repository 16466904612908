import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import HomepageSearch from "../../components/userHomepage/HomepageSearch";
import HomepageRecommended from "../../components/userHomepage/HomepageRecommended";
import {Bundle, BundlesApi, Token} from "client";
import getConfig from "../../utils/getConfig";
import {addError} from "../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import moment from "moment";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const defaultStartTime = moment().add(2, "days").startOf("day");

const UserHomepage: React.FC<IProps> = (props) => {

	const [bundles, setBundles] = useState<Array<Bundle>>([]);
	const [initialRender, setInitialRender] = useState<boolean>(true);
	const [dateInterval, setDateInterval] = useState<number>(defaultStartTime.valueOf());


	/**
	 * calls the api to get bundle information based on date interval from input.
	 *
	 */
	useEffect(() => {
		getBundles().then().catch();
	}, [JSON.stringify(dateInterval)]);

	/**
	 * api to get bundle information based on dateInterface useState.
	 *
	 */
	async function getBundles(): Promise<void> {
		try {
			const res = await new BundlesApi(getConfig(props.fullToken)).getAvailableBundles({
				date: dateInterval,
				// offset: 0,
				// limit: 200,
			});

			console.log("res", res);
			setBundles(res);
		} catch (err) {
			props.dispatch(addError(err));
		} finally {
			setInitialRender(false);
		}
	}

	return (
		<section className="user-homepage">
			<div
				className="user-homepage__background"
				style={{
					backgroundImage: "url(/backgrounds/green-landscape.png)",
				}}
			>
				<Container className="user-homepage__container">
					<h1>Find your next outing</h1>
					<h4>Start your next search here</h4>

					<HomepageSearch
						dateInterval={dateInterval}
						setDateInterval={setDateInterval}
					/>

					<div className="user-homepage__recommended">
						<HomepageRecommended
							initialRender={initialRender}
							bundles={bundles}
						/>
					</div>
				</Container>
			</div>
		</section>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UserHomepage);
