import React, {useState} from "react";
import {InputType} from "reactstrap/lib/Input";
import {ChangePasswordBody, CompanyAdmin, Token, UsersApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useHistory} from "react-router-dom";
import OutsideButton2 from "../buttons/OutsideButton2";
import FrameOneButtonActions from "../FrameOneButtonActions";
import ProfileImagePicker from "../inputs/ProfileImagePicker";
import classNames from "classnames";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import NumberFormat from "react-number-format";
import {Input, Label} from "reactstrap";
import PasswordSavedModal from "../modals/PasswordSavedModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: CompanyAdmin;
}

const defaultPasswordData: ChangePasswordBody = {
	confirmPassword: "",
	newPassword: "",
	oldPassword: ""
}
const PasswordSettingsForm: React.FC<IProps> = (props) => {

	const [password, setPassword] = useState<ChangePasswordBody>(defaultPasswordData);
	const [minimumCharError, setMinimumCharError] = useState<boolean>(false);
	const [matchError, setMatchError] = useState<boolean>(false);
	const [editMode, setEditMode] = useState(true);
	const [incrementor, setIncrementor] = useState<number>(1);
	const [showModal, setShowModal] = useState<boolean>(false);

	/**
	 * Call to api to update a standard user's password
	 * - also handles loading while waiting for api
	 *
	 */
	async function updatePassword(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).changePassword({
				changePasswordBody: {
					oldPassword: password.oldPassword || undefined,
					newPassword: password.newPassword || undefined,
					confirmPassword: password.confirmPassword || undefined,
				}
			});

			setShowModal(!showModal);
		} catch (e) {
			props.dispatch(addError(e));

			if (password.newPassword.length < 8) {
				setMinimumCharError(!minimumCharError);
			}

			if (password.newPassword !== password.confirmPassword) {
				setMatchError(!matchError);
			}
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Dynamic on change for updating the password form.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof ChangePasswordBody): (e?) => void {
		return (e?) => setPassword({
			...password,
			[key]: e?.target.value,
		})
	}

	/**
	 * function to toggle the edit boolean, also resets the form when toggling editMode to false
	 */
	function toggleEdit(): void {
		if (editMode === false) {
			resetForm().then().catch();
		}
		setEditMode(!editMode);
	}

	/**
	 * helper to reset default values of form
	 */
	async function resetForm(): Promise<void> {
		setPassword(defaultPasswordData);
		setIncrementor(incrementor * -1);
	}

	function isPasswordFormSendable(): boolean {
		return (password.oldPassword.length < 1 || password.newPassword.length < 1 || password.confirmPassword.length < 1);
	}

	return (
		<div className="password-settings-form">
			<React.Fragment>
				<PasswordSavedModal
					isOpen={showModal}
					onClose={() => setShowModal(!showModal)}
				/>
			</React.Fragment>

			<div className="password-settings-form__header">
				<h1>Password Settings</h1>
				<div className="password-settings-form__header-button">
				</div>
			</div>

			<div>
				<div className="password-settings-form_detail-container">
					<Label>Current Password</Label>
					<Input
						placeholder="Current Password..."
						value={password?.oldPassword}
						onChange={dynamicOnChange("oldPassword")}
						type="password"
					/>
				</div>
				<div className="password-settings-form_detail-container">
					<Label>New Password</Label>
					<Input
						placeholder="New Password..."
						value={password?.newPassword}
						onChange={dynamicOnChange("newPassword")}
						type="password"
					/>
				</div>
				<div className="password-settings-form_detail-container">
					<Label>Confirm Password</Label>
					<Input
						placeholder="Confirm Password..."
						value={password?.confirmPassword}
						onChange={dynamicOnChange("confirmPassword")}
						type="password"
					/>
				</div>

				<div className="display-personal-info_detail-container_button-container">
					<FrameOneButtonActions>
						<OutsideButton2
							color="forestGreen"
							onClick={updatePassword}
							disabled={isPasswordFormSendable()}
						>
							Save Changes
						</OutsideButton2>

						{/*<OutsideButton2*/}
						{/*	color="offWhite2"*/}
						{/*	outline={true}*/}
						{/*	onClick={toggleEdit}*/}
						{/*>*/}
						{/*	Cancel*/}
						{/*</OutsideButton2>*/}
					</FrameOneButtonActions>
				</div>
			</div>
		</div>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(PasswordSettingsForm)
