import React, {useState} from "react";
import { Link } from "react-router-dom";

interface IProps {
	image: string;
	totalItems: number;
	name: string;
	description: string;
	to?: string;
}

const descriptionLimit: number = 100;

const BundlePreviewItem: React.FC<IProps> = (props) => {

	const [fullText, setFullText] = useState<boolean>();
	const ellipsis = props.description.length > descriptionLimit ? "..." : "";
	const tempDescription = props.description.substring(0, descriptionLimit) + ellipsis;

	function toggleText(): void {
		setFullText(!fullText);
	}

	return (
		<Link
			to={props.to}
			className="bundle-preview-item"
		>
			<div className="bundle-preview-item__image-container">
				<img
					src={props.image}
					alt="inventory thumbnail"
					draggable={false}
				/>
			</div>

			<div className="bundle-preview-item__body-container">
				<span className="bundle-preview-item__body-container__subtext">{props.totalItems} item{(props.totalItems > 1 || props.totalItems === 0) ? "s" : ""}</span>

				<h3>{props.name}</h3>

				<div className="bundle-preview-item__body-container__body">
					<p>{fullText ? props.description : tempDescription}</p>
				</div>
			</div>
		</Link>
	);
}

export default BundlePreviewItem;
