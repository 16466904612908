import moment from "moment";

export interface IFrontendDateInput {
	year: string;
	month: string;
	date: string;
}

/**
 * Takes a number and spits out the interface we use for the date inputs when presented as a triad.
 *
 * @param date
 */
export function convertMStoFrontendDateInput(date: number): IFrontendDateInput {
	const m = moment(date);

	const monthString = (m.month() + 1).toString();
	const dateString = m.date().toString();

	return {
		year: m.year().toString(),
		month: monthString.length === 1 ? ("0" + monthString) : monthString,
		date: dateString.length === 1 ? ("0" + dateString) : dateString,
	}
}

/**
 * Return the IFrontendDateInput object back into a number for our convenience.
 * Will get NaN if the date is invalid (ie. date = 0, month > 12, etc).
 *
 * @param date
 */
export function convertFrontendDateInputToMS(date: IFrontendDateInput): number {
	return moment({
		year: Number(date.year),
		month: Number(date.month) - 1,
		date: Number(date.date),
	}).valueOf();
}
