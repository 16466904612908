"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBundlesResponseToJSON = exports.GetBundlesResponseFromJSONTyped = exports.GetBundlesResponseFromJSON = void 0;
const _1 = require("./");
function GetBundlesResponseFromJSON(json) {
    return GetBundlesResponseFromJSONTyped(json, false);
}
exports.GetBundlesResponseFromJSON = GetBundlesResponseFromJSON;
function GetBundlesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'bundles': (json['bundles'].map(_1.BundleFromJSON)),
    };
}
exports.GetBundlesResponseFromJSONTyped = GetBundlesResponseFromJSONTyped;
function GetBundlesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'bundles': (value.bundles.map(_1.BundleToJSON)),
    };
}
exports.GetBundlesResponseToJSON = GetBundlesResponseToJSON;
