import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {RouteProps} from "react-router";
import {IStore} from "../../redux/defaultStore";
import {Bundle, BundlesApi, Token} from "client";
import {Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import moment from "moment";
import UserPreviewBookingAvailabilityChecker from "../../components/UserPreviewBookingAvailabilityChecker";
import PreviewBundleCharacteristicsAndDescription from "../../components/PreviewBundleCharacteristicsAndDescription";
import PreviewBundleItemList from "../../components/PreviewBundleItemList";
import PreviewBundleGallery from "../../components/PreviewBundleGallery";

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
}
// todo redirect on this page &&&&&&&&& the Confirm-Page if the id of the bundle isn't present
const UserPreviewBooking: React.FC<IProps> = (props) => {

	const query = new URLSearchParams(props.location.search);
	const bundleID: string = query.get("bundle");

	const [bundle, setBundle] = useState<Bundle>(undefined);
	const [checkingAvailability, setCheckingAvailability] = useState(false);

	useEffect(() => {
		if (bundleID) {
			getBundle().then().catch();
		}
	}, [bundleID]);

	/**
	 * Call the api to get the bundle based on the query parameter from previous page.
	 *
	 */
	async function getBundle(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BundlesApi(getConfig(props.fullToken)).getBundle({
				id: bundleID,
			});

			setBundle(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	if (!bundle) {
		return null;
	}

	const numberOfItems: number = bundle?.items?.length;

	return (
		<Container className="authenticated-user-page preview-booking-page">
			<h1>{bundle?.name}</h1>
			<p className="preview-booking-page_items-count">
				{numberOfItems + " item" + (numberOfItems === 1 ? "" : "s")}
			</p>

			<div className="card-page-split-layout-container">
				<div className="card-page-col-left">
					<PreviewBundleGallery bundle={bundle}/>

					<div className="preview-booking-page_about">
						<h3>
							About
						</h3>

						<PreviewBundleCharacteristicsAndDescription
							characteristics={bundle?.requiredCharacteristics}
							description={bundle?.description}
						/>
					</div>

					<hr className="preview-booking-page_hr"/>

					{bundle?.items?.length > 0 && (
						<div className="preview-booking-page_items">
							<div>
								<h3>
									Items included
								</h3>
								<p className="preview-booking-page_items_count">
									{numberOfItems + " item" + (numberOfItems === 1 ? "" : "s")}
								</p>
							</div>

							<PreviewBundleItemList bundleItems={bundle?.items}/>
						</div>
					)}
				</div>

				<div className="card-page-col-right">
					<UserPreviewBookingAvailabilityChecker bundle={bundle}/>
				</div>
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props
	}
})(UserPreviewBooking);
