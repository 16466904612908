import React from "react";
import {Bundle} from "client";

interface IProps {
	bundle: Bundle;
}

const ViewBookingBundleDetails: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card view-booking-bundle-details">
			<div className="outside-card-section">
				<div className="view-booking-bundle-details_thumbnail-container">
					<img
						src={props.bundle?.thumbnail?.url || "/images/placeholder.png"}
						alt={`${props.bundle?.name}`}
					/>
				</div>

				<h3 className="view-booking-bundle-details_bundle-name">
					{props.bundle?.name}
				</h3>

				<p className="view-booking-bundle-details_items-quantity">
					{(props.bundle?.items?.length + " item") + (props.bundle?.items?.length === 1 ? "" : "s")}
				</p>
			</div>
		</div>
	);
};

export default ViewBookingBundleDetails;
