"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class UsersApi extends runtime.BaseAPI {
    /**
     * Used by users to change their passwords.
     */
    async changePasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangePasswordBodyToJSON(requestParameters.changePasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to change their passwords.
     */
    async changePassword(requestParameters) {
        await this.changePasswordRaw(requestParameters);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling changeUserPassword.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/change-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters) {
        await this.changeUserPasswordRaw(requestParameters);
    }
    /**
     * Used to create new company admin accounts
     */
    async createCompanyAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateCompanyAdminBodyToJSON(requestParameters.createCompanyAdminBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CompanyAdminFromJSON(jsonValue));
    }
    /**
     * Used to create new company admin accounts
     */
    async createCompanyAdmin(requestParameters) {
        const response = await this.createCompanyAdminRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to create new partner admin accounts
     */
    async createPartnerAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partner-admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreatePartnerAdminBodyToJSON(requestParameters.createPartnerAdminBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PartnerAdminFromJSON(jsonValue));
    }
    /**
     * Used to create new partner admin accounts
     */
    async createPartnerAdmin(requestParameters) {
        const response = await this.createPartnerAdminRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to create new standard user accounts
     */
    async createStandardUserRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("CompanyAdmin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/standard-users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateStandardUserBodyToJSON(requestParameters.createStandardUserBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StandardUserFromJSON(jsonValue));
    }
    /**
     * Used to create new standard user accounts
     */
    async createStandardUser(requestParameters) {
        const response = await this.createStandardUserRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to create standard users in bulk through a CSV.
     */
    async createStandardUsersFromCSVRaw(requestParameters) {
        if (requestParameters.companyID === null || requestParameters.companyID === undefined) {
            throw new runtime.RequiredError('companyID', 'Required parameter requestParameters.companyID was null or undefined when calling createStandardUsersFromCSV.');
        }
        if (requestParameters.csv === null || requestParameters.csv === undefined) {
            throw new runtime.RequiredError('csv', 'Required parameter requestParameters.csv was null or undefined when calling createStandardUsersFromCSV.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("CompanyAdmin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.companyID !== undefined) {
            formParams.append('companyID', requestParameters.companyID);
        }
        if (requestParameters.csv !== undefined) {
            formParams.append('csv', requestParameters.csv);
        }
        const response = await this.request({
            path: `/standard-users/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to create standard users in bulk through a CSV.
     */
    async createStandardUsersFromCSV(requestParameters) {
        await this.createStandardUsersFromCSVRaw(requestParameters);
    }
    /**
     * Used by admins to disable a user
     */
    async disableUserRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling disableUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to disable a user
     */
    async disableUser(requestParameters) {
        await this.disableUserRaw(requestParameters);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUserRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling enableUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/enable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUser(requestParameters) {
        await this.enableUserRaw(requestParameters);
    }
    /**
     * Used by users to update their profile for the first time.
     */
    async firstTimeFormRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/first-time-form`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateProfileBodyToJSON(requestParameters.updateProfileBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserResponseFromJSON(jsonValue));
    }
    /**
     * Used by users to update their profile for the first time.
     */
    async firstTimeForm(requestParameters) {
        const response = await this.firstTimeFormRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getCompanyAdminsRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getCompanyAdmins.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getCompanyAdmins.');
        }
        const queryParameters = {};
        if (requestParameters.company !== undefined) {
            queryParameters['company'] = requestParameters.company;
        }
        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetCompanyAdminsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getCompanyAdmins(requestParameters) {
        const response = await this.getCompanyAdminsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPartnerAdminsRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getPartnerAdmins.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getPartnerAdmins.');
        }
        const queryParameters = {};
        if (requestParameters.partner !== undefined) {
            queryParameters['partner'] = requestParameters.partner;
        }
        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partner-admins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetPartnerAdminsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getPartnerAdmins(requestParameters) {
        const response = await this.getPartnerAdminsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getProfileRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserResponseFromJSON(jsonValue));
    }
    /**
     */
    async getProfile() {
        const response = await this.getProfileRaw();
        return await response.value();
    }
    /**
     */
    async getStandardUsersRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getStandardUsers.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getStandardUsers.');
        }
        const queryParameters = {};
        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("CompanyAdmin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/standard-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetStandardUsersResponseFromJSON(jsonValue));
    }
    /**
     */
    async getStandardUsers(requestParameters) {
        const response = await this.getStandardUsersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUserRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUser(requestParameters) {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUsersRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getUsers.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getUsers.');
        }
        const queryParameters = {};
        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUsersResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUsers(requestParameters) {
        const response = await this.getUsersRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async loginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.LoginBodyToJSON(requestParameters.loginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async login(requestParameters) {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by users to update their profile.
     */
    async updateProfileRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateProfileBodyToJSON(requestParameters.updateProfileBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserResponseFromJSON(jsonValue));
    }
    /**
     * Used by users to update their profile.
     */
    async updateProfile(requestParameters) {
        const response = await this.updateProfileRaw(requestParameters);
        return await response.value();
    }
}
exports.UsersApi = UsersApi;
