import React, {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {
	Asset,
	AssetsApi,
	StandardUser,
	Token, UpdateProfileBody,
	UpdateProfileRequest,
	User,
	UsersApi,
} from "client";
import {Input} from "reactstrap";
import classNames from "classnames";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import OutsideButton2 from "../buttons/OutsideButton2";
import ProfileImagePicker from "../inputs/ProfileImagePicker";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import {isAsset} from "../../utils/typeGuards";
import FrameOneButtonActions from "../FrameOneButtonActions";

interface IUpdateProfileRequestFrontend extends Omit<UpdateProfileBody, "image"> {
	image: Asset | FileWithSRC;
}

export const defaultCreatePersonalInfoForm: IUpdateProfileRequestFrontend = {
	email: "",
	firstName: "",
	lastName: "",
	phoneNumber: {
		countryCode: undefined,
		nationalNumber: "",
	},
	image: undefined,
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: StandardUser;
}

const DisplayPersonalInfo: React.FC<IProps> = (props) => {

	const [existingUser, setExistingUser] = useState<User>(); // A copy of the existing user for reference

	const [updateProfileRequest, setUpdateProfileRequest] = useState<IUpdateProfileRequestFrontend>(defaultCreatePersonalInfoForm);
	const [editMode, setEditMode] = useState(false);
	const [incrementor, setIncrementor] = useState<number>(1);

	useEffect(() => {
		getExistingUserInfo().then().catch();
	}, []);

	/**
	 * Call api on load to get the full data for the user we'll be editing. Save a version with setExistingUser
	 * that we won't be touching and only use as reference, as well as construct the form object we will be editing.
	 *
	 */
	async function getExistingUserInfo(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const userRes = await new UsersApi(getConfig(props.fullToken)).getProfile();
			setExistingUser(userRes.user);
			setUpdateProfileRequest({
				email: userRes.user?.email,
				firstName: userRes.user?.firstName,
				lastName: userRes.user?.lastName,
				phoneNumber: {
					countryCode: userRes.user?.phoneNumber?.countryCode,
					nationalNumber: userRes.user?.phoneNumber?.nationalNumber,
				},
				image: userRes.user?.image,
			});
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Call to api to update a standard user's profile
	 * - also handles loading while waiting for api
	 *
	 */
	async function updateUserProfile(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			// Upload the profile image, checking to make sure it's a new file upload with typeguard
			let profileImage: Asset;
			if (updateProfileRequest.image && isFileWithSRC(updateProfileRequest.image)) {
				profileImage = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: updateProfileRequest.image,
				});
			} else if (isAsset(updateProfileRequest.image)) {
				profileImage = updateProfileRequest.image;
			}

			const res = await new UsersApi(getConfig(props.fullToken)).updateProfile({
				updateProfileBody: {
					email: updateProfileRequest?.email || undefined,
					firstName: updateProfileRequest?.firstName || undefined,
					lastName: updateProfileRequest?.lastName || undefined,
					phoneNumber: (updateProfileRequest?.phoneNumber?.countryCode || updateProfileRequest?.phoneNumber?.nationalNumber) ? {
						countryCode: updateProfileRequest?.phoneNumber?.countryCode,
						nationalNumber: updateProfileRequest?.phoneNumber?.nationalNumber || undefined,
					} : undefined,
					image: profileImage?._id,
				},
			});

			props.dispatch(updateCurrentUser(res.user));
			await getExistingUserInfo();
			toggleEdit();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	async function onImageChange(e: ChangeEvent<HTMLInputElement> = undefined): Promise<void> {
		setUpdateProfileRequest({
			...updateProfileRequest,
			image: e ? (await addURLsToFiles(e?.target.files))[0] : undefined,
		});
	}

	/**
	 * Dynamic on change for updating the updatedUser form.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof IUpdateProfileRequestFrontend): (e?) => void {
		return (e?) => setUpdateProfileRequest({
			...updateProfileRequest,
			[key]: e?.target.value,
		})
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param dialCodePart
	 */
	function onCountryCodeChange(dialCodePart: string): void {
		setUpdateProfileRequest({
			...updateProfileRequest,
			phoneNumber: {
				...updateProfileRequest.phoneNumber,
				countryCode: dialCodePart,
			},
		});
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param values
	 */
	function onNationalNumberChange(values: NumberFormatValues): void {
		setUpdateProfileRequest({
			...updateProfileRequest,
			phoneNumber: {
				...updateProfileRequest.phoneNumber,
				nationalNumber: values.value,
			}
		});
	}

	/**
	 * helper to reset default values of form
	 */
	async function resetForm(): Promise<void> {
		setUpdateProfileRequest(defaultCreatePersonalInfoForm);
		await getExistingUserInfo();
		setIncrementor(incrementor * -1);
	}

	/**
	 * function to toggle the edit boolean, also resets the form when toggling editMode to false
	 */
	function toggleEdit(): void {
		if (editMode === false) {
			resetForm().then().catch();
		}
		setEditMode(!editMode);
	}

	/**
	 * function to render Inputs
	 * @param key
	 */
	function createInputElement(key: keyof Omit<IUpdateProfileRequestFrontend, "image" | "phoneNumber">): ReactElement {
		const tempLabel = key.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');

		return (
			<div
				className={classNames("display-personal-info_detail-container_element", {
					"disable": !editMode,
				})}
			>
				<label className="text-capitalize">{tempLabel}</label>
				<Input
					placeholder={`Enter ${tempLabel}`}
					value={updateProfileRequest?.[key]}
					onChange={dynamicOnChange(key)}
				/>
			</div>
		);
	}

	if (existingUser === undefined) {
		return null;
	}

	return (
		<div className="display-personal-info">
			<div className="display-personal-info__header">
				<h1>Personal Information</h1>
				<div className="display-personal-info__header-button">
					<OutsideButton2
						color={editMode ? "offWhite2" : "forestGreen"}
						outline={editMode}
						onClick={toggleEdit}
						className="d-flex"
					>
						{editMode ? "Cancel" : "Edit"}
					</OutsideButton2>
				</div>
			</div>

			<div className="display-personal-info__image-container">
				<h4>Profile picture</h4>

				<ProfileImagePicker
					image={updateProfileRequest.image}
					onImageChange={onImageChange}
					edit={editMode}
				/>
			</div>

			<div className="display-personal-info_detail-container">
				<div className="display-personal-info_detail-container_header">
					<h4>Full name</h4>
				</div>

				<div>
					<p className={classNames({"disable": editMode})}>
						{`${updateProfileRequest.firstName} ${updateProfileRequest.lastName}`}
					</p>

					<div className="display-personal-info_detail-container_full-name">
						{createInputElement("firstName")}
						{createInputElement("lastName")}
					</div>
				</div>

			</div>

			<div className="display-personal-info_detail-container">
				<div className="display-personal-info_detail-container_header">
					<h4>Email address</h4>
				</div>

				<div>
					<p className={classNames({"disable": editMode})}>
						{updateProfileRequest?.email || "-"}
					</p>

					{createInputElement("email")}
				</div>
			</div>

			<div className="display-personal-info_detail-container">
				<div className="display-personal-info_detail-container_header">
					<h4>Company</h4>
				</div>
				<p>{props.currentUser?.company?.name || "-"}</p>
			</div>

			<div className="display-personal-info_detail-container">
				<div className="display-personal-info_detail-container_header">
					<h4>Phone number</h4>
				</div>

				<div>
					<p className={classNames({"disable": editMode})}>
						{updateProfileRequest?.phoneNumber?.countryCode || "-"} {updateProfileRequest?.phoneNumber?.nationalNumber || ""}
					</p>

					<div
						className={classNames("display-personal-info_detail-container_phone-number-container", {
							"disable": !editMode,
						})}
					>
						<div>
							<CountryCodeSelector
								value={updateProfileRequest?.phoneNumber?.countryCode}
								valueKey="code"
								onChange={onCountryCodeChange}
							/>
						</div>

						<div>
							<NumberFormat
								placeholder="(000) 000-0000"
								value={updateProfileRequest?.phoneNumber?.nationalNumber}
								customInput={Input}
								allowNegative={false}
								decimalScale={0}
								onValueChange={onNationalNumberChange}
							/>
						</div>
					</div>

					<div
						className={classNames("display-personal-info_detail-container_button-container", {
								"disable": !editMode,
							}
						)}
					>
						<FrameOneButtonActions>
							<OutsideButton2
								color="forestGreen"
								onClick={updateUserProfile}
							>
								Save Changes
							</OutsideButton2>

							<OutsideButton2
								color="offWhite2"
								outline={true}
								onClick={toggleEdit}
							>
								Cancel
							</OutsideButton2>
						</FrameOneButtonActions>
					</div>
				</div>
			</div>
		</div>
	);
}

DisplayPersonalInfo.defaultProps = {}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(DisplayPersonalInfo);
