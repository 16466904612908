import React from "react";
import {PaginationInfo} from "client";
import {Input, Label} from "reactstrap";
import {ISelectOption} from "../SelectOptionsFactory";

interface IProps extends PaginationInfo {
	onChangeLimit: (newLimit: number) => void;
}

// Uncomment the first vew "low" values for ease of testing.
export const paginationLimiterOptions: Array<ISelectOption> = [
	// {value: 1, label: "1"},
	// {value: 2, label: "2"},
	// {value: 3, label: "3"},
	{value: 10, label: "10"},
	{value: 20, label: "20"},
	{value: 30, label: "30"},
	{value: 50, label: "50"},
	{value: 100, label: "100"},
];

const FrameOnePaginatorLimiter: React.FC<IProps> = (props) => {

	function onChangeHelper(e): void {
		props.onChangeLimit(parseInt(e?.target?.value))
	}

	return (
		<div className="frame-one-paginator_limiter-container">
			<div className="frame-one-paginator_limiter-container_limiter">
				<Label>
					{`Showing ${props.lowerRange} to ${props.upperRange} of ${props.total}`}
				</Label>

				<Input
					value={props.limit}
					type="select"
					onChange={onChangeHelper}
				>
					{paginationLimiterOptions?.map((option) => {
						return (
							<option value={option.value}>
								{option.label}
							</option>
						);
					})}
				</Input>
			</div>
		</div>
	);
};

export default FrameOnePaginatorLimiter;
