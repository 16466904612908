import React, {useEffect, useState} from "react";
import {GetNewQuestionResponse, QuestionsApi, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Container} from "reactstrap";
import {addError} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {RouteProps} from "react-router";
import OutsideButton2 from "../../components/buttons/OutsideButton2";
import {FiChevronRight} from "react-icons/all";
import OutsideCardFormButtons from "../../components/OutsideCardFormButtons";
import OutsideTextButton2 from "../../components/buttons/OutsideTextButton2";
import QuestionAnswerSelection from "../../components/inputs/QuestionAnswerSelection";
import {filterDuplicateStrings} from "../../utils/filterDuplicateStrings";
import {Link} from "react-router-dom";
import LocalLoader from "../../components/LocalLoader";
import classNames from "classnames";
import UserAnsweredAllAvailableQuestionsModal from "../../components/modals/UserAnsweredAllAvailableQuestionsModal";

interface IProps extends RouteProps {
	fullToken?: Token;
	dispatch?: any;
}

const UserQuestionnaireQuestionPage: React.FC<IProps> = (props) => {

	const [localLoading, setLocalLoading] = useState(false);
	const [skips, setSkips] = useState<Array<string>>([]);
	const [currentQuestion, setCurrentQuestion] = useState<GetNewQuestionResponse>(undefined);
	const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number>(undefined);
	const [showDoneModal, setShowDoneModal] = useState(false);

	useEffect(() => {
		getQuestion().then().catch();
	}, [JSON.stringify(skips)]);

	async function getQuestion(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new QuestionsApi(getConfig(props.fullToken)).getNewQuestion({
				getNewQuestionBody: {
					exclude: filterDuplicateStrings(skips),
				},
			});

			setCurrentQuestion(res);
			setSelectedAnswerIndex(undefined);

			if (res.outOfQuestions) {
				setShowDoneModal(true);
			}
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	async function submitAnswer(): Promise<void> {
		setLocalLoading(true);

		try {
			await new QuestionsApi(getConfig(props.fullToken)).createAnswer({
				id: currentQuestion?.question?._id,
				createAnswerBody: {
					answer: currentQuestion?.question?.answerOptions?.[selectedAnswerIndex]?.answer,
				},
			});

			// call the get question api again
			await getQuestion();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	function onSkipQuestion(): void {
		setSkips(skips.concat(currentQuestion?.question?._id));
	}

	return (
		<React.Fragment>
			<UserAnsweredAllAvailableQuestionsModal isOpen={showDoneModal}/>

			<Container className="authenticated-user-page user-questionnaire-question-page">
				<div className="user-questionnaire-question-page_text-content">
					<h1>
						Questionnaire
					</h1>
					<p>
						Answer each question as accurately as possible so we can provide the best recommendations, or
						skip
						any that you don't feel like answering right now. Skipped questions can be answered at a later
						time,
						and your answers to any question can be updated by going to your <Link
						to="/user/account/questionnaire-results">profile</Link>.
					</p>
				</div>

				{(currentQuestion && !currentQuestion.outOfQuestions) && (
					<div className="outside-card">
						<div className="outside-card-section">
							<h2 className="user-questionnaire-question-page_question-title">
								{currentQuestion?.question?.question}
							</h2>

							<QuestionAnswerSelection
								answers={currentQuestion?.question?.answerOptions}
								selectedAnswer={selectedAnswerIndex}
								onSelectAnswer={setSelectedAnswerIndex}
							/>
						</div>

						<OutsideCardFormButtons>
							<OutsideTextButton2 onClick={onSkipQuestion}>
								Skip question for now
							</OutsideTextButton2>

							<OutsideButton2
								type="submit"
								color="forestGreen"
								rightIcon={FiChevronRight}
								onClick={submitAnswer}
								disabled={selectedAnswerIndex === undefined}
							>
								Next
							</OutsideButton2>
						</OutsideCardFormButtons>

						<div
							className={classNames("user-questionnaire-question-page_loading-cover", {
								"user-questionnaire-question-page_loading-cover_visible": localLoading
							})}
						>
							<LocalLoader/>
						</div>
					</div>
				)}

				{(!currentQuestion && localLoading) && (
					<LocalLoader/>
				)}
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UserQuestionnaireQuestionPage);
