import React from "react";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import {ReactComponent as OutsideCheck} from "../../images/outside-check.svg";
import {Bundle} from "client";
import OutsideButton2 from "../buttons/OutsideButton2";
import {useHistory} from "react-router-dom";

interface IProps {
	isOpen: boolean;
	bundle: Bundle;
}

const ConfirmBookingSuccessModal: React.FC<IProps> = (props) => {

	const history = useHistory();

	/**
	 * Replace route in history to "prevent" user "backing" into booking flow accidentally.
	 *
	 */
	function onDismiss(e?): void {
		e?.preventDefault();
		history.replace("/user/calendar");
	}

	return (
		<OutsideActivitiesModal isOpen={props.isOpen}>
			<OutsideActivitiesModalBody className="confirm-booking-success-modal-content">
				<OutsideCheck/>

				<h2 className="confirm-booking-success-modal-content_title">
					Booking Successful!
				</h2>

				<p>
					{`You've successfully booked ${props.bundle?.name}.`}
				</p>
			</OutsideActivitiesModalBody>

			<OutsideActivitiesModalFooter>
				<OutsideButton2
					color="forestGreen"
					onClick={onDismiss}
					className="confirm-booking-success-modal-dismiss-button"
				>
					View Bookings
				</OutsideButton2>
			</OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
};

export default ConfirmBookingSuccessModal;
