import React, {ReactElement} from "react";
import {BundleItem, Equipment} from "client";

interface IProps {
	items: Array<BundleItem>;
}

const ViewBookingDetailsBundleItems: React.FC<IProps> = (props) => {

	function createGridItem(item: BundleItem, index: number): ReactElement {
		return (
			<div
				key={`grid-item-${index}`}
				className="history-grid-item__body-container__grid-item"
			>
				<div className="history-grid-item__body-container__grid-item__image-container">
					<img
						src={item.equipmentOptions?.[0]?.thumbnail ? item.equipmentOptions?.[0]?.thumbnail?.url : "/images/placeholder.png"}
						alt={`${item.name}`}
					/>
				</div>

				<div className="history-grid-item__body-container__grid-item__body-container">
					<h4>{item.name}</h4>
					<span>Qty: {item.quantity}</span>
				</div>
			</div>
		);
	}

	return (
		<div className="outside-card history-grid-item">
			<div className="outside-card-section history-grid-item__header-container">
				<h2 className="outside-card-header">
					Items
				</h2>
				<span className="history-grid-item__header-container__subheader">{props.items?.length} total</span>
			</div>

			<div className="outside-card-section">
				<div className="history-grid-item__body-container">
					{props.items?.length ? (
						props.items.map(createGridItem)
					) : (
						<p className="history-grid-item__body-container__empty-item-list">No items in this bundle.</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewBookingDetailsBundleItems;
