import React, {ReactElement, ReactNode} from "react";
import classNames from "classnames";

interface IProps<T> {
    children: ReactNode;
    onToggle: (val: T) => void;
    disabled?: boolean;
    name: string;
    value: T;
    checked: boolean;
    className?: string;
    radioClassName?: string;
}

function OutsideActivitiesRadio<T>(props: IProps<T>): ReactElement {

    /**
     * onClick helper for when clicking on a part of the component
     * that is not directly the input itself.
     *
     * Don't call the toggle function if this input is disabled.
     *
     */
    function onClickHelper(): void {
        if (!props.disabled) {
            props.onToggle(props.value);
        }
    }

    return (
        <div
            className={classNames("outside-activities-radio", props.className, {
                "outside-activities-radio": props.disabled
            })}
            onClick={onClickHelper}
        >

            <div>
                <div className="pretty p-default p-round p-smooth">
                    <input
                        type="radio"
                        checked={props.checked}
                        onChange={onClickHelper}
                        disabled={props.disabled}
                        name={props.name}
                    />

                    <div className={classNames("state", props.radioClassName)}>
                        <label>
                            {/*{props.children}*/}
                        </label>
                    </div>
                </div>
            </div>

            <div>
                <label className="outside-activities-radio_real-label">
                    {props.children}
                </label>
            </div>
        </div>
    );
}

OutsideActivitiesRadio.defaultProps = {
    radioClassName: "p-safetyOrange",
}

export default OutsideActivitiesRadio;
