import React, {ReactNode} from "react";
import {Booking} from "client";
import moment, {Moment} from "moment";
import classNames from "classnames";
import UserUpcomingCalendarItem from "./UserUpcomingCalendarItem";

interface IProps {
	bookings: Array<Booking>;
}

const UserUpcomingCalendarList: React.FC<IProps> = (props) => {

	function renderCalendarItems(_bookings: Array<Booking>): ReactNode {
		const establishedMonths: { [key: number]: true } = {};

		return _bookings.map((booking: Booking, i: number) => {
			const bookingMoment: Moment = moment(booking.createdAt);
			const bookingStartOfMonth: number = bookingMoment.startOf("month").valueOf();

			let renderNewMonthHeader: boolean = false;
			if (!establishedMonths[bookingStartOfMonth]) {
				establishedMonths[bookingStartOfMonth] = true;
				renderNewMonthHeader = true;
			}

			return (
				<React.Fragment>
					{renderNewMonthHeader && (
						<div
							key={`partner-calendar-month-header_${i}`}
							className="calendar_section-header"
						>
							<b>
								{bookingMoment.format("MMMM YYYY")}
							</b>
						</div>
					)}

					<div
						key={`user-calendar-item_${i}`}
						className={classNames("calendar_section-item", {
							"calendar_section-item-first-of-month": renderNewMonthHeader,
							"calendar_section-item-not-first-of-month": !renderNewMonthHeader,
						})}
					>
						<UserUpcomingCalendarItem booking={booking}/>
					</div>
				</React.Fragment>
			);
		});
	}

	return (
		<div>
			{renderCalendarItems(props.bookings)}
		</div>
	);
};

export default UserUpcomingCalendarList;
