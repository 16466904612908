import React, {ReactNode} from "react";
import {FiChevronRight} from "react-icons/all";
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";

export interface IBreadCrumbItem {
	label: string;
	route: string;
}

interface IProps {
	crumbs: Array<IBreadCrumbItem>;
}

const BreadCrumbs: React.FC<IProps> = (props) => {

	const location = useLocation();

	function makeCrumb(item: IBreadCrumbItem, i: number): ReactNode {
		return (
			<div
				key={`bread-crumb-item_${item.label}_${i}`}
				className="bread-crumb-container_item"
			>
				{i > 0 && (
					<FiChevronRight className="bread-crumb-container_item_icon"/>
				)}

				<Link
					to={item.route}
					className={classNames("bread-crumb-container_item_link", {
						"bread-crumb-container_item_link_disabled": item.route.includes(location.pathname),
					})}
				>
					{item.label}
				</Link>
			</div>
		);
	}

	return (
		<div className="bread-crumb-container">
			{props.crumbs?.map(makeCrumb)}
		</div>
	);
};

BreadCrumbs.defaultProps = {
	crumbs: [],
};

export default BreadCrumbs;
