import React from "react";
import {omit} from "lodash";
import {Button, ButtonProps} from "reactstrap";

interface IProps extends ButtonProps {
    icon?: React.FC;
}

const OutsideActivitiesButton: React.FC<IProps> = (props) => {
    const Icon = props.icon;

    return (
        <Button {...omit(props, "children", "icon")}>
            {props.icon && <Icon/>}

            {props.children && (
                <span>{props.children}</span>
            )}
        </Button>
    );
}

export default OutsideActivitiesButton;
