import React, {ChangeEventHandler, useState} from "react";
import {Link, RouteComponentProps, useHistory} from "react-router-dom";
import {Input, Label} from "reactstrap";
import OutsideActivitiesButton from "../components/OutsideActivitiesButton";
import {connect} from "react-redux";
import {ResetPasswordBody, VerificationsApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";

type IResetPasswordBodyFrontend = Omit<ResetPasswordBody, "passwordResetRequestID">;

const defaultResetPassword: IResetPasswordBodyFrontend = {
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
};

interface IProps extends RouteComponentProps {
    dispatch?: any;
}

const ResetPasswordPage: React.FC<IProps> = (props) => {

    const history = useHistory();
    const [view, setView] = useState<"password" | "text">("password");
    const query = new URLSearchParams(props.location?.search);
    const passwordResetRequestID = query.get("reset-id");

    const [form, setForm] = useState<IResetPasswordBodyFrontend>(defaultResetPassword);

    function changeHandler(key: keyof IResetPasswordBodyFrontend): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setForm({
                ...form,
                [key]: e.target.value,
            });
        }
    }

    async function resetPasswordVerifier(e?): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {
            await new VerificationsApi(getConfig()).verifyPasswordReset({
                resetPasswordBody: {
                    passwordResetRequestID: passwordResetRequestID,
                    verificationCode: form.verificationCode || undefined,
                    newPassword: form.newPassword || undefined,
                    confirmPassword: form.confirmPassword || undefined,
                },
            });

            history.push("/forgot-password/success");
        } catch(err) {
            props.dispatch(addError(err));
        }

        props.dispatch(decrementLoading());
    }

    function showPasswordHelper(): void {
        if (view === "password") {
            setView("text");
        } else {
            setView("password");
        }
    }

    return (
        <div className="reset-password-page">
            <div className="reset-password-page_container">

                <h1>Reset Password</h1>

                <p>
                    Enter a new password for your account.
                </p>

                <div className="reset-password-page_container_form">
                    <form onSubmit={resetPasswordVerifier}>
                        <Label className="label-p2">New password</Label>
                        <Input
                            type={view}
                            name="new-password-reset-input"
                            placeholder="Enter new password"
                            onChange={changeHandler("newPassword")}
                            value={form.newPassword}
                        />

                        <div className="reset-password-page_container_form_sub-text">
                            <p>
                                Minimum 8 characters
                            </p>

                            <OutsideActivitiesButton
                                color="link"
                                onClick={showPasswordHelper}
                            >
                                show password
                            </OutsideActivitiesButton>
                        </div>


                        <Label className="label-p2">Confirm password</Label>
                        <Input
                            type={view}
                            name="confirm-password-reset-input"
                            placeholder="Confirm new password"
                            onChange={changeHandler("confirmPassword")}
                            value={form.confirmPassword}
                        />

                        <Label className="label-p2">Enter email verification code</Label>
                        <Input
                            type="text"
                            name="email-verification-code"
                            placeholder="Enter code"
                            onChange={changeHandler("verificationCode")}
                            value={form.verificationCode}
                        />

                        <div className="reset-password-page_container_form_button">
                            <OutsideActivitiesButton
                                type="submit"
                                color="forestGreen"
                                onClick={resetPasswordVerifier}
                            >
                                Reset password
                            </OutsideActivitiesButton>
                        </div>
                    </form>
                </div>

                <p className="reset-password-page_container_login-link">
                    Back to <Link to="/">Login page</Link>
                </p>
            </div>
        </div>
    );
}

export default connect()(ResetPasswordPage);
