import React, {useEffect, useState} from "react";
import {
	defaultFrontendPagination,
	FrontendPaginationWithRenderKey
} from "../../components/tables/FrameOnePaginator";
import {addError} from "../../redux/meta/MetaActions";
import {Container} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	BookingQueryType,
	EquipmentBookingsApi,
	GetEquipmentBookingsResponse,
	PartnerAdmin,
	Token
} from "client";
import PageTabSwitcher from "../../components/PageTabSwitcher";
import {RouteProps} from "react-router";
import PartnerUpcomingCalendarList from "../../components/calendars/PartnerUpcomingCalendarList";
import getConfig from "../../utils/getConfig";
import OutsideButton2 from "../../components/buttons/OutsideButton2";
import LocalLoader from "../../components/LocalLoader";
import {useHistory} from "react-router-dom";

const bookingTypeMap: {[key: string]: BookingQueryType} = {
	upcoming: BookingQueryType.UPCOMING,
	current: BookingQueryType.CURRENT,
	history: BookingQueryType.PAST,
}

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: PartnerAdmin;
}

const PartnerCalendarPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const listType: string = query.get("list");

	const [equipmentBookings, setEquipmentBookings] = useState<GetEquipmentBookingsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPaginationWithRenderKey>(undefined);
	const [localLoading, setLocalLoading] = useState(false);

	/**
	 * Explanation of useEffects on this page and their interactions.
	 *
	 * I've included 3 useEffects to accomplish my goals of using a single component for multiple "pages" which is really
	 * just the query changing on a single page (and the "tabs" in the UI updating to reflect it), but each query update
	 * reflects a new entry into the browser history (intentional).
	 *
	 * I generally dislike including multiple useEffects that interact with each other, but I think this time we've found
	 * a good balance with fairly simple flow(s)...
	 *
	 * There should only be 3 possible "flows" that happen with these useEffects; each scenario explained along with what,
	 * if any each useEffect will do;
	 *
	 * 1. Initial Render / Mount
	 * i) `listType useEffect` shouldn't do anything, as when it runs `equipmentBookings` WILL be undefined.
	 * ii) `equipmentBookings useEffect` acts as the point of entry for the initial render, checks that the value it watches
	 * is undefined, and in turn sets the pagination state for the first time.
	 * iii) `Pagination useEffect` doesn't do anything initially, as when it runs on the first render `frontendPagination`
	 * will not be set, and it will do nothing. But, because of the `equipmentBookings useEffect`, `frontendPagination`
	 * will get set and cause a re-render, in turn prompting the `Pagination useEffect` again, and this time it will call
	 * the api for the first time to get data.
	 *
	 * 2. User switches tabs/routes, thus updating the query parameter `list`
	 * i) `listType useEffect` runs, updating `equipmentBookings` to undefined again (because `equipmentBookings` should
	 * already hav a value set at this point).
	 * ii) `equipmentBookings useEffect` runs as a result, resetting the pagination.
	 * iii) `Pagination useEffect` runs as a result, just like the initial render, and causes an api call with 0 offset &
	 * appropriate BookingQueryType.
	 *
	 * 3. User presses the "View More" button at the bottom of either of the lists, updating the useState for `frontendPagination`
	 * i) `listType useEffect` does not run.
	 * ii) `equipmentBookings useEffect` does not run.
	 * iii) `Pagination useEffect` runs, prompting a new api call to grab data the new offset & concatenate it with existing data.
	 *
	 */

	/**
	 * `listType useEffect`
	 * `listType` could change from the user switching tabs/navigating.
	 * Also redirects to the `upcoming` tab/route if the query for `list` is no good.
	 *
	 */
	useEffect(() => {
		if (
			listType !== "upcoming" &&
			listType !== "history" &&
			listType !== "current"
		) {
			history.replace("/partner/calendar?list=upcoming")
		} else if (equipmentBookings !== undefined) {
			setEquipmentBookings(undefined);
		}
	}, [listType]);

	/**
	 * `equipmentBookings useEffect`
	 * `equipmentBookings` can change from the `listType useEffect`, or after successful api calls.
	 *
	 */
	useEffect(() => {
		if (equipmentBookings === undefined) {
			setFrontendPagination({
				...defaultFrontendPagination,
				key: frontendPagination?.key !== undefined ? frontendPagination?.key + 1 : 0,
			});
		}
	}, [equipmentBookings]);

	/**
	 * `Pagination useEffect`
	 * `frontendPagination` can be updated either from the `equipmentBookings useEffect` (resets it) or from the user
	 * clicking the "View More" button (increments offset by 1).
	 *
	 */
	useEffect(() => {
		if (frontendPagination) {
			getCalendarData().then().catch();
		}
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Call the api to get the appropriate list of Equipment Bookings.
	 * The `type` is determined by the query (controlled by navigation / the on-page tabs).
	 *
	 * When the response comes back, we run a check to see if `equipmentBookings` is undefined, which is the case for an
	 * initial render, or when the user has switched routes/queries, in which case just set the response to the state.
	 * Otherwise, if `equipmentBookings` exists, it must mean the api call is happening as a response to the pagination offset
	 * updating, so we can concatenate the new (offset) data set with the existing array.
	 *
	 */
	async function getCalendarData(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new EquipmentBookingsApi(getConfig(props.fullToken)).getEquipmentBookings({
				limit: 10,
				offset: frontendPagination.offset,
				type: bookingTypeMap[listType],
			});

			if (equipmentBookings === undefined) {
				setEquipmentBookings(res);
			} else {
				setEquipmentBookings({
					...res,
					equipmentBookings: equipmentBookings.equipmentBookings.concat(res.equipmentBookings),
				});
			}
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	/**
	 * When use clicks the "View More" button while `enableNext` is true in the `paginationInfo` part of the api response.
	 * Technically, we could also update the key field here, but there's no real reason - I've unofficially reserved using
	 * that when absolutely necessary to force re-renders, like when switching between tabs/routes on this page when
	 * the paginated offset hasn't changed.
	 *
	 */
	function onLoadMore(): void {
		setFrontendPagination({
			...frontendPagination,
			offset: frontendPagination.offset + 1,
		});
	}

	return (
		<Container className="authenticated-user-page">
			<h1>Calendar</h1>

			<PageTabSwitcher
				disabled={localLoading}
				tabs={[
					{
						label: "Upcoming",
						route: "/partner/calendar?list=upcoming",
						selected: listType === "upcoming",
					},
					{
						label: "Current",
						route: "/partner/calendar?list=current",
						selected: listType === "current",
					},
					{
						label: "History",
						route: "/partner/calendar?list=history",
						selected: listType === "history",
					},
				]}
			/>

			{(equipmentBookings === undefined && localLoading) && (
				<LocalLoader/>
			)}

			{equipmentBookings !== undefined && (
				<React.Fragment>
					{equipmentBookings?.equipmentBookings?.length > 0 ? (
						<React.Fragment>
							<PartnerUpcomingCalendarList equipmentBookings={equipmentBookings.equipmentBookings}/>

							{localLoading && (
								<LocalLoader/>
							)}

							<div className="calendar-single-button-controller">
								<OutsideButton2
									color={equipmentBookings?.paginationInfo?.enableNext ? "safetyOrange" : "offWhite2"}
									outline={!equipmentBookings?.paginationInfo?.enableNext}
									onClick={onLoadMore}
									disabled={localLoading || !equipmentBookings?.paginationInfo?.enableNext}
								>
									{equipmentBookings?.paginationInfo?.enableNext ? "View More" : "End of List"}
								</OutsideButton2>
							</div>
						</React.Fragment>
					) : (
						<p className="empty-message my-5">
							No Data.
						</p>
					)}
				</React.Fragment>
			)}
		</Container>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser as PartnerAdmin,
		...props,
	}
})(PartnerCalendarPage);
