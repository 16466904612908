import React, {ReactNode} from "react";
import {svgSizer} from "../utils/svgSizer";
import {FiInfo} from "react-icons/all";
import classNames from "classnames";

interface IProps {
    className?: string;
    color?: string;
    size?: number | string;
    icon?: ReactNode;
    onClick: () => void;
}

const IconButton:React.FC<IProps> = (props) => {
    const Icon: any = props.icon;

    return (
        <div className={classNames("icon-button-container", props.className)}>
            <div
                className={`icon-button-container-${props.color}_button`}
                onClick={props.onClick}
                style={svgSizer(props.size)}
            >
                <Icon className={`icon-button-container-${props.color}_button_icon`}/>
            </div>
        </div>
    );
}

IconButton.defaultProps = {
    size: 35,
    icon: FiInfo,
};

export default IconButton;