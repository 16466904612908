import React from "react";
import {FiX} from "react-icons/all";
import classNames from "classnames";

interface IProps {
	src: string;
	alt?: string;
	onClick?: () => void;
	className?: string;
}

const RemovableImage: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("removable-image", props.className)}>
			<img
				src={props.src}
				alt={props.alt}
			/>

			{props.onClick && (
				<div
					className="removable-image_close-container"
					onClick={props.onClick}
				>
					<FiX/>
				</div>
			)}
		</div>
	);
};

RemovableImage.defaultProps = {
	alt: "Preview",
};

export default RemovableImage;
