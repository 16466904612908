import React, {ReactNode} from "react";
import {removeError} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {APIError, User, UserType} from "client";
import OutsideActivitiesModal from "./OutsideActivitiesModal";
import OutsideActivitiesModalBody from "./OutsideActivitiesModalBody";
import OutsideActivitiesModalFooter from "./OutsideActivitiesModalFooter";
import OutsideActivitiesButton from "./OutsideActivitiesButton";
import OutsideButton2 from "./buttons/OutsideButton2";

/* Main Error OutsideActivitiesModal Manager */

interface IProps {
	errors?: Array<APIError>;
}

const ErrorModalManager: React.FC<IProps> = (props: IProps) => {

	const modals: Array<ReactNode> = props.errors.map((e: APIError, i: number) => {
		return (
			<ErrorModal
				key={`error-modal_${i}`}
				apiError={e}
				index={i}
			/>
		);
	});

	return (
		<React.Fragment>
			{modals}
		</React.Fragment>
	);
};

/* Individual Modals */

interface IErrorModalProps {
	dispatch?: any;
	currentUser?: User;
	apiError: APIError;
	index: number;
}

const _ErrorModal: React.FC<IErrorModalProps> = (props: IErrorModalProps) => {

	let displayColor: "forestGreen" | "safetyOrange";
	switch (props.currentUser.type) {
		case UserType.STANDARDUSER:
			displayColor = "forestGreen";
			break
		case UserType.PARTNERADMIN:
		case UserType.COMPANYADMIN:
		default:
			displayColor = "safetyOrange";
			break;
	}

	function dismiss(): void {
		props.dispatch(removeError(props.index));
	}

	function createErrors(_error: APIError, i: number): ReactNode {
		return (
			<li key={`error_${i}`}>
				{_error.message}
				{Array.isArray(_error.errors) && (
					<ul>
						{_error.errors.map(createErrors)}
					</ul>
				)}
			</li>
		);
	}

	return (
		<OutsideActivitiesModal isOpen={true}>
			<OutsideActivitiesModalBody className="my-3">
				<h2>Error</h2>
				<b>{props.apiError?.message}</b>
				{props.apiError?.errors?.length > 0 && (
					<ul>
						{props.apiError.errors.map(createErrors)}
					</ul>
				)}
			</OutsideActivitiesModalBody>

			<OutsideActivitiesModalFooter>
				<OutsideButton2
					color={displayColor}
					onClick={dismiss}
				>
					Dismiss
				</OutsideButton2>
			</OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
};

const ErrorModal = connect((store: IStore, props: IProps) => {
	return {
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(_ErrorModal);

export default connect((store: IStore, props: IProps) => {
	return {
		errors: store.metaStore.errors,
		...props,
	}
})(ErrorModalManager);
