import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children?: ReactNode;
	className?: string;
}

const FrameModalFooter: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("frame-modal_footer", props.className)}>
			{props.children}
		</div>
	);
};

export default FrameModalFooter;
