import React, {useState} from "react";
import {Equipment} from "client";
import PartnerCalendarEquipmentItemAvailabilityModal from "../../modals/PartnerCalendarEquipmentItemAvailabilityModal";

interface IProps {
	equipment: Equipment;
}

const PartnerInventoryAvailabilityCell: React.FC<IProps> = (props) => {

	const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

	/**
	 * Hide or show the modal displaying the item's availability.
	 *
	 */
	function toggleAvailabilityModal(): void {
		setShowAvailabilityModal(!showAvailabilityModal);
	}

	return (
		<React.Fragment>
			<PartnerCalendarEquipmentItemAvailabilityModal
				isOpen={showAvailabilityModal}
				onClose={toggleAvailabilityModal}
				equipment={props.equipment}
			/>

			<div className="frame-one-table-cell">
				<a
					href="#"
					onClick={toggleAvailabilityModal}
					className="text-hover-underline"
				>
					{`${props.equipment?.availability?.length} (View)`}
				</a>
			</div>
		</React.Fragment>
	);
};

export default PartnerInventoryAvailabilityCell;
