import React from "react";
import {IconType} from "react-icons/lib";

interface IProps {
	icon: IconType | string;
	header: string;
	subheader?: string;
	invert?: boolean;
}

const IconHeaderLabel: React.FC<IProps> = (props) => {

	const Icon = props.icon;

	return (
		<span className="icon-header-label">
			<div className="icon-header-label__icon">
				{typeof props.icon === "string" ? (
					<img src={Icon as string} alt="placeholder icon"/>
				) : (
					<Icon
						size={25}
						color="black"
					/>
				)}
			</div>

			{props.invert ? (
				<div className="icon-header-label__body">
					<span>{props.header}</span>
					{props.subheader ? <strong>{props.subheader}</strong> : null}
				</div>
			) : (
				<div className="icon-header-label__body">
					<strong>{props.header}</strong>
					{props.subheader ? <span>{props.subheader}</span> : null}
				</div>
			)}
		</span>
	);
}

IconHeaderLabel.defaultProps = {
	invert: false,
}

export default IconHeaderLabel;
