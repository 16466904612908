import React, {ReactNode} from "react";
import {Modal} from "reactstrap";

interface IProps {
	isOpen: boolean;
	children: ReactNode;
	toggle?: () => void;
	size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const FrameModal: React.FC<IProps> = (props) => {

	function toggleHelper(): void {
		if (props.toggle) {
			props.toggle();
		}
	}

	return (
		<Modal
			isOpen={props.isOpen}
			fade={true}
			centered={true}
			size={props.size}
			toggle={toggleHelper}
			contentClassName="frame-modal"
		>
			{props.children}
		</Modal>
	);
};

export default FrameModal;
