import {useState} from "react";

export function useWindowDimensions() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const [scrollX, setScrollX] = useState(window.pageXOffset);
	const [scrollY, setScrollY] = useState(window.pageYOffset);

	useState(() => {
		const listener = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		}

		const scrollListener = () => {
			setScrollX(window.scrollX);
			setScrollY(window.scrollY);
		}

		window.addEventListener('resize', listener);
		window.addEventListener('scroll', scrollListener);

		return () => {
			window.removeEventListener('resize', listener);
			window.removeEventListener('scroll', listener);
		}
	})

	return {
		width,
		height,
		scrollX,
		scrollY
	}
}
