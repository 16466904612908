import {connect} from "react-redux";
import React from "react";
import {IStore} from "../redux/defaultStore";
import {Redirect, Route, RouteProps} from "react-router";
import {addError} from "../redux/meta/MetaActions";
import {Token, UserType} from "client";

interface IProps {
	fullToken?: Token;
	dispatch?: any;
	access?: Array<UserType>;
}

const AuthenticatedRoute: React.FC<RouteProps & IProps> = (props: RouteProps & IProps) => {

	const {component, fullToken, ...rest} = props;
	const Component = component;

	if (!fullToken?.token) {
		props.dispatch(addError({message: "You must have a token to access that page. If you were previously logged in, your access token may have expired and you must log in again to get a new one."}));

		return (
			<Redirect to="/"/>
		);
	} else if (props.access?.length > 0 && props.access?.indexOf(props.fullToken?.type) < 0) {
		props.dispatch(addError({message: "You do not have the correct permissions to access that page"}));

		return (
			<Redirect to="/" />
		);
	}

	return (
		<Route
			{...rest}
			render={props => <Component {...props}/>}
		/>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(AuthenticatedRoute);
