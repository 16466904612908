import React, {useState} from "react";
import EditAnswerModal from "../../modals/EditAnswerModal";
import {MdEdit} from "react-icons/all";
import OutsideActivitiesButton from "../../OutsideActivitiesButton";
import OutsideButton2 from "../../buttons/OutsideButton2";
import {Answer} from "client";

interface IProps {
    answer: Answer;
    onDone: () => Promise<void>;
}

const ManageQuestionnaireOptionsCell: React.FC<IProps> = (props) => {

    const [viewEditModal, setViewEditModal] = useState<boolean>(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    function toggleEditModal(): void {
        setViewEditModal(!viewEditModal);
    }

    function onDoneEditModal() {
        setViewEditModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <EditAnswerModal
                answer={props.answer}
                isOpen={viewEditModal}
                onClose={toggleEditModal}
                onDone={onDoneEditModal}
            />

            <div className="frame-one-table-cell">
                <OutsideButton2
                    color="forestGreen"
                    narrow={true}
                    icon={MdEdit}
                    onClick={toggleEditModal}
                >
                    Change Answer
                </OutsideButton2>
            </div>
        </React.Fragment>
    );
}

export default ManageQuestionnaireOptionsCell;
