import React from "react";
import {StandardUser} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import NavBox from "../../components/NavBox";
import {Container} from "reactstrap";
import DisplayPersonalInfo from "../../components/account/DisplayPersonalInfo";
import BreadCrumbs from "../../components/BreadCrumbs";

interface IProps {
	dispatch?: any;
	currentUser?: StandardUser;
}

const PersonalInfoPage: React.FC<IProps> = (props) => {

	return (
		<Container className="personal-info-page">
			<div className="personal-info-page__container">
				<div className="personal-info-page__container__body-breadcrumbs__mobile" >
					<BreadCrumbs
						crumbs={[
							{
								label: "Account",
								route: "/user/account/personal-info",
							},
							{
								label: "Personal Information",
								route: "/user/account/personal-info",
							},
						]}
					/>
				</div>

				<div className="card-page-split-layout-container_aside">
					<div className="card-page-col-left">
						<div className="personal-info-page__container__navbox">
							<div className="personal-info-page__container__navbox-spacer"/>

							<div className="personal-info-page__container__navbox-position">
								<NavBox
									user={props?.currentUser}
								/>
							</div>
						</div>
					</div>

					<div className="card-page-col-right">
						<div className="personal-info-page__container__body">
							<div className="personal-info-page__container__body-container">
								<div className="personal-info-page__container__body-breadcrumbs">
									<BreadCrumbs
										crumbs={[
											{
												label: "Account",
												route: "/user/account/personal-info",
											},
											{
												label: "Personal Information",
												route: "/user/account/personal-info",
											},
										]}
									/>
								</div>

								<DisplayPersonalInfo/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(PersonalInfoPage);
