import React, {ReactNode} from "react";
import {PaginationInfo} from "client";
import {FiChevronLeft, FiChevronRight} from "react-icons/all";
import {isNil} from "lodash";
import OutsidePaginatorButton from "../buttons/OutsidePaginatorButton";

interface IProps extends PaginationInfo {
	onChangeOffset: (offset: number) => void;
}

const OutsidePaginatorButtons: React.FC<IProps> = (props) => {

	function changePageHelper(offset: number): () => void {
		return () => props.onChangeOffset(offset);
	}

	function renderPaginatorButton(offset: number, i: number): ReactNode {
		return (
			<OutsidePaginatorButton
				key={`table-paginator-offset_${offset}_${i}`}
				active={props.offset === offset}
				onClick={props.offset !== offset ? changePageHelper(offset) : undefined}
			>
				{offset + 1}
			</OutsidePaginatorButton>
		);
	}

	return (
		<div className="outside-paginator_buttons-container">
			<OutsidePaginatorButton
				active={false}
				onClick={props.enablePrevious ? changePageHelper(props.offset - 1) : undefined}
			>
				<FiChevronLeft/>
			</OutsidePaginatorButton>

			{props.availableOffsets?.filter((i) => !isNil(i)).map(renderPaginatorButton)}

			<OutsidePaginatorButton
				active={false}
				onClick={props.enableNext ? changePageHelper(props.offset + 1) : undefined}
			>
				<FiChevronRight/>
			</OutsidePaginatorButton>
		</div>
	);
};

export default OutsidePaginatorButtons;
