import React, {useState} from "react";
import {DateRange, Range, RangeKeyDict} from "react-date-range";
import moment from "moment";
import colors from "../../style/colors";
import {TimeInterval} from "client";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";

// Default date rate, set to start today and end 1 week from now
const defaultDateRange: Range = {
	startDate: moment(new Date()).startOf("day").toDate(),
	endDate: moment(new Date()).startOf("day").add(7, "days").toDate(),
	color: colors.safetyOrange,
};

interface IProps {
	isOpen: boolean;
	onDone: (interval: TimeInterval) => void;
	onClose: () => void;
}

const AddInventoryDateRangeModal: React.FC<IProps> = (props) => {

	const [range, setRange] = useState<Range>(defaultDateRange);

	/**
	 * Reset to the default range before closing.
	 *
	 */
	function resetAndClose(): void {
		setRange(defaultDateRange);
		props.onClose();
	}

	/**
	 * Grab the single range that we want from the map returned by the library.
	 *
	 * @param rangesByKey
	 */
	function onDateRangeChangeHelper(rangesByKey: RangeKeyDict): void {
		setRange(Object.values(rangesByKey)[0]);
	}

	/**
	 * Format the date range from the library for our convenience.
	 *
	 */
	function confirmDateRange(): void {
		setRange(defaultDateRange);
		props.onDone({
			start: moment(range.startDate).valueOf(),
			end: moment(range.endDate).valueOf(),
		});
	}

	return (
		<OutsideActivitiesModal
			isOpen={props.isOpen}
			toggle={resetAndClose}
		>
			<OutsideActivitiesModalBody onToggle={resetAndClose}>
				<div className="add-inventory-schedule-modal">
					<DateRange
						editableDateInputs={false}
						onChange={onDateRangeChangeHelper}
						moveRangeOnFirstSelection={false}
						ranges={[range]}
						minDate={moment().subtract(1, "year").toDate()}
					/>
				</div>
			</OutsideActivitiesModalBody>

			<OutsideActivitiesModalFooter>
				<OutsideButton2
					color="safetyOrange"
					narrow={true}
					onClick={confirmDateRange}
				>
					Save Interval
				</OutsideButton2>
			</OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
};

export default AddInventoryDateRangeModal;
