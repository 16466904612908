import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {PhoneNumberBody, StandardUser, Token, UpdateProfileBody, UpdateProfileRequest, User, UsersApi} from "client";
import {Container, Input, Label} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import OutsideButton2 from "../../components/buttons/OutsideButton2";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import OutsideCardFormButtons from "../../components/OutsideCardFormButtons";
import {FiChevronRight} from "react-icons/all";
import GooglePlacesInput from "../../components/inputs/GooglePlacesInput";
import moment from "moment";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {
	convertFrontendDateInputToMS,
	convertMStoFrontendDateInput,
	IFrontendDateInput
} from "../../utils/frontendDateofBirthFieldsHelpers";
import CountryCodeSelector from "../../components/inputs/CountryCodeSelector";
import {standardUserShouldCompleteFirstTimeForm} from "../../utils/standardUserShouldCompleteFirstTimeForm";

interface IUpdateProfileBody extends Omit<UpdateProfileBody, "dateOfBirth"> {
	dateOfBirth: IFrontendDateInput;
}

const defaultUpdateProfileRequestFirstTimeFrontend: IUpdateProfileBody = {
	email: "",
	phoneNumber: {
		countryCode: undefined,
		nationalNumber: "",
	},
	dateOfBirth: undefined,
	address: undefined,
	firstName: "",
	lastName: "",
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: StandardUser;
}

const UserFirstTimeForm: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [form, setForm] = useState<IUpdateProfileBody>(defaultUpdateProfileRequestFirstTimeFrontend);
	const [firstCheck, setFirstCheck] = useState(true);

	/**
	 * Check to make sure the user is supposed to be here before continuing.
	 * If not, send to "/" route & let logic there redirect based on user type.
	 *
	 */
	useEffect(() => {
		if (firstCheck && props.currentUser && standardUserShouldCompleteFirstTimeForm(props.currentUser)) {
			setDefaultFormValues();
			setFirstCheck(false);
		} else if (firstCheck) {
			history.replace("/");
		}
	}, [props.currentUser]);

	/**
	 * Inflate the form with any of the existing fields from the user, which would have come from the Company Admin
	 * creating the user.
	 *
	 */
	function setDefaultFormValues(): void {
		setForm({
			email: props.currentUser?.email || "",
			phoneNumber: {
				countryCode: props.currentUser?.phoneNumber?.countryCode || undefined,
				nationalNumber: props.currentUser?.phoneNumber?.nationalNumber || "",
			},
			dateOfBirth: props.currentUser?.dateOfBirth ? convertMStoFrontendDateInput(props.currentUser?.dateOfBirth) : {
				year: undefined,
				month: undefined,
				date: undefined,
			},
			address: props.currentUser?.address?.placeID || undefined,
			firstName: props.currentUser?.firstName || "",
			lastName: props.currentUser?.lastName || "",
		});
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof UpdateProfileBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param key
	 */
	function onCountryCodeChange(key: keyof PhoneNumberBody): (dialCodePart: string) => void {
		return (dialCodePart) => {
			setForm({
				...form,
				phoneNumber: {
					...form.phoneNumber,
					[key]: dialCodePart,
				},
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setForm({
				...form,
				phoneNumber: {
					...form.phoneNumber,
					[key]: values.value,
				},
			});
		}
	}

	/**
	 * Custom onChange for the places autocomplete because of how we return the data from that component.
	 *
	 * @param placeID
	 */
	function setPlaceID(placeID: string): void {
		setForm({
			...form,
			address: placeID,
		});
	}

	/**
	 * Handle each of the Date of Birth fields changing, and "fix" the values if they're too great
	 * (year greater than current year, month greater than 12, date greater than 21;
	 * no validation is done here to make sure the inputs are valid relative to each other - so entering February 30
	 * won't be automatically adjusted, but the api should throw an error when submitting as before we convert to a
	 * number, Moment will give us an invalid date).
	 *
	 * @param key
	 */
	function onDateOfBirthChange(key: keyof IFrontendDateInput): (values: NumberFormatValues) => void {
		return (values: NumberFormatValues) => {
			let val = values.value;

			if (key === "year" && values.floatValue > moment().year()) {
				val = moment().year().toString();
			}

			if (key === "month" && values.floatValue > 12) {
				val = "12";
			}

			if (key === "date" && values.floatValue > 31) {
				val = "31";
			}

			setForm({
				...form,
				dateOfBirth: {
					...form.dateOfBirth,
					[key]: val,
				},
			});
		}
	}

	/**
	 * Helper to adjust the input value when the month input is blurred,
	 * resetting to an empty string of the input is invalid, or pre-pending a 0 if the entry was single digit.
	 *
	 */
	function onMonthBlur(): void {
		let adjustedMonthValue: string = form.dateOfBirth?.month;

		if (form.dateOfBirth?.month === "0" || form.dateOfBirth?.month === "00" || form.dateOfBirth?.month?.length > 2) {
			adjustedMonthValue = "";
		} else if (form.dateOfBirth?.month?.length === 1) {
			adjustedMonthValue = "0" + form.dateOfBirth?.month;
		}

		setForm({
			...form,
			dateOfBirth: {
				...form.dateOfBirth,
				month: adjustedMonthValue,
			},
		});
	}

	/**
	 * Helper to adjust the input value when the date input is blurred,
	 * resetting to an empty string of the input is invalid, or pre-pending a 0 if the entry was single digit.
	 *
	 */
	function onDateBlur(): void {
		let adjustedDateValue: string = form.dateOfBirth?.date;

		if (form.dateOfBirth?.date === "0" || form.dateOfBirth?.date === "00" || form.dateOfBirth?.date?.length > 2) {
			adjustedDateValue = "";
		} else if (form.dateOfBirth?.date?.length === 1) {
			adjustedDateValue = "0" + form.dateOfBirth?.date;
		}

		setForm({
			...form,
			dateOfBirth: {
				...form.dateOfBirth,
				date: adjustedDateValue,
			},
		});
	}

	/**
	 * Call the api to submit the user's update profile information.
	 * When successful update redux with the response, and send to the Questionnaire Start page.
	 *
	 * @param e
	 */
	async function submitFirstTimeForm(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).firstTimeForm({
				updateProfileBody: {
					email: form.email || undefined,
					phoneNumber: (form?.phoneNumber?.countryCode || form?.phoneNumber?.nationalNumber) ? {
						countryCode: form?.phoneNumber?.countryCode,
						nationalNumber: form?.phoneNumber?.nationalNumber || undefined,
					} : undefined,
					dateOfBirth: convertFrontendDateInputToMS(form.dateOfBirth),
					address: form.address || undefined,
					firstName: form.firstName || undefined,
					lastName: form.lastName || undefined,
				},
			});

			props.dispatch(updateCurrentUser(res.user));
			history.push("/user/questionnaire/start");
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<Container className="authenticated-user-page user-first-time-form-page">
			<div className="user-first-time-form-page_header">
				<h1>First Time?</h1>
				<p className="user-first-time-form-page_header_sub-title">
					We noticed that it's your first time logging in. Before we begin, tell us a little more about
					yourself.
				</p>
			</div>hasAnsweredFirstTimeForm

			<form
				className="outside-card"
				onSubmit={submitFirstTimeForm}
			>
				<div className="outside-card-section">
					<h2 className="outside-card-header">
						Personal Details
					</h2>
				</div>

				<div className="outside-card-section">
					<div className="row g-4 mb-4">
						<div className="col col-12 col-md-6 col-xl-4">
							<Label>First Name</Label>
							<Input
								placeholder="Enter First Name..."
								value={form.firstName}
								onChange={dynamicOnChange("firstName")}
							/>
						</div>

						<div className="col col-12 col-md-6 col-xl-4">
							<Label>Last Name</Label>
							<Input
								placeholder="Enter Last Name..."
								value={form.lastName}
								onChange={dynamicOnChange("lastName")}
							/>
						</div>
					</div>

					<div className="row g-4 mb-4">
						<div className="col col-12 col-md-6 col-xl-4">
							<Label>Confirm Email</Label>
							<Input
								placeholder="Enter Email..."
								value={form.email}
								onChange={dynamicOnChange("email")}
							/>
						</div>

						<div className="col col-12 col-md-6 col-xl-4">
							<Label>Phone Number</Label>
							<div className="d-flex gap-2">
								<div className="user-first-time-form-page_country-code-input">
									<CountryCodeSelector
										value={form?.phoneNumber?.countryCode}
										valueKey="code"
										onChange={onCountryCodeChange("countryCode")}
									/>
								</div>

								<NumberFormat
									placeholder="(000) 000-0000"
									value={form.phoneNumber?.nationalNumber}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									onValueChange={numberFormatOnChange("nationalNumber")}
									className="w-100"
								/>
							</div>
						</div>
					</div>

					<div className="row g-4 mb-4">
						<div className="col col-12 col-md-6 col-xl-4">
							<Label>Address</Label>
							<GooglePlacesInput
								placeholder="Enter Address..."
								initialInputValue={props.currentUser?.address?.formattedAddress}
								setPlaceID={setPlaceID}
							/>
						</div>

						<div className="col col-12 col-md-6 col-xl-4">
							<Label>Date of Birth <span className="text-secondary">(YYYY/MM/DD)</span></Label>
							<div className="d-flex gap-2">
								<NumberFormat
									placeholder="YYYY"
									value={form?.dateOfBirth?.year}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									maxLength={4}
									allowLeadingZeros={false}
									onValueChange={onDateOfBirthChange("year")}
								/>
								<NumberFormat
									placeholder="MM"
									value={form.dateOfBirth?.month}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									maxLength={2}
									allowLeadingZeros={true}
									onValueChange={onDateOfBirthChange("month")}
									onBlur={onMonthBlur}
								/>
								<NumberFormat
									placeholder="DD"
									value={form.dateOfBirth?.date}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									maxLength={2}
									allowLeadingZeros={true}
									onValueChange={onDateOfBirthChange("date")}
									onBlur={onDateBlur}
								/>
							</div>
						</div>
					</div>
				</div>

				<OutsideCardFormButtons>
					<OutsideButton2
						type="submit"
						color="forestGreen"
						rightIcon={FiChevronRight}
						onClick={submitFirstTimeForm}
					>
						Next
					</OutsideButton2>
				</OutsideCardFormButtons>
			</form>
		</Container>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser as StandardUser,
		...props,
	}
})(UserFirstTimeForm);
