import React from "react";
import {CompanyAdmin, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import NavBox from "../../components/NavBox";
import {Container} from "reactstrap";
import BreadCrumbs from "../../components/BreadCrumbs";
import PasswordSettingsForm from "../../components/account/PasswordSettingsForm";
import PasswordSavedModal from "../../components/modals/PasswordSavedModal";


interface IProps {
    dispatch?: any;
    fullToken?: Token;
    currentUser?: CompanyAdmin;
}

const PasswordSettingsPage: React.FC<IProps> = (props) => {

    return (
        <Container className="password-settings-page">
            <div className="password-settings-page__container">
                <div className="password-settings-page__container__body-breadcrumbs__mobile">
                    <BreadCrumbs
                        crumbs={[
                            {
                                label: "Account",
                                route: "/user/account/personal-info",
                            },
                            {
                                label: "Password Settings",
                                route: "/user/account/password-settings",
                            },
                        ]}
                    />
                </div>

                <div className="card-page-split-layout-container_aside">
                    <div className="card-page-col-left">
                        <div className="password-settings-page__container__navbox">
                            <div className="password-settings-page__container__navbox-spacer"/>

                            <div className="password-settings-page__container__navbox-position">
                                <NavBox
                                    user={props?.currentUser}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card-page-col-right">
                        <div className="password-settings-page__container__body">
                            <div className="password-settings-page__container__body-container">
                                <div className="password-settings-page__container__body-breadcrumbs">
                                    <BreadCrumbs
                                        crumbs={[
                                            {
                                                label: "Account",
                                                route: "/user/account/personal-info",
                                            },
                                            {
                                                label: "Password Settings",
                                                route: "/user/account/password-settings",
                                            },
                                        ]}
                                    />
                                </div>

                                <PasswordSettingsForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        currentUser: store.metaStore.currentUser,
        ...props,
    }
})(PasswordSettingsPage);
