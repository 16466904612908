import {PaginationInfo} from "client";
import {FrontendPagination} from "../components/tables/FrameOnePaginator";

// Temporary value adding for the sake of having a default value for when using these utilities on the frontend.
// In the future this can be replaced or made dynamic - pagination from backend apis uses middleware and defaults it to 5 if not found,
// so for now defaulting to 5 should be fine for the few scenarios where we want some pure frontend pagination - Spencer, Feb 1 2022
const temporaryNumberOfAvailableOffsets: number = 5;

export function getPaginationInfo(paginationParameters: FrontendPagination, total: number = 0): PaginationInfo {
    const {limit, offset} = paginationParameters;

    // send empty pagination info if no results are found
    if (total === 0) {
        return {
            enablePrevious: false,
            enableNext: false,
            lowerRange: 0,
            upperRange: 0,
            total: 0,
            availableOffsets: Array(temporaryNumberOfAvailableOffsets).fill(undefined),
            limit,
            offset,
        }
    }

    // calculate pagination info
    let skip = offset * limit;
    if (skip >= total) {
       skip = total - 1;
    }

    // determine if the enable next button should be active
    const enableNext = limit < (total - skip);

    return {
        enablePrevious: offset !== 0,
        enableNext,
        lowerRange: skip + 1,
        upperRange: skip + limit > total ? total : skip + limit,
        total,
        availableOffsets: findAvailableOffsets(paginationParameters, total),
        limit,
        offset,
    }
}

function findAvailableOffsets(paginationParameters: FrontendPagination, total: number = 0): number[] {
    const {limit, offset} = paginationParameters;


    const indexOfOffset = Math.floor((temporaryNumberOfAvailableOffsets - 1) / 2);
    const maxOffset = total % limit === 0 ? (total / limit) - 1 : Math.floor(total / limit);
    const availableOffsets = [];

    let currentOffset = offset - indexOfOffset;
    for (let i = 0; i < temporaryNumberOfAvailableOffsets; i++) {
        if (currentOffset < 0 || currentOffset > maxOffset) {
            availableOffsets.push(undefined);
        } else {
            availableOffsets.push(currentOffset);
        }

        currentOffset = currentOffset + 1;
    }

    return availableOffsets;
}
