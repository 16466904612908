import React, {useEffect, useState} from "react";
import {Equipment, EquipmentApi, Token} from "client";
import {RouteProps} from "react-router";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Container} from "reactstrap";
import BreadCrumbs from "../../components/BreadCrumbs";
import { Link } from "react-router-dom";
import ViewInventoryItemThumbnail from "../../components/inventory/ViewInventoryItemThumbnail";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import ViewInventoryItemSchedule from "../../components/inventory/ViewInventoryItemSchedule";
import ViewInventoryItemGallery from "../../components/inventory/ViewInventoryItemGallery";
import ViewInventoryItemDetails from "../../components/inventory/ViewInventoryItemDetails";
import ViewInventoryItemAttributes from "../../components/inventory/ViewInventoryItemAttributes";
import OutsideButton2 from "../../components/buttons/OutsideButton2";

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
}

const PartnerViewInventoryItem: React.FC<IProps> = (props) => {

	const query = new URLSearchParams(props.location.search);
	const equipmentID: string = query.get("e");

	const [equipment, setEquipment] = useState<Equipment>(undefined);

	useEffect(() => {
		getExistingEquipment().then().catch();
	}, []);

	/**
	 * Call api on load to get the existing data for the equipment specified in the query.
	 *
	 */
	async function getExistingEquipment(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipment({
				id: equipmentID,
			});

			setEquipment(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<Container className="authenticated-user-page">
			<BreadCrumbs
				crumbs={[
					{
						label: "Inventory",
						route: "/partner/inventory",
					},
					{
						label: "View Item",
						route: `/partner/inventory/view?e=${equipmentID}`,
					},
				]}
			/>

			<div className="card-page-header-row">
				<h1 className="card-page-header-row_title">
					{equipment?.name}
				</h1>

				<Link to={`/partner/inventory/edit?e=${equipmentID}`}>
					<OutsideButton2 color="safetyOrange">
						Edit Item
					</OutsideButton2>
				</Link>
			</div>

			<div className="card-page-split-layout-container">
				<div className="card-page-col-left">
					<ViewInventoryItemDetails
						name={equipment?.name}
						description={equipment?.description}
						isConsumable={equipment?.isConsumable}
						quantity={equipment?.quantity}
						instructions={equipment?.instructions}
					/>

					<ViewInventoryItemAttributes attributes={equipment?.attributes}/>
				</div>

				<div className="card-page-col-right">
					<ViewInventoryItemThumbnail thumbnail={equipment?.thumbnail}/>
					<ViewInventoryItemSchedule availability={equipment?.availability}/>
					<ViewInventoryItemGallery images={equipment?.images}/>
				</div>
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(PartnerViewInventoryItem);
