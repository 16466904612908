import React, {ChangeEvent} from "react";
import AddEquipmentAdditionalImagesGallery from "../modals/modalComponents/AddEquipmentAdditionalImagesGallery";
import FileInputButton from "../inputs/FileInputButton";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import {FileWithSRC} from "../../utils/renderAssetsHelper";
import {Asset} from "client";
import OutsideButton2 from "../buttons/OutsideButton2";

interface IProps {
	images: Array<Asset | FileWithSRC>;
	onAdditionalImagesChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	onRemoveAdditionalImage: (index: number) => void;
}

const AddInventoryGallery: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card add-inventory-gallery">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Gallery Images
				</h2>
			</div>

			<div className="outside-card-section">
				{(props.images && props.images.length > 0) ? (
					<AddEquipmentAdditionalImagesGallery
						images={props.images}
						onClick={props.onRemoveAdditionalImage}
					/>
				) : (
					<p className="add-inventory-gallery_empty-message">
						No Additional Images.
					</p>
				)}

				<div className="add-inventory-gallery_file-input-container">
					<FileInputButton
						accept=".png,.jpeg,.gif"
						multiple={true}
						onChange={props.onAdditionalImagesChange}
					>
						<OutsideButton2
							color="safetyOrange"
							narrow={true}
							className="w-100"
						>
							Add Images
						</OutsideButton2>
					</FileInputButton>
				</div>
			</div>
		</div>
	);
};

export default AddInventoryGallery;
