"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVMChainToJSON = exports.EVMChainFromJSONTyped = exports.EVMChainFromJSON = exports.EVMChain = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var EVMChain;
(function (EVMChain) {
    EVMChain["ETHEREUMMAINNET"] = "ETHEREUM_MAINNET";
    EVMChain["RINKEBY"] = "RINKEBY";
    EVMChain["ROPSTEN"] = "ROPSTEN";
    EVMChain["POLYGON"] = "POLYGON";
    EVMChain["POLYGONMUMBAI"] = "POLYGON_MUMBAI";
    EVMChain["BINANCEMAINNET"] = "BINANCE_MAINNET";
    EVMChain["BINANCETESTNET"] = "BINANCE_TESTNET";
})(EVMChain = exports.EVMChain || (exports.EVMChain = {}));
function EVMChainFromJSON(json) {
    return EVMChainFromJSONTyped(json, false);
}
exports.EVMChainFromJSON = EVMChainFromJSON;
function EVMChainFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.EVMChainFromJSONTyped = EVMChainFromJSONTyped;
function EVMChainToJSON(value) {
    return value;
}
exports.EVMChainToJSON = EVMChainToJSON;
