"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfileBodyToJSON = exports.UpdateProfileBodyFromJSONTyped = exports.UpdateProfileBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateProfileBodyFromJSON(json) {
    return UpdateProfileBodyFromJSONTyped(json, false);
}
exports.UpdateProfileBodyFromJSON = UpdateProfileBodyFromJSON;
function UpdateProfileBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : _1.PhoneNumberBodyFromJSON(json['phoneNumber']),
        'image': !runtime_1.exists(json, 'image') ? undefined : json['image'],
        'dateOfBirth': !runtime_1.exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}
exports.UpdateProfileBodyFromJSONTyped = UpdateProfileBodyFromJSONTyped;
function UpdateProfileBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'phoneNumber': _1.PhoneNumberBodyToJSON(value.phoneNumber),
        'image': value.image,
        'dateOfBirth': value.dateOfBirth,
        'address': value.address,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
exports.UpdateProfileBodyToJSON = UpdateProfileBodyToJSON;
