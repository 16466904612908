import React from "react";
import {PaginationInfo} from "client";
import {FrontendPagination} from "./FrameOnePaginator";
import {omit} from "lodash";
import OutsidePaginatorLimiter from "./OutsidePaginatorLimiter";
import OutsidePaginatorButtons from "./OutsidePaginatorButtons";

interface IProps extends PaginationInfo {
	onPaginationChange: (newPagination: FrontendPagination) => void;
}

const OutsidePaginator: React.FC<IProps> = (props) => {

	function onChangeLimit(limit): void {
		props.onPaginationChange(omit({
			offset: 0,
			limit,
		}, "onPaginationChange"));
	}

	function onChangeOffset(offset: number): void {
		props.onPaginationChange(omit({
			limit: props.limit,
			offset,
		}, "onPaginationChange"));
	}

	return (
		<div className="outside-paginator">
			<OutsidePaginatorLimiter
				{...omit(props, "onPaginationChange")}
				onChangeLimit={onChangeLimit}
			/>

			<OutsidePaginatorButtons
				{...omit(props, "onPaginationChange")}
				onChangeOffset={onChangeOffset}
			/>
		</div>
	);
};

export default OutsidePaginator;
