import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import Login from "./pages/Login";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import SideBarManager from "./components/sidebar/SideBarManager";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetSuccessfulPage from "./pages/ResetSuccessfulPage";
import PersonalInfoPage from "./pages/account/PersonalInfoPage";
import PasswordSettingsPage from "./pages/account/PasswordSettingsPage";
import PartnerInventoryPage from "./pages/partner/PartnerInventoryPage";
import PartnerAddInventory from "./pages/partner/PartnerAddInventory";
import PartnerEditInventory from "./pages/partner/PartnerEditInventory";
import PartnerViewInventoryItem from "./pages/partner/PartnerViewInventoryItem";
import UserFirstTimeForm from "./pages/user/UserFirstTimeForm";
import UserQuestionnaireStart from "./pages/user/UserQuestionnaireStart";
import HistoryViewHistoryItem from "./pages/history/HistoryViewHistoryItem";
import UserHomepage from "./pages/user/UserHomepage";
import NavigationManager from "./components/navbar/NavigationManager";
import CompanyManageUsers from "./pages/CompanyManageUsers";
import CompanyHomePage from "./pages/CompanyHomePage";
import CompanyProfilePage from "./pages/CompanyProfilePage";
import QuestionnaireResultsPage from "./pages/QuestionnaireResultsPage";
import UserCalendarPage from "./pages/UserCalendarPage";
import {UserType} from "client";
import PartnerCalendarPage from "./pages/partner/PartnerCalendarPage";
import StandardUserFirstTimeManager from "./components/StandardUserFirstTimeManager";
import UserQuestionnaireQuestionPage from "./pages/user/UserQuestionnaireQuestionPage";
import UserConfirmBooking from "./pages/user/UserConfirmBooking";
import UserPreviewBooking from "./pages/user/UserPreviewBooking";

const App: React.FC = () => {

	return (
		<BrowserRouter>
			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>
			<StandardUserFirstTimeManager/>

			<SideBarManager>
				<NavigationManager/>

				<main>
					<Switch>
						{process.env.NODE_ENV === "development" && <Route exact path="/demo" component={ComponentDemoPage}/>}

						{/* General & Unauthenticated Routes */}
						<Route exact path="/" component={Login}/>
						<Route exact path="/forgot-password" component={ForgotPasswordPage}/>
						<Route exact path="/forgot-password/reset" component={ResetPasswordPage}/>
						<Route exact path="/forgot-password/success" component={ResetSuccessfulPage}/>
						{/*<Route exact path="/help" component={HelpPage}/>*/}

						{/* Standard User Pages */}
						<AuthenticatedRoute exact path="/user/first-time" component={UserFirstTimeForm} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/questionnaire/start" component={UserQuestionnaireStart} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/questionnaire/question" component={UserQuestionnaireQuestionPage} access={[UserType.STANDARDUSER]}/>

						<AuthenticatedRoute exact path="/user/home" component={UserHomepage} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/calendar" component={UserCalendarPage} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/bookings/preview" component={UserPreviewBooking} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/bookings/confirm" component={UserConfirmBooking} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/bookings/view" component={HistoryViewHistoryItem} access={[UserType.STANDARDUSER]}/>

						<AuthenticatedRoute exact path="/user/account/personal-info" component={PersonalInfoPage} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/account/password-settings" component={PasswordSettingsPage} access={[UserType.STANDARDUSER]}/>
						<AuthenticatedRoute exact path="/user/account/questionnaire-results" component={QuestionnaireResultsPage} access={[UserType.STANDARDUSER]}/>
						<Redirect from="/user" to="/user/home"/>

						{/* Company Admin Pages */}
						{/*<AuthenticatedRoute exact path="/company/home" component={CompanyHomePage} access={[UserType.COMPANYADMIN]}/>*/}
						<AuthenticatedRoute exact path="/company/users" component={CompanyManageUsers} access={[UserType.COMPANYADMIN]}/>
						{/*<AuthenticatedRoute exact path="/company/profile" component={CompanyProfilePage} access={[UserType.COMPANYADMIN]}/>*/}
						<Redirect from="/company" to="/company/users"/>
						{/* Partner Admin Pages */}
						{/*<AuthenticatedRoute exact path="/partner/home" component={PartnerHomePage} access={[UserType.PARTNERADMIN]}/>*/}
						<AuthenticatedRoute exact path="/partner/calendar" component={PartnerCalendarPage} access={[UserType.PARTNERADMIN]}/>
						<AuthenticatedRoute exact path="/partner/inventory" component={PartnerInventoryPage} access={[UserType.PARTNERADMIN]}/>
						<AuthenticatedRoute exact path="/partner/inventory/add" component={PartnerAddInventory} access={[UserType.PARTNERADMIN]}/>
						<AuthenticatedRoute exact path="/partner/inventory/edit" component={PartnerEditInventory} access={[UserType.PARTNERADMIN]}/>
						<AuthenticatedRoute exact path="/partner/inventory/view" component={PartnerViewInventoryItem} access={[UserType.PARTNERADMIN]}/>
						{/*<Redirect from="/partner" to="/partner/home"/>*/}
						<Redirect from="/partner" to="/partner/calendar"/>

						<Redirect to="/"/>
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

export default App;
