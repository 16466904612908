import React, {useEffect, useState} from "react";
import BundlePreviewItemComponent from "./BundlePreviewItemComponent";
import {
	Bundle, BundlesApi,
	Token,
} from "client";
import LocalLoader from "../LocalLoader";

interface IProps {
	initialRender: boolean;
	bundles: Array<Bundle>;
}

const HomepageRecommended: React.FC<IProps> = (props) => {

	if (props.initialRender) {
		return (
			<section className="homepage-recommended">
				<LocalLoader/>
			</section>
		);
	}

	return (
		<section className="homepage-recommended">
			<header>
				<h1>Recommended for you</h1>
			</header>

			{props.bundles.length > 0 ? (
				<BundlePreviewItemComponent
					bundles={props.bundles}
				/>
			) : (
				<p className="bundle-preview__empty-item">No bundles available.</p>
			)}

		</section>
	);
}

export default HomepageRecommended;
