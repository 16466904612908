import React from "react";
import {Spinner} from "reactstrap";

const LocalLoader: React.FC = () => {

	return (
		<div className="local-loading">
			<Spinner/>
		</div>
	);
};

export default LocalLoader;
