import React, {ReactNode} from "react";
import {AnswerOption} from "client";
import classNames from "classnames";
import {FiCheck} from "react-icons/all";

interface IProps {
	answers: Array<AnswerOption>;
	selectedAnswer: number;
	onSelectAnswer: (index: number) => void;
}

const QuestionAnswerSelection: React.FC<IProps> = (props) => {

	function renderAnswer(answerOption: AnswerOption, i: number): ReactNode {
		function onSelectHelper(): void {
			props.onSelectAnswer(i);
		}

		const isSelected: boolean = props.selectedAnswer === i;

		return (
			<div
				key={`answer_${i}`}
				onClick={onSelectHelper}
				className={classNames("questionnaire-answer-selection_option", {
					"questionnaire-answer-selection_option_selectable": !isSelected,
					"questionnaire-answer-selection_option_selected": isSelected,
				})}
			>
				<p className="questionnaire-answer-selection_option_text">
					{answerOption.answer}
					<FiCheck
						className={classNames({
							"questionnaire-answer-selection_option_text_visible": isSelected,
							"questionnaire-answer-selection_option_text_hidden": !isSelected,
						})}
					/>
				</p>
			</div>
		);
	}

	return (
		<div className="questionnaire-answer-selection">
			{props.answers.map(renderAnswer)}
		</div>
	);
};

QuestionAnswerSelection.defaultProps = {
	answers: [],
};

export default QuestionAnswerSelection;
