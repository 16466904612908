import {User} from "client";
import {capitalizeFirstLetter} from "./capitalizeFirstLetter";

export function generateUserFullName(user: User): string {
	if (!user) {
		return "User";
	}

	if (user.firstName && user.lastName) {
		return capitalizeFirstLetter(user.firstName) + " " + capitalizeFirstLetter(user.lastName);
	} else if (user.firstName) {
		return capitalizeFirstLetter(user.firstName);
	} else if (user.lastName) {
		return capitalizeFirstLetter(user.lastName);
	}
}
