import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import Sidebar from "react-sidebar";
import {toggleSideBar} from "../../redux/meta/MetaActions";
import {FiX} from "react-icons/all";
import SideBarInner from "./SideBarInner";

interface IProps {
    dispatch?: any;
    sidebarVisible?: boolean;
    children: ReactNode;
}

const SideBarManager: React.FC<IProps> = (props: IProps) => {

    /**
     * Dispatch redux action to toggle the open status of the side bar,
     * where it is tracked instead of locally
     *
     */
    function toggleSideBarHelper(e?): void {
        if (e) {
            e.preventDefault();
        }

        props.dispatch(toggleSideBar(!props.sidebarVisible));
    }

    return (
        <div>
            <Sidebar
                sidebar={(
                    <div className="sidebar">
                        <div className="sidebar_close-header-container">
                            <FiX
                                className="sidebar_close-header-container_x"
                                onClick={toggleSideBarHelper}
                            />

                            <h2 className="sidebar_close-header-container_title">
                                Menu
                            </h2>
                        </div>

                        <SideBarInner/>
                    </div>
                )}
                open={props.sidebarVisible}
                docked={false}
                onSetOpen={toggleSideBarHelper}
            >

                {props.children}
            </Sidebar>
        </div>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        sidebarVisible: store.metaStore.sidebarVisible,
        ...props,
    }
})(SideBarManager);
