import colors from "./colors.module.scss"

interface IColors {
    safetyOrange: string;
    safetyOrange0: string;
    safetyOrange100: string;
    safetyOrange200: string;
    safetyOrange300: string;
    safetyOrange400: string;
    safetyOrange500: string;
    safetyOrange600: string;
    safetyOrange800: string;
    safetyOrange900: string;

    forestGreen: string;
    forestGreen0: string;
    forestGreen100: string;
    forestGreen200: string;
    forestGreen300: string;
    forestGreen400: string;
    forestGreen500:  string;
    forestGreen600: string;
    forestGreen800: string;
    forestGreen900: string;

    grayscale0: string;
    grayscale100:  string;
    grayscale200:  string;
    grayscale300:  string;
    grayscale400:  string;
    grayscale500:  string;
    grayscale600:  string;
    grayscale700:  string;
    grayscale800:  string;
    grayscale900:  string;

    blueLight: string;
    blueDark: string;

    redLight: string;
    redDark: string;
}

export default colors as unknown as IColors;
