import React from "react";
import {Equipment} from "client";
import OutsideIconButton from "../../buttons/OutsideIconButton";
import {FiEdit2, FiEye} from "react-icons/all";
import { Link } from "react-router-dom";

interface IProps {
	equipment: Equipment
}

const PartnerInventoryActionsCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			<Link to={`/partner/inventory/view?e=${props.equipment?._id}`}>
				<OutsideIconButton
					icon={FiEye}
					inverted={true}
				/>
			</Link>

			<Link to={`/partner/inventory/edit?e=${props.equipment?._id}`}>
				<OutsideIconButton
					icon={FiEdit2}
					inverted={true}
				/>
			</Link>
		</div>
	);
};

export default PartnerInventoryActionsCell;

