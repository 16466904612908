"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePartnerAdminBodyToJSON = exports.CreatePartnerAdminBodyFromJSONTyped = exports.CreatePartnerAdminBodyFromJSON = void 0;
const _1 = require("./");
function CreatePartnerAdminBodyFromJSON(json) {
    return CreatePartnerAdminBodyFromJSONTyped(json, false);
}
exports.CreatePartnerAdminBodyFromJSON = CreatePartnerAdminBodyFromJSON;
function CreatePartnerAdminBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.CreateUserBodyFromJSONTyped(json, ignoreDiscriminator),
        'partnerID': json['partnerID'],
    };
}
exports.CreatePartnerAdminBodyFromJSONTyped = CreatePartnerAdminBodyFromJSONTyped;
function CreatePartnerAdminBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.CreateUserBodyToJSON(value),
        'partnerID': value.partnerID,
    };
}
exports.CreatePartnerAdminBodyToJSON = CreatePartnerAdminBodyToJSON;
