"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GodAdminToJSON = exports.GodAdminFromJSONTyped = exports.GodAdminFromJSON = void 0;
function GodAdminFromJSON(json) {
    return GodAdminFromJSONTyped(json, false);
}
exports.GodAdminFromJSON = GodAdminFromJSON;
function GodAdminFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GodAdminFromJSONTyped = GodAdminFromJSONTyped;
function GodAdminToJSON(value) {
    return value;
}
exports.GodAdminToJSON = GodAdminToJSON;
