import React from "react";
import {BundleItem} from "client";

interface IProps {
	item: BundleItem;
}

const PreviewBundleItemIndividual: React.FC<IProps> = (props) => {

	return (
		<div className="preview-bundle-item-individual">
			<div className="preview-bundle-item-individual_thumb-container">
				<img
					src={props.item?.equipmentOptions?.[0]?.thumbnail?.url || "/images/placeholder.png"}
					alt={`${props.item?.name}`}
				/>
			</div>

			<div className="preview-bundle-item-individual_content">
				<p className="preview-bundle-item-individual_content_quantity">
					{`Quantity: ${props.item?.quantity}`}
				</p>

				<h3>
					{props.item?.name}
				</h3>

				<p>
					{props.item?.description}
				</p>
			</div>
		</div>
	);
};

export default PreviewBundleItemIndividual;
