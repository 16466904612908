import React, {useState} from "react";
import {Equipment} from "client";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import moment from "moment";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import FrameOneButtonActions from "../FrameOneButtonActions";
import OutsideTableContainer from "../tables/OutsideTableContainer";
import {FrontendPagination} from "../tables/FrameOnePaginator";
import {getPaginationInfo} from "../../utils/paginationUtils";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	equipment: Equipment;
}

const PartnerCalendarEquipmentItemAvailabilityModal: React.FC<IProps> = (props) => {

	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>({offset: 0, limit: 30});

	return (
		<OutsideActivitiesModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<OutsideActivitiesModalBody onToggle={props.onClose}>
				<h4>
					{`Availabilities for ${props.equipment?.name}`}
				</h4>

				<div className="my-4">
					<OutsideTableContainer
						data={props.equipment?.availability}
						pagination={{
							...frontendPagination,
							...getPaginationInfo(frontendPagination, props.equipment?.availability?.length),
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "start",
								headerValue: "Start",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (d: number) => moment(d).format("MMMM D YYYY"),
							},
							{
								key: "end",
								headerValue: "End",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (d: number) => moment(d).format("MMMM D YYYY"),
							},
						]}
					/>
				</div>
			</OutsideActivitiesModalBody>

			<OutsideActivitiesModalFooter>
				<FrameOneButtonActions>
					<OutsideButton2
						color="safetyOrange"
						narrow={true}
						onClick={props.onClose}
					>
						Dismiss
					</OutsideButton2>
				</FrameOneButtonActions>
			</OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
};

export default PartnerCalendarEquipmentItemAvailabilityModal;
