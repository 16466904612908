/**
 * Remove duplicate strings from array.
 *
 * @param a
 */
export function filterDuplicateStrings(a: string[]): string[] {
	const seen: { [key: string]: boolean } = {};

	return a.filter((item: string) => {
		if (!seen[item]) {
			seen[item] = true;
			return true;
		}

		return false;
	});
}
