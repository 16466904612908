import React, {useState} from "react";
import {Equipment} from "client";
import PartnerEquipmentAttributesModal from "../../modals/PartnerEquipmentAttributesModal";

interface IProps {
	equipment: Equipment;
}

const PartnerInventoryAttributesCell: React.FC<IProps> = (props) => {

	const [showAttributesModal, setShowAttributesModal] = useState(false);

	/**
	 * Hide or show the modal displaying the item's attributes.
	 *
	 */
	function toggleAttributesModal(): void {
		setShowAttributesModal(!showAttributesModal);
	}

	return (
		<React.Fragment>
			<PartnerEquipmentAttributesModal
				isOpen={showAttributesModal}
				onClose={toggleAttributesModal}
				equipment={props.equipment}
			/>

			<div className="frame-one-table-cell">
				<a
					href="#"
					onClick={toggleAttributesModal}
					className="text-hover-underline"
				>
					{`${props.equipment?.availability?.length} (View)`}
				</a>
			</div>
		</React.Fragment>
	);
}

export default PartnerInventoryAttributesCell
