import React, {ChangeEvent, ReactNode} from "react";
import OutsideActivitiesButton from "./OutsideActivitiesButton";
import {Input, Label} from "reactstrap";
import OutsideButton2 from "./buttons/OutsideButton2";
import {FiPlus} from "react-icons/all";

interface IProps {
	attributes: Array<[string, string]>;
	handleAddAttribute: (tuple: [string, string]) => void;
	handleAttributeOnChange: (index: number, tuple: [string, string]) => void;
	handleRemoveAttribute: (index: number) => void;
}

const AttributesInput: React.FC<IProps> = (props) => {

	/**
	 * Append a new, empty attribute.
	 *
	 */
	function onAddAttribute(): void {
		props.handleAddAttribute(["", ""]);
	}

	/**
	 * Render each existing attribute, and attach functionality for editing both fields, and removing.
	 *
	 * @param attributePair
	 * @param index
	 */
	function renderAttribute(attributePair: [string, string], index: number): ReactNode {
		function onLabelChange(e: ChangeEvent<HTMLInputElement>): void {
			props.handleAttributeOnChange(index, [e.target.value, attributePair[1]])
		}

		function onValueChange(e: ChangeEvent<HTMLInputElement>): void {
			props.handleAttributeOnChange(index, [attributePair[0], e.target.value])
		}

		function onRemoveHelper(e): void {
			e.preventDefault();
			props.handleRemoveAttribute(index);
		}

		return (
			<div className="attributes-input_entry">
				<div className="attributes-input_entry_row">
					<div className="attributes-input_entry_row_col">
						<Input
							placeholder="Attribute Name..."
							value={attributePair[0]}
							onChange={onLabelChange}
						/>
					</div>

					<div className="attributes-input_entry_row_gap"/>

					<div className="attributes-input_entry_row_col">
						<Input
							placeholder="Attribute Value..."
							value={attributePair[1]}
							onChange={onValueChange}
						/>
					</div>
				</div>

				<div className="attributes-input_entry_remover">
					<a
						href="#"
						onClick={onRemoveHelper}
					>
						Remove
					</a>
				</div>
			</div>
		);
	}

	return (
		<div className="attributes-input">
			{(props.attributes && props.attributes.length > 0) ? (
				<React.Fragment>
					<div className="attributes-input_title-row">
						<div className="attributes-input_title-row_col">
							<Label>
								Attribute Name
							</Label>
						</div>

						<div className="attributes-input_title-row_gap"/>

						<div className="attributes-input_title-row_col">
							<Label>
								Value
							</Label>
						</div>
					</div>

					<div className="attributes-input_entries">
						{props.attributes.map(renderAttribute)}
					</div>
				</React.Fragment>
			) : (
				<div className="attributes-input_empty-message-container">
					<p>
						No Attributes.
					</p>
				</div>
			)}

			<div className="attributes-input_button-container">
				<OutsideButton2
					color="safetyOrange"
					narrow={true}
					leftIcon={FiPlus}
					onClick={onAddAttribute}
				>
					Add
				</OutsideButton2>
			</div>
		</div>
	);
};

export default AttributesInput;

