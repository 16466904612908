import React, {ReactNode} from "react";
import {svgSizer} from "../utils/svgSizer"
import {IoCloseOutline} from "react-icons/all";

interface IProps {
    color?: string;
    size?: number | string;
    icon?: ReactNode;
    onClick: () => void;
}

const IconButtonMini:React.FC<IProps> = (props) => {
    const Icon: any = props.icon;

    return (
        <div className="icon-button-mini-container">
            <div
                className={`icon-button-mini-container-${props.color}_button`}
                onClick={props.onClick}
                style={svgSizer(props.size)}
            >
                <Icon className={`icon-button-mini-container-${props.color}_button_icon`}/>
            </div>
        </div>
    );
}

IconButtonMini.defaultProps = {
    color: "redDark",
    size: 30,
    icon: IoCloseOutline,
};

export default IconButtonMini;