import React, {useState} from "react";
import {Equipment} from "client";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import moment from "moment";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import FrameOneButtonActions from "../FrameOneButtonActions";
import OutsideTableContainer from "../tables/OutsideTableContainer";
import {FrontendPagination} from "../tables/FrameOnePaginator";
import {getPaginationInfo} from "../../utils/paginationUtils";

interface IAttributesAsObject {
	key: string;
	value: string;
}

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	equipment: Equipment;
}

const PartnerEquipmentAttributesModal: React.FC<IProps> = (props) => {

	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>({offset: 0, limit: 30});

	return (
		<OutsideActivitiesModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<OutsideActivitiesModalBody onToggle={props.onClose}>
				<h4>
					{`Attributes for ${props.equipment?.name}`}
				</h4>

				<div className="my-4">
					<OutsideTableContainer
						data={Object.entries(props.equipment?.attributes).map((a): IAttributesAsObject => {
							return {
								key: a[0],
								value: a[1],
							}
						})}
						pagination={{
							...frontendPagination,
							...getPaginationInfo(frontendPagination, Object.entries(props.equipment?.attributes)?.length),
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "key",
								headerValue: "Attribute Name",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "value",
								headerValue: "Value",
								showSortIcons: false,
								sortable: false,
							},
						]}
					/>
				</div>
			</OutsideActivitiesModalBody>

			<OutsideActivitiesModalFooter>
				<FrameOneButtonActions>
					<OutsideButton2
						color="safetyOrange"
						narrow={true}
						onClick={props.onClose}
					>
						Dismiss
					</OutsideButton2>
				</FrameOneButtonActions>
			</OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
};

export default PartnerEquipmentAttributesModal;
