"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardUserToJSON = exports.StandardUserFromJSONTyped = exports.StandardUserFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StandardUserFromJSON(json) {
    return StandardUserFromJSONTyped(json, false);
}
exports.StandardUserFromJSON = StandardUserFromJSON;
function StandardUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.UserFromJSONTyped(json, ignoreDiscriminator),
        'company': _1.CompanyFromJSON(json['company']),
        'hasAnsweredFirstTimeForm': json['hasAnsweredFirstTimeForm'],
        'dateOfBirth': !runtime_1.exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'address': !runtime_1.exists(json, 'address') ? undefined : _1.AddressFromJSON(json['address']),
    };
}
exports.StandardUserFromJSONTyped = StandardUserFromJSONTyped;
function StandardUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.UserToJSON(value),
        'company': _1.CompanyToJSON(value.company),
        'hasAnsweredFirstTimeForm': value.hasAnsweredFirstTimeForm,
        'dateOfBirth': value.dateOfBirth,
        'address': _1.AddressToJSON(value.address),
    };
}
exports.StandardUserToJSON = StandardUserToJSON;
