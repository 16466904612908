import React from "react";
import {DatePickerProps} from "rsuite/esm/DatePicker/DatePicker";
import {DatePicker} from "rsuite";
import classNames from "classnames";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {User, UserType} from "client";
import {omit} from "lodash";

interface IOutsideDatePicker extends DatePickerProps {
	currentUser?: User;
}

const OutsideDatePicker: React.FC<IOutsideDatePicker> = (props) => {

	return (
		<DatePicker
			block={true}
			{...omit(props, "currentUser")}
			className={classNames("outside-date-picker", props.className, {
				"outside-date-picker_user": !props.currentUser || props.currentUser?.type === UserType.STANDARDUSER,
				"outside-date-picker_admin": props.currentUser?.type === UserType.COMPANYADMIN || props.currentUser?.type === UserType.PARTNERADMIN,
			})}
		/>
	)
};

export default connect((store: IStore, props: IOutsideDatePicker): IOutsideDatePicker => {
	return {
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(OutsideDatePicker);
