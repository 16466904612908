import React, {ReactElement} from "react";
import {Characteristic} from "client";
import IconHeaderLabel from "./labels/IconHeaderLabel";
import {FiCheckCircle} from "react-icons/all";

interface IProps {
	characteristics: Array<Characteristic>
	description: string;
}

const PreviewBundleCharacteristicsAndDescription: React.FC<IProps> = (props) => {


	function createImageLabel(item: Characteristic, index: number): ReactElement {
		return (
			<div
				key={`image-label-${index}`}
				className="preview-bundle-chars-desc_icon-container_item"
			>
				<IconHeaderLabel
					icon={FiCheckCircle}
					header={item.name}
					subheader={item.description}
					invert={true}
				/>
			</div>
		);
	}

	return (
		<div className="preview-bundle-chars-desc">
			{props.characteristics?.length > 0 && (
				<div className="preview-bundle-chars-desc_icon-container">
					{props.characteristics.map(createImageLabel)}
				</div>
			)}

			<div className="preview-bundle-chars-desc_body-container">
				<h4>What to know</h4>
				<p>{props.description}</p>
			</div>
		</div>
	);
};

export default PreviewBundleCharacteristicsAndDescription;
