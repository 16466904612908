import React, {useState} from "react";
import {FiTrash} from "react-icons/all";
import {User} from "client";
import DeleteUserModal from "../../modals/DeleteUserModal";
import IconButton from "../../IconButton";

interface IProps {
    user: User;
    onDone: () => Promise<void>;
}

const ManageUsersDeleteCell: React.FC<IProps> = (props) => {
    const [viewDeleteModal, setViewDeleteModal] = useState<boolean>(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    function toggleDeleteModal(): void {
        setViewDeleteModal(!viewDeleteModal);
    }

    function onDoneDeleteModal() {
        setViewDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <DeleteUserModal
                user={props.user}
                isOpen={viewDeleteModal}
                onClose={toggleDeleteModal}
                onDone={onDoneDeleteModal}
            />

            <div className="d-flex justify-content-center">
                <IconButton
                    color="redDark"
                    icon={FiTrash}
                    onClick={toggleDeleteModal}
                />
            </div>
        </React.Fragment>
    );
}

export default ManageUsersDeleteCell;
