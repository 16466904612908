import React from "react";
import {Equipment} from "client";

interface IProps {
	equipment: Equipment;
}

const PartnerInventoryItemNameCell: React.FC<IProps> = (props) => {

	return (
		<div className="partner-inventory-item-name-cell">
			<div className="partner-inventory-item-name-cell_thumbnail">
				<img
					src={props.equipment?.thumbnail?.url || "/images/placeholder.png"}
					alt="equipment thumbnail"
					className="partner-inventory-item-name-cell_thumbnail_image"
				/>
			</div>

			<span className="partner-inventory-item-name-cell_name">
				{props.equipment?.name}
			</span>
		</div>
	);
};

export default PartnerInventoryItemNameCell;
