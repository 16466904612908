"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginBodyToJSON = exports.LoginBodyFromJSONTyped = exports.LoginBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LoginBodyFromJSON(json) {
    return LoginBodyFromJSONTyped(json, false);
}
exports.LoginBodyFromJSON = LoginBodyFromJSON;
function LoginBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'phoneNumberBody': !runtime_1.exists(json, 'phoneNumberBody') ? undefined : _1.PhoneNumberBodyFromJSON(json['phoneNumberBody']),
        'password': json['password'],
    };
}
exports.LoginBodyFromJSONTyped = LoginBodyFromJSONTyped;
function LoginBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'phoneNumberBody': _1.PhoneNumberBodyToJSON(value.phoneNumberBody),
        'password': value.password,
    };
}
exports.LoginBodyToJSON = LoginBodyToJSON;
