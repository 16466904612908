import React, {ChangeEvent} from "react";
import {FileWithSRC} from "../../utils/renderAssetsHelper";
import FileInputButton from "../inputs/FileInputButton";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import {isAsset} from "../../utils/typeGuards";
import {Asset} from "client";
import OutsideButton2 from "../buttons/OutsideButton2";

interface IProps {
	thumbnail: Asset | FileWithSRC;
	onThumbnailChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const AddInventoryPicture: React.FC<IProps> = (props) => {

	/**
	 * Submit undefined to the onChange to remove the image from the form.
	 *
	 */
	function onRemoveHelper(): void {
		props.onThumbnailChange(undefined).then().catch();
	}

	return (
		<div className="outside-card add-inventory-picture">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Picture
				</h2>
			</div>

			<div className="outside-card-section">
				{props.thumbnail ? (
					<React.Fragment>
						<div className="add-inventory-picture_preview-row">
							<div className="add-inventory-picture_preview-row_col-left">
								<div className="add-inventory-picture_preview-row_col-left_image-container">
									{isFileWithSRC(props.thumbnail) && (
										<img
											src={props.thumbnail.imageSRC as string}
											alt="inventory item"
										/>
									)}

									{isAsset(props.thumbnail) && (
										<img
											src={props.thumbnail.url}
											alt="inventory item"
										/>
									)}
								</div>
							</div>

							<div className="add-inventory-picture_preview-row_col-right">
								<p>
									Upload a photo of the stock item for reference.
								</p>
							</div>
						</div>

						<div className="add-inventory-picture_editing-buttons">
							<div className="add-inventory-picture_editing-buttons_inner">
								<FileInputButton
									accept=".png,.jpeg"
									onChange={props.onThumbnailChange}
									className="add-inventory-picture_editing-buttons_inner_col"
								>
									<OutsideButton2
										color="offWhite2"
										narrow={true}
									>
										New Photo
									</OutsideButton2>
								</FileInputButton>

								<div className="add-inventory-picture_editing-buttons_inner_col">
									<OutsideButton2
										color="outline-red"
										narrow={true}
										onClick={onRemoveHelper}
									>
										Remove
									</OutsideButton2>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<div className="add-inventory-picture_empty">
						<p>
							Upload a photo of the stock item for reference.
						</p>

						<div className="add-inventory-picture_empty_button-container">
							<div className="add-inventory-picture_empty_button-container_inner">
								<FileInputButton
									accept=".png,.jpeg"
									onChange={props.onThumbnailChange}
								>
									<OutsideButton2
										color="safetyOrange"
										narrow={true}
									>
										Add Photo
									</OutsideButton2>
								</FileInputButton>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddInventoryPicture;
