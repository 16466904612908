import React, {ChangeEvent, ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
	Asset,
	AssetsApi,
	Equipment,
	EquipmentApi,
	TimeInterval,
	Token,
	UpdateEquipmentBody
} from "client";
import {RouteProps} from "react-router";
import {IStore} from "../../redux/defaultStore";
import {Container} from "reactstrap";
import BreadCrumbs from "../../components/BreadCrumbs";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import AddInventoryPicture from "../../components/inventory/AddInventoryPicture";
import AddInventorySchedule from "../../components/inventory/AddInventorySchedule";
import AddInventoryGallery from "../../components/inventory/AddInventoryGallery";
import {NumberFormatValues} from "react-number-format";
import {cloneDeep} from "lodash";
import AddInventoryDetails from "../../components/inventory/AddInventoryDetails";
import AddInventoryAttributes from "../../components/inventory/AddInventoryAttributes";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import {isAsset} from "../../utils/typeGuards";
import {useHistory} from "react-router-dom";
import {filterAttributeTuples} from "../../utils/filterAttributeTuples";
import OutsideButton2 from "../../components/buttons/OutsideButton2";

export interface IUpdateEquipmentBodyFrontend extends Omit<UpdateEquipmentBody, "attributes" | "thumbnailID" | "imageIDs"> {
	attributes: Array<[string, string]>;
	thumbnail: Asset | FileWithSRC;
	images: Array<Asset | FileWithSRC>;
}

const defaultEditEquipmentForm: IUpdateEquipmentBodyFrontend = {
	isConsumable: false,
	quantity: undefined,
	availability: [],
	name: "",
	description: "",
	instructions: "",
	placeID: undefined,
	attributes: [["", ""]],
	thumbnail: undefined,
	images: [],
}

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
}

const PartnerEditInventory: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const equipmentID: string = query.get("e");

	const [existingEquipment, setExistingEquipment] = useState<Equipment>(undefined); // A copy of the existing equipment for reference
	const [editForm, setEditForm] = useState<IUpdateEquipmentBodyFrontend>(defaultEditEquipmentForm); // Form for editing

	useEffect(() => {
		getExistingEquipment().then().catch();
	}, [equipmentID]);

	/**
	 * Call api on load to get the full data for the equipment we'll be editing. Save a version with setExistingEquipment
	 * that we won't be touching and only use as reference, as well as construct the form object we will be editing.
	 *
	 */
	async function getExistingEquipment(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipment({
				id: equipmentID,
			});

			setExistingEquipment(res);
			setEditForm({
				isConsumable: res.isConsumable,
				quantity: res.quantity,
				availability: res.availability,
				name: res.name,
				description: res.description,
				instructions: res.instructions,
				thumbnail: res.thumbnail,
				images: res.images,
				attributes: Object.entries(res.attributes)
			});
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof IUpdateEquipmentBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setEditForm({
				...editForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange for the consumable radio buttons.
	 *
	 * @param _consumable
	 */
	function consumableOnChange(_consumable: boolean): void {
		setEditForm({
			...editForm,
			isConsumable: _consumable,
		});
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data for the quantity.
	 *
	 * @param values
	 */
	function numberFormatOnChange(values: NumberFormatValues): void {
		setEditForm({
			...editForm,
			quantity: values.floatValue,
		});
	}

	/**
	 * Custom onChange for the thumbnail input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onThumbnailChange(e: ChangeEvent<HTMLInputElement> = undefined): Promise<void> {
		setEditForm({
			...editForm,
			thumbnail: e ? (await addURLsToFiles(e?.target.files))[0] : undefined,
		});
	}

	/**
	 * Handle adding the new time interval.
	 *
	 * @param interval
	 */
	function onAddAvailability(interval: TimeInterval): void {
		const availabilityCopy: Array<TimeInterval> = cloneDeep(editForm.availability);
		availabilityCopy.push(interval);

		setEditForm({
			...editForm,
			availability: availabilityCopy,
		});
	}

	/**
	 * Handle removing the specified time interval.
	 *
	 * @param index
	 */
	function onRemoveAvailability(index: number): void {
		const availabilityCopy: Array<TimeInterval> = cloneDeep(editForm.availability);
		availabilityCopy.splice(index, 1);

		setEditForm({
			...editForm,
			availability: availabilityCopy,
		});
	}

	/**
	 * Custom onChange for the additional images input, to add usable URL.
	 *
	 * @param e
	 */
	async function onAdditionalImagesChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newImages = await addURLsToFiles(e?.target.files);

		setEditForm({
			...editForm,
			images: editForm.images.concat(newImages),
		});
	}

	/**
	 * Splice the selected image from the array and update state.
	 *
	 * @param index
	 */
	function onRemoveAdditionalImage(index: number): void {
		const imagesCopy: Array<Asset | FileWithSRC> = cloneDeep(editForm?.images);
		imagesCopy.splice(index, 1);

		setEditForm({
			...editForm,
			images: imagesCopy,
		});
	}

	/**
	 * Add the new attribute & value to the list.
	 *
	 * @param tuple
	 */
	function handleAddAttribute(tuple: [string, string]): void {
		const attributesCopy: Array<[string, string]> = cloneDeep(editForm?.attributes);
		attributesCopy.push(tuple);

		setEditForm({
			...editForm,
			attributes: attributesCopy,
		});
	}

	/**
	 * Update the specific attribute tuple.
	 *
	 * @param index
	 * @param tuple
	 */
	function handleAttributeOnChange(index: number, tuple: [string, string]): void {
		const attributesCopy: Array<[string, string]> = cloneDeep(editForm?.attributes);
		attributesCopy[index] = tuple;

		setEditForm({
			...editForm,
			attributes: attributesCopy,
		});
	}

	/**
	 * Remove the specified attribute.
	 *
	 * @param index
	 */
	function handleRemoveAttribute(index: number): void {
		const attributesCopy: Array<[string, string]> = cloneDeep(editForm?.attributes);
		attributesCopy.splice(index, 1);

		setEditForm({
			...editForm,
			attributes: attributesCopy,
		});
	}

	/**
	 * Submit the new form values to the api to update the equipment.
	 * Handles checking existing assets against new file uploads for both thumbnail & gallery images.
	 * Navigate to the view page afterwards.
	 *
	 * @param e
	 */
	async function submitUpdatedEquipment(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			// Upload the thumbnail image, checking to make sure it's a new file upload with typeguard
			let thumbnail: Asset;
			if (editForm.thumbnail && isFileWithSRC(editForm.thumbnail)) {
				thumbnail = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: editForm.thumbnail,
				});
			} else if (isAsset(editForm.thumbnail)) {
				thumbnail = editForm.thumbnail;
			}

			// Upload the additional gallery images. First filter the existing assets and concat the newly uploaded assets
			let galleryAssets: Array<Asset> = [];
			if (editForm.images.length > 0) {
				galleryAssets = editForm.images.filter(isAsset)
					.concat(await Promise.all(editForm.images.filter(isFileWithSRC).map(async (asset): Promise<Asset> => {
						return await new AssetsApi(getConfig(props.fullToken)).createAsset({
							asset,
						});
					})));
			}

			const equipment = await new EquipmentApi(getConfig(props.fullToken)).updateEquipment({
				id: equipmentID,
				updateEquipmentBody: {
					isConsumable: editForm.isConsumable,
					quantity: editForm.quantity || undefined,
					availability: editForm.availability,
					name: editForm.name || undefined,
					description: editForm.description || undefined,
					instructions: editForm.instructions || undefined,
					placeID: editForm.placeID,
					attributes: Object.fromEntries(filterAttributeTuples(editForm.attributes)),
					thumbnailID: thumbnail?._id,
					imageIDs: galleryAssets.map(a => a._id),
				},
			});

			history.push(`/partner/inventory/view?e=${equipment?._id}`);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	const saveButton: ReactNode = (
		<OutsideButton2
			color="safetyOrange"
			onClick={submitUpdatedEquipment}
		>
			Update Item
		</OutsideButton2>
	);

	return (
		<Container className="authenticated-user-page">
			<BreadCrumbs
				crumbs={[
					{
						label: "Inventory",
						route: "/partner/inventory",
					},
					{
						label: "Edit Item",
						route: `/partner/inventory/edit?e=${equipmentID}`,
					},
				]}
			/>

			<div className="card-page-header-row-responsive">
				<h1 className="card-page-header-row_title">
					Edit Inventory Item
				</h1>

				{saveButton}
			</div>

			<div className="card-page-split-layout-container">
				<div className="card-page-col-left">
					<AddInventoryDetails
						name={editForm.name}
						description={editForm.description}
						isConsumable={editForm.isConsumable}
						quantity={editForm.quantity}
						instructions={editForm.instructions}
						dynamicOnChange={dynamicOnChange}
						consumableOnChange={consumableOnChange}
						numberFormatOnChange={numberFormatOnChange}
					/>

					<AddInventoryAttributes
						attributes={editForm.attributes}
						handleAddAttribute={handleAddAttribute}
						handleAttributeOnChange={handleAttributeOnChange}
						handleRemoveAttribute={handleRemoveAttribute}
					/>
				</div>

				<div className="card-page-col-right">
					<AddInventoryPicture
						thumbnail={editForm.thumbnail}
						onThumbnailChange={onThumbnailChange}
					/>

					<AddInventorySchedule
						availability={editForm.availability}
						onAddAvailability={onAddAvailability}
						onRemoveAvailability={onRemoveAvailability}
					/>

					<AddInventoryGallery
						images={editForm.images}
						onAdditionalImagesChange={onAdditionalImagesChange}
						onRemoveAdditionalImage={onRemoveAdditionalImage}
					/>
				</div>
			</div>

			<div className="card-page-mobile-button-actions">
				{saveButton}
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(PartnerEditInventory);
