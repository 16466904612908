import React from "react";
import {Button, ButtonProps} from "reactstrap";
import {omit} from "lodash";
import classNames from "classnames";

interface IProps extends Omit<ButtonProps, "color"> {
	// Manually set allowed colors for specific styling; update .outside-button-2 css file, and add to the classNames
	// object on the Button in this component if adding a new color.
	color: "safetyOrange" | "offWhite2" | "outline-red" | "forestGreen",
	leftIcon?: React.FC;
	rightIcon?: React.FC;
	narrow?: boolean; // Makes the button shorter
}

const OutsideButton2: React.FC<IProps> = (props) => {

	const LeftIcon: any = props.leftIcon;
	const RightIcon: any = props.rightIcon;

	return (
		<Button
			{...omit(props, "children", "leftIcon", "rightIcon")}
			className={classNames("outside-button-2", props.className, {
				"outside-button-2_narrow": props.narrow,
				"outside-button-2_orange": props.color === "safetyOrange",
				"outside-button-2_white": props.color === "offWhite2",
				"outside-button-2_outline-red": props.color === "outline-red",
				"outside-button-2_forestGreen": props.color === "forestGreen",
			})}
		>
			{props.leftIcon && (<LeftIcon className="outside-button-2_left-icon"/>)}

			{props.children && (
				<span>{props.children}</span>
			)}

			{props.rightIcon && (<RightIcon className="outside-button-2_right-icon"/>)}
		</Button>
	);
};

OutsideButton2.defaultProps = {
	narrow: false,
};

export default OutsideButton2;
