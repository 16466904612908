"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerSummaryToJSON = exports.PartnerSummaryFromJSONTyped = exports.PartnerSummaryFromJSON = void 0;
function PartnerSummaryFromJSON(json) {
    return PartnerSummaryFromJSONTyped(json, false);
}
exports.PartnerSummaryFromJSON = PartnerSummaryFromJSON;
function PartnerSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'rented': json['rented'],
        'available': json['available'],
        'scheduled': json['scheduled'],
    };
}
exports.PartnerSummaryFromJSONTyped = PartnerSummaryFromJSONTyped;
function PartnerSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'rented': value.rented,
        'available': value.available,
        'scheduled': value.scheduled,
    };
}
exports.PartnerSummaryToJSON = PartnerSummaryToJSON;
