import React from "react";
import {Asset} from "client";

interface IProps {
	thumbnail: Asset;
}

const ViewInventoryItemThumbnail: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card view-inventory-item-thumbnail">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Picture
				</h2>
			</div>

			<div className="outside-card-section">
				<div className="view-inventory-item-thumbnail_positioner">
					<div className="view-inventory-item-thumbnail_positioner_sizer">
						<img
							src={props.thumbnail ? props.thumbnail.url : "/images/placeholder.png"}
							alt="inventory thumbnail"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewInventoryItemThumbnail;
