import React, {ChangeEventHandler, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import {Input} from "reactstrap";
import OutsideActivitiesButton from "../components/OutsideActivitiesButton";
import {BsArrowLeftShort} from "react-icons/all";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {RequestPasswordResetBody, VerificationsApi} from "client";
import getConfig from "../utils/getConfig";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";

const defaultRequestPasswordResetBody: RequestPasswordResetBody = {
    email: "",
};

interface IProps {
    dispatch?: any;
}

const ForgotPasswordPage: React.FC<IProps> = (props) => {

    const history = useHistory();
    const [form, setForm] = useState<RequestPasswordResetBody>(defaultRequestPasswordResetBody);

    function changeHandler(key: keyof RequestPasswordResetBody): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setForm({
                ...form,
                [key]: e.target.value,
            });
        }
    }

    async function sendPasswordResetCode(e?): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {
            const res = await new VerificationsApi(getConfig()).requestPasswordReset({
                requestPasswordResetBody: {
                    email: form.email || undefined,
                },
            });

            history.push(`/forgot-password/reset?reset-id=${res.passwordResetRequestID}`);
        } catch (err) {
            props.dispatch(addError(err));
        }

        props.dispatch(decrementLoading());
    }

    return (
        <div className="forgot-password-page">
            <div className="forgot-password-page_container">

                <div className="forgot-password-page_container_back-button">
                    <BsArrowLeftShort size={24}/>

                    <Link to={"/"}>
                        Go back
                    </Link>
                </div>

                <h1>Forgot password?</h1>

                <p>
                    First, enter your email address below. If an email address exists, we will send you an email with a code that you can use to reset your password.
                </p>

                <div className="forgot-password-page_container_form">
                    <form onSubmit={sendPasswordResetCode}>
                        <Input
                            type="email"
                            name="forgot-email-input"
                            placeholder="Email address"
                            onChange={changeHandler("email")}
                            value={form.email}
                        />

                        <div className="forgot-password-page_container_form_button">
                            <OutsideActivitiesButton
                                type="submit"
                                color="forestGreen"
                                onClick={sendPasswordResetCode}
                            >
                                Send code
                            </OutsideActivitiesButton>
                        </div>
                    </form>
                </div>

                <p className="forgot-password-page_container_login-link">
                    Back to <Link to="/">Login page</Link>
                </p>
            </div>
        </div>
    );
}

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(ForgotPasswordPage);