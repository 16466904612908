import React, {ReactNode} from "react";
import {TimeInterval} from "client";
import FullTag from "../FullTag";
import moment from "moment";

interface IProps {
	availability: Array<TimeInterval>;
}

const ViewInventoryItemSchedule: React.FC<IProps> = (props) => {

	/**
	 * Render each interval & attach the onClick / remove functionality.
	 *
	 * @param _interval
	 * @param i
	 */
	function renderTimeInterval(_interval: TimeInterval, i: number): ReactNode {
		return (
			<FullTag>
				{`${moment(_interval.start).format("MM/DD/YY")} → ${moment(_interval.end).format("MM/DD/YY")}`}
			</FullTag>
		);
	}

	return (
		<div className="outside-card view-inventory-item-schedule">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Scheduling
				</h2>
			</div>

			<div className="outside-card-section">
				{(props.availability && props.availability.length > 0) ? (
					<div className="view-inventory-item-schedule_tags">
						{props.availability.map(renderTimeInterval)}
					</div>
				) : (
					<div className="view-inventory-item-schedule_tags_empty-message-container">
						<p>
							No Availabilities
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ViewInventoryItemSchedule;
