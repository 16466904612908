import React, {ChangeEvent} from "react";
import classNames from "classnames";
import {FileWithSRC} from "../../utils/renderAssetsHelper";
import {Asset} from "client";
import FileInputButton from "./FileInputButton";
import OutsideButton2 from "../buttons/OutsideButton2";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import {isAsset} from "../../utils/typeGuards";
import {BsCardImage} from "react-icons/all";

interface IProps {
	image: Asset | FileWithSRC;
	onImageChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	edit?: boolean;
}

const ProfileImagePicker: React.FC<IProps> = (props: IProps) => {

	/**
	 * Sets the image to undefined and then calls the API
	 *
	 */
	function onRemoveFile(): void {
		props.onImageChange(undefined).then().catch();
	}

	return (
		<div className="profile-image-picker">

			<div className="profile-image-picker_preview-container">
				{isFileWithSRC(props.image) && (
					<img
						src={props.image?.imageSRC as string}
						alt="profile image"

					/>
				)}

				{isAsset(props.image) && (
					<img
						src={props.image?.url}
						alt="profile image"
					/>
				)}

				{props.image === undefined && (
					<BsCardImage
						className="profile-image-picker_preview-container_placeholder"
						color="white"
					/>
				)}
			</div>


			<div
				className={classNames("profile-image-picker_button-container", {
					"d-none": !props.edit,
				})}
			>
				<FileInputButton
					color="forestGreen"
					accept=".png,.jpeg"
					className="profile-image-picker-button"
					onChange={props.onImageChange}
				>
					<OutsideButton2
						color="forestGreen"
						narrow={true}
					>
						Change
					</OutsideButton2>
				</FileInputButton>

				<OutsideButton2
					color="offWhite2"
					outline={true}
					narrow={true}
					className="profile-image-picker-button"
					onClick={onRemoveFile}
				>
					Remove
				</OutsideButton2>
			</div>
		</div>
	);
}

ProfileImagePicker.defaultProps = {
	edit: true,
}

export default ProfileImagePicker;
