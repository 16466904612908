import React from "react";
import {valueOrBust} from "../../utils/valueOrBust";
import {parseBooleanToYesNo} from "../../utils/parseBooleanToYesNo";
import moment from "moment";
import {AiOutlineClockCircle} from "react-icons/all";
import IconHeaderLabel from "../labels/IconHeaderLabel";

interface IProps {
    startDate: string;
    endDate: string;
}

const ViewHistoryItemBookingDetails: React.FC<IProps> = (props) => {

    // TODO use generic component for image label rendering
    return (
        <div className="outside-card history-view-item-booking-details">
            <div className="outside-card-section">
                <h2 className="outside-card-header">
                    Booking date
                </h2>
            </div>

            <div className="outside-card-section history-view-item-booking-details__body-container">
                <IconHeaderLabel
                    icon={AiOutlineClockCircle}
                    header="Starting date"
                    subheader={valueOrBust(moment(props.startDate).format("LL"))}
                />

                <IconHeaderLabel
                    icon={AiOutlineClockCircle}
                    header="Ending date"
                    subheader={valueOrBust(moment(props.endDate).format("LL"))}
                />
            </div>
        </div>
    );
};

export default ViewHistoryItemBookingDetails;
