import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {RouteProps} from "react-router";
import {BookingsApi, Bundle, BundlesApi, CheckBundleAvailabilityResponse, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Container} from "reactstrap";
import BreadCrumbs from "../../components/BreadCrumbs";
import ViewBookingDetailsBundleItems from "../../components/history/ViewBookingDetailsBundleItems";
import ConfirmBookingBundleDetails from "../../components/ConfirmBookingBundleDetails";
import ConfirmBookingDateSelection from "../../components/ConfirmBookingDateSelection";
import ViewHistoryItemInfo from "../../components/history/ViewBundleCharacteristicsAndDescription";
import ConfirmBookingSuccessModal from "../../components/modals/ConfirmBookingSuccessModal";
import {useHistory} from "react-router-dom";
import LocalLoader from "../../components/LocalLoader";

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
}

const UserConfirmBooking: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const bundleID: string = query.get("bundle");
	const startTimeFromQuery: number = Number(query.get("start"));

	const [bundle, setBundle] = useState<Bundle>(undefined);
	const [localLoading, setLocalLoading] = useState(true);
	const [available, setAvailable] = useState<CheckBundleAvailabilityResponse>(undefined);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	useEffect(() => {
		if (bundleID) {
			getBundle().then().catch();
		} else {
			history.replace("/user/home");
		}
	}, [bundleID]);

	/**
	 * Watch for changes to the start time in the query, and when bundle is also present (so shouldn't run on first render),
	 * call the function that gets the availability information.
	 *
	 */
	useEffect(() => {
		if (bundle && startTimeFromQuery !== undefined) {
			checkAvailability().then().catch();
		}
	}, [startTimeFromQuery, bundle]);

	/**
	 * Call the api to get the bundle based on the query parameter from previous page.
	 *
	 */
	async function getBundle(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BundlesApi(getConfig(props.fullToken)).getBundle({
				id: bundleID,
			});

			setBundle(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * When user changes time in the input, instead of using a useState to track the form value,
	 * we'll update the query and just also read back the start time from the query where we need it.
	 *
	 * @param startTime
	 */
	function handleTimeChange(startTime: number): void {
		history.replace(`/user/bookings/confirm?bundle=${bundleID}&start=${startTime}`)
	}

	/**
	 * Call the api to check if the bundle is available at the selected time & save response.
	 * Uses a local loading variable instead of Redux.
	 *
	 */
	async function checkAvailability(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new BookingsApi(getConfig(props.fullToken)).checkBundleAvailability({
				id: bundleID,
				bookBundleBody: {
					date: startTimeFromQuery,
				},
			});

			setAvailable(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	/**
	 * Call the api to book the bundle & show the success modal on success.
	 *
	 */
	async function submitBookingConfirmation(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new BookingsApi(getConfig(props.fullToken)).bookBundle({
				id: bundleID,
				bookBundleBody: {
					date: startTimeFromQuery,
				},
			});

			setShowSuccessModal(true);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	if (!bundle) {
		return null;
	}

	return (
		<React.Fragment>
			<ConfirmBookingSuccessModal
				isOpen={showSuccessModal}
				bundle={bundle}
			/>

			{localLoading && <LocalLoader/>}
			<Container className="authenticated-user-page">
				<BreadCrumbs
					crumbs={[
						{
							label: `${bundle?.name}`,
							route: `/user/bookings/preview?bundle=${bundle?._id}`,
						},
						{
							label: "Review Booking",
							route: `/user/bookings/confirm?bundle=${bundle?._id}`,
						},
					]}
				/>

				<h1 className="mb-4">{bundle?.name}</h1>

				<div className="card-page-split-layout-container">
					<div className="card-page-col-left">
						<h2 className="m-0">Details</h2>
						<ConfirmBookingDateSelection
							bundle={bundle}
							loading={localLoading}
							startTime={startTimeFromQuery}
							onChangeStartTime={handleTimeChange}
						/>

						<ViewHistoryItemInfo
							characteristics={bundle?.requiredCharacteristics}
							description={bundle?.description}
						/>

						<ViewBookingDetailsBundleItems items={bundle?.items}/>
					</div>

					<div className="card-page-col-right">
						<h2 className="m-0">Bundle</h2>
						<ConfirmBookingBundleDetails
							bundle={bundle}
							loading={localLoading}
							availability={available}
							onSubmit={submitBookingConfirmation}
						/>
					</div>
				</div>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UserConfirmBooking);
