import {MetaAction} from "./MetaReducer";
import {IAction} from "../IAction";
import {APIError, Token, User} from "client";
import {isAPIError} from "../../utils/isAPIError";

export function login(fullToken: Token): IAction<MetaAction.LOGIN, Token> {
	return {
		type: MetaAction.LOGIN,
		payload: fullToken,
	}
}

export function logout(): IAction<MetaAction.LOGOUT> {
	return {
		type: MetaAction.LOGOUT,
	}
}

export function incrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: -1,
	}
}

const makeDefaultError: () => APIError = () => ({message: "An unknown error has occurred. Please contact support or try your request again."});
export async function addError(error?: {json: () => Promise<APIError>} | APIError): Promise<IAction<MetaAction.ADD_ERROR, any>> {
	let _error: APIError;

	try {
		if (isAPIError(error)) {
			_error = error;
		} else {
			try {
				const jsonResponse = await (error as {json: () => Promise<APIError>}).json();
				if (isAPIError(jsonResponse)) {
					_error = jsonResponse
				} else {
					_error = makeDefaultError()
				}
			} catch (e) {
				_error = makeDefaultError();
			}
		}
	} catch (e) {
		_error = makeDefaultError();
	}

	return {
		type: MetaAction.ADD_ERROR,
		payload: _error,
	}
}

export function removeError(i: number = 0): IAction<MetaAction.REMOVE_ERROR, number> {
	return {
		type: MetaAction.REMOVE_ERROR,
		payload: i,
	}
}

export function toggleSideBar(open: boolean): IAction<MetaAction.SIDEBAR, boolean> {
	return {
		type: MetaAction.SIDEBAR,
		payload: open,
	}
}

export function updateCurrentUser(user: User): IAction<MetaAction.UpdateCurrentUser, User> {
	return {
		type: MetaAction.UpdateCurrentUser,
		payload: user,
	}
}
