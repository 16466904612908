import React from "react";
import AttributesInput from "../AttributesInput";

interface IProps {
	attributes: Array<[string, string]>;
	handleAddAttribute: (tuple: [string, string]) => void;
	handleAttributeOnChange: (index: number, tuple: [string, string]) => void;
	handleRemoveAttribute: (index: number) => void;
}

const AddInventoryAttributes: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card add-inventory-attributes">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Attributes
				</h2>
			</div>

			<div className="outside-card-section-flush">
				<AttributesInput
					attributes={props.attributes}
					handleAddAttribute={props.handleAddAttribute}
					handleAttributeOnChange={props.handleAttributeOnChange}
					handleRemoveAttribute={props.handleRemoveAttribute}
				/>
			</div>
		</div>
	);
};

export default AddInventoryAttributes;
