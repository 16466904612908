import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
    children?: ReactNode;
    className?: string;
}

const OutsideActivitiesModalFooter: React.FC<IProps> = (props) => {
    return (
        <div className={classNames("outside-activities-modal_footer", props.className)}>
            {props.children}
        </div>
    );
}

export default OutsideActivitiesModalFooter;
