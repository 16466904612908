import React, {ReactNode} from "react";
import BundlePreviewItem from "./BundlePreviewItem";
import {Bundle} from "client";

interface IProps {
	bundles?: Array<Bundle>;
}

const BundlePreviewItemComponent: React.FC<IProps> = (props) => {

	function createBundleItem(bundle: Bundle, index: number): ReactNode {
		return (
			<BundlePreviewItem
				key={`bundle-item-${index}`}
				image={bundle.thumbnail ? bundle.thumbnail?.url : "/images/placeholder.png"}
				totalItems={bundle.items?.length || 0}
				name={bundle.name}
				description={bundle.description}
				to={`/user/bookings/preview?bundle=${bundle._id}`}
			/>
		);
	}

	return (
		<div className="bundle-preview">
			{props.bundles && props.bundles.length > 0 ? (
				props.bundles.map(createBundleItem)
			) : (
				<p className="bundle-preview__empty-item">No recommended bundles available.</p>
			)}
		</div>
	);
}

export default BundlePreviewItemComponent;
