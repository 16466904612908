import React, {ReactNode} from "react";

interface IProps {
	attributes: { [key: string]: string; };
}

const ViewInventoryItemAttributes: React.FC<IProps> = (props) => {

	function renderAttributes(attribute: [string, string], i: number): ReactNode {
		return (
			<div
				key={`attribute_${i}`}
				className="view-inventory-item-attributes_list_item"
			>
				<p>{attribute[0]}</p>
				<p>{attribute[1]}</p>
			</div>
		);
	}

	return (
		<div className="outside-card view-inventory-item-attributes">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Attributes
				</h2>
			</div>

			<div className="outside-card-section-flush">
				{(props.attributes && Object.entries(props.attributes)) ? (
					<div className="view-inventory-item-attributes_list">
						{Object.entries(props.attributes).map(renderAttributes)}
					</div>
				) : (
					<p className="view-inventory-item-attributes_empty-message">

					</p>
				)}
			</div>
		</div>
	);
};

ViewInventoryItemAttributes.defaultProps = {
	attributes: {},
};

export default ViewInventoryItemAttributes;
