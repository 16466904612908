import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
}

const OutsideCardFormButtons: React.FC<IProps> = (props) => {

	const numChildren: number = React.Children.count(props.children);

	return (
		<div
			className={classNames("outside-card-section", "outside-card-form-buttons", {
				"outside-card-form-buttons_single-child": numChildren === 1,
				"outside-card-form-buttons_multi-child": numChildren > 1,
			})}
		>
			{props.children}
		</div>
	);
};

export default OutsideCardFormButtons;
