import React from "react";
import OutsideActivitiesModal from "../OutsideActivitiesModal";
import OutsideActivitiesModalBody from "../OutsideActivitiesModalBody";
import OutsideActivitiesModalFooter from "../OutsideActivitiesModalFooter";
import OutsideButton2 from "../buttons/OutsideButton2";
import {Link} from "react-router-dom";

interface IProps {
	isOpen: boolean;
}

const UserAnsweredAllAvailableQuestionsModal: React.FC<IProps> = (props) => {

	return (
		<OutsideActivitiesModal isOpen={props.isOpen}>
			<OutsideActivitiesModalBody>
				<h3 className="text-center">
					All Questions Answered!
				</h3>

				<p className="text-center my-4">
					You've answered all questions currently available.<br/>
					Your answers can be updated any time in your{" "}<Link
					to="/user/account/questionnaire-results">profile</Link>.
					<br/><br/>
					You'll now see bundles that are most relevant to you when browsing.
				</p>
			</OutsideActivitiesModalBody>

			<OutsideActivitiesModalFooter>

				<Link to="/user/home">
					<OutsideButton2 color="forestGreen">
						Browse Bundles
					</OutsideButton2>
				</Link>
			</OutsideActivitiesModalFooter>
		</OutsideActivitiesModal>
	);
};

export default UserAnsweredAllAvailableQuestionsModal;
