import {CSSProperties} from "react";

export function svgSizer(size: number | string = "1em"): CSSProperties {
    return {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
        maxHeight: size,
        maxWidth: size,
    }
}
