import React, {ReactNode} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {User, UserType} from "client";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";

interface ITabItem {
	label: string;
	route: string;
	selected: boolean;
}

interface IProps {
	currentUser?: User;
	tabs: Array<ITabItem>;
	disabled?: boolean; // Use to disable all links for whatever reason; ie disabling navigation while calling an api with local loading UI handling.
}

const PageTabSwitcher: React.FC<IProps> = (props) => {

	/**
	 * Render each tab / navigation Link with dynamic classNames based on certain criteria.
	 * Some of the CSS removes pointer events as a path of least resistence to "disable" the links in some scenarios.
	 * This is generally done so the user can't keep clicking the same link in the UI and see nothing happen while
	 * they add the same page over and over again into their history stack.
	 *
	 * @param tab
	 * @param i
	 */
	function renderTabItems(tab: ITabItem, i: number): ReactNode {
		return (
			<Link
				key={`page-tab-switcher-item_${i}`}
				to={tab.route}
				className={classNames("page-tab-switcher_item", {
					"page-tab-switcher_item_disabled": props.disabled,
					"page-tab-switcher_item_selectable": !tab.selected,
					"page-tab-switcher_item_selected": tab.selected,
					"page-tab-switcher_item_user-selected": tab.selected && props.currentUser?.type === UserType.STANDARDUSER,
					"page-tab-switcher_item_admin-selected": tab.selected && (props.currentUser?.type === UserType.COMPANYADMIN || props.currentUser?.type === UserType.PARTNERADMIN),
				})}
			>
				{tab.label}
			</Link>
		);
	}

	return (
		<div className="page-tab-switcher">
			{props.tabs.map(renderTabItems)}
		</div>
	);
};

PageTabSwitcher.defaultProps = {
	tabs: [],
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(PageTabSwitcher);
