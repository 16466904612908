import {ContextFunctionCell, TableData, TableDataEntryJSON} from "frame-one-table/build/contextTypes";
import React, {ReactNode} from "react";
import {ColumnOption} from "frame-one-table";
import {FaCaretDown, FaCaretUp} from "react-icons/all";

const FrameOneTableHeaderCell: ContextFunctionCell<ReactNode> = (
	value: any,
	row: TableDataEntryJSON,
	key: string,
	data: TableData,
	column: ColumnOption,
) => {

	return (
		<div className="frame-one-table-header-cell">
			<span>
				{value}
			</span>

			{column.sortable && (
				<div className="frame-one-sorting-icons-container">
					<FaCaretUp
						className="frame-one-sorting-icons-container__icon__up"
					/>

					<FaCaretDown
						className="frame-one-sorting-icons-container__icon__down"
					/>
				</div>
			)}
		</div>
	);
};

export default FrameOneTableHeaderCell;
