import React, {ReactNode} from "react";
import classNames from "classnames";
import {IoCloseOutline} from "react-icons/all";

export interface IModalBody {
    children?: ReactNode;
    className?: string;
    onToggle?: () => void;
}

const OutsideActivitiesModalBody: React.FC<IModalBody> = (props) => {

    return (
        <div className={classNames("outside-activities-modal_body", props.className)}>
            {props.onToggle && (
                <div className="outside-activities-modal_body_close-icon-container">
                    <div
                        className="outside-activities-modal_body_close-icon-container_inner"
                        onClick={props.onToggle}
                    >
                        <IoCloseOutline/>
                    </div>
                </div>
            )}

            {props.children}
        </div>
    );
}

export default OutsideActivitiesModalBody;
