import React from "react";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const PasswordSavedModal: React.FC<IProps> = (props) => {

    function closeHelper(): void {
        props.onClose();
    }

    return (
        <React.Fragment>
            <FrameModal
                isOpen={props.isOpen}
                toggle={closeHelper}
            >
                <FrameModalHeader
                    toggle={closeHelper}
                    title="Password saved"
                />

                <FrameModalBody>
                    <p>
                        Your password was successfully saved.
                    </p>
                </FrameModalBody>

                <FrameModalFooter>
                    <OutsideActivitiesButton
                        color="forestGreen"
                        onClick={closeHelper}
                    >
                        Close
                    </OutsideActivitiesButton>
                </FrameModalFooter>
            </FrameModal>
        </React.Fragment>
    );
}

export default PasswordSavedModal;