"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressToJSON = exports.AddressFromJSONTyped = exports.AddressFromJSON = void 0;
const _1 = require("./");
function AddressFromJSON(json) {
    return AddressFromJSONTyped(json, false);
}
exports.AddressFromJSON = AddressFromJSON;
function AddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': _1.GeoJsonTypeFromJSON(json['type']),
        'placeID': json['placeID'],
        'formattedAddress': json['formattedAddress'],
        'coordinates': json['coordinates'],
    };
}
exports.AddressFromJSONTyped = AddressFromJSONTyped;
function AddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': _1.GeoJsonTypeToJSON(value.type),
        'placeID': value.placeID,
        'formattedAddress': value.formattedAddress,
        'coordinates': value.coordinates,
    };
}
exports.AddressToJSON = AddressToJSON;
