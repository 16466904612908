import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {persistor, store} from "./redux";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "rsuite/dist/rsuite.min.css"; // second date picker css file
import "./style/index.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor} loading={null}>
			<App/>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
