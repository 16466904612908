import React, { ReactNode } from "react";
import {BundleItem} from "client";
import PreviewBundleItemIndividual from "./PreviewBundleItemIndividual";

interface IProps {
	bundleItems: Array<BundleItem>;
}

const PreviewBundleItemList: React.FC<IProps> = (props) => {

	function renderBundleItem(item: BundleItem, i: number): ReactNode {
		return (
			<PreviewBundleItemIndividual
				key={`bundle-item_${i}`}
				item={item}
			/>
		);
	}

	return (
		<div className="preview-bundle-item-list">
			{props.bundleItems?.map(renderBundleItem)}
		</div>
	);
};

PreviewBundleItemList.defaultProps = {
	bundleItems: [],
};

export default PreviewBundleItemList;
