import React from "react";
import {Bundle} from "client";
import moment from "moment";
import OutsideDatePicker from "./inputs/OutsideDatePicker";
import {Label} from "reactstrap";
import {AiOutlineClockCircle, FaFlagCheckered} from "react-icons/all";
import {valueOrBust} from "../utils/valueOrBust";
import IconHeaderLabel from "./labels/IconHeaderLabel";

interface IProps {
	bundle: Bundle;
	loading: boolean;
	startTime: number;
	onChangeStartTime: (startTime: number) => void;
}

const ConfirmBookingDateSelection: React.FC<IProps> = (props) => {

	/**
	 * Convert to ms for our form... Using moment here might not be needed but... y'know...
	 *
	 * @param d
	 */
	function onStartDateChange(d: Date): void {
		props.onChangeStartTime(moment(d).valueOf());
	}

	const bundleDurationInDays: number = (props.bundle.bookingTime / 1000 / 60 / 60 / 24);

	return (
		<div className="outside-card confirm-booking-date-selection">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Booking Date
				</h2>
			</div>

			<div className="outside-card-section confirm-booking-date-selection_info">
				<div className="confirm-booking-date-selection_info_col">
					<Label>
						Start Date
					</Label>
					<OutsideDatePicker
						disabled={props.loading}
						oneTap={true}
						ranges={[]}
						onChange={onStartDateChange}
						disabledDate={(d) => {
							return moment(d).valueOf() < moment().add(2, "day").startOf("day").valueOf()
						}}
						cleanable={false}
						value={moment(props.startTime).toDate()}
					/>
				</div>

				<div className="confirm-booking-date-selection_info_col">
					<IconHeaderLabel
						icon={AiOutlineClockCircle}
						header="Duration"
						subheader={bundleDurationInDays + " day" + (bundleDurationInDays === 1 ? "" : "s")}
					/>
				</div>

				<div className="confirm-booking-date-selection_info_col">
					<IconHeaderLabel
						icon={FaFlagCheckered}
						header="End Date"
						subheader={valueOrBust(moment(props.startTime + props.bundle.bookingTime).format("LL"))}
					/>
				</div>
			</div>
		</div>
	);
};

export default ConfirmBookingDateSelection;
