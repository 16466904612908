import React from "react";

interface IProps {
    children: string;
    color?: string;
}

const MiniTag: React.FC<IProps> = (props) => {
    return (
        <div className={`mini-tag-${props.color}`}>
            {props.children}
        </div>
    );
}

MiniTag.defaultProps = {
    color: "blueLight",
};

export default MiniTag;