import React from "react";
import placeholderImage from "../images/male-profile-picture-placeholder-vector-600w-450966901.webp";

interface IProps {
    name?: string;
    location?: string;
    image?: string;
}

const ProfileImageMini: React.FC<IProps> = (props) => {
    return (
        <div className="profile-image-mini">
            <div className="profile-image-mini_image-container">
                <div className="profile-image-mini_image-container_inner-container">
                    <img src={props.image} alt="placeholder image"/>
                </div>
            </div>

            <div className="profile-image-mini_text-container">
                <p className="profile-image-mini_text-container_name">
                    {props.name}
                </p>

                <p className="profile-image-mini_text-container_location">
                    {props.location}
                </p>
            </div>
        </div>
    );
}

ProfileImageMini.defaultProps = {
    image: placeholderImage,
};

export default ProfileImageMini;