import React, {ReactNode} from "react";
import {FileWithSRC} from "../../../utils/renderAssetsHelper";
import RemovableImage from "../../RemovableImage";
import {Asset} from "client";
import {isFileWithSRC} from "../../../utils/fileTypeChecks";
import {isAsset} from "../../../utils/typeGuards";

interface IProps {
	images: Array<Asset | FileWithSRC>;
	onClick?: (index: number) => void;
}

const AddEquipmentAdditionalImagesGallery: React.FC<IProps> = (props) => {

	/**
	 * Render each individual image & attach the onClick.
	 *
	 * @param image
	 * @param i
	 */
	function renderAdditionalImages(image: Asset | FileWithSRC, i: number): ReactNode {

		function onClickHelper(): void {
			props.onClick(i);
		}

		let src: string;
		if (isFileWithSRC(image)) {
			src = image.imageSRC as string;
		} else if (isAsset(image)) {
			src = image.url;
		}

		return (
			<RemovableImage
				key={`add-equipment-image_${i}`}
				src={src}
				onClick={props.onClick ? onClickHelper : undefined}
				className="add-equipment-additional-images-gallery_item"
			/>
		);
	}

	return (
		<div className="add-equipment-additional-images-gallery">
			{props.images.map(renderAdditionalImages)}
		</div>
	);
};

export default AddEquipmentAdditionalImagesGallery;
