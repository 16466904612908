"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAnswerOptionBodyToJSON = exports.CreateAnswerOptionBodyFromJSONTyped = exports.CreateAnswerOptionBodyFromJSON = void 0;
function CreateAnswerOptionBodyFromJSON(json) {
    return CreateAnswerOptionBodyFromJSONTyped(json, false);
}
exports.CreateAnswerOptionBodyFromJSON = CreateAnswerOptionBodyFromJSON;
function CreateAnswerOptionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'answer': json['answer'],
        'points': json['points'],
        'characteristic': json['characteristic'],
    };
}
exports.CreateAnswerOptionBodyFromJSONTyped = CreateAnswerOptionBodyFromJSONTyped;
function CreateAnswerOptionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'answer': value.answer,
        'points': value.points,
        'characteristic': value.characteristic,
    };
}
exports.CreateAnswerOptionBodyToJSON = CreateAnswerOptionBodyToJSON;
