import React from "react";
import {StandardUser} from "client";

interface IProps {
	user: StandardUser;
}

const CompanyAdminManageUsersProfileImageCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell company-admin-manage-users-profile-image-cell">
			<div className="company-admin-manage-users-profile-image-cell_image-container">
				<img
					src={props.user?.image?.url || "/images/placeholder.png"}
					alt={props.user?.firstName}
				/>
			</div>
		</div>
	);
};

export default CompanyAdminManageUsersProfileImageCell;
