import React, {ReactNode} from "react";
import {IoCloseOutline} from "react-icons/all";
import {svgSizer} from "../utils/svgSizer";
import classNames from "classnames";

interface IProps {
	icon?: ReactNode;
	iconClassName?: string;
	children: string;
	onClick?: () => void;
	size?: string | number;
}

const FullTag: React.FC<IProps> = (props) => {

	/**
	 * Helper for rendering the icon properly in JSX.
	 *
	 */
	function renderIcon(): ReactNode {
		const Icon: any = props.icon;

		return (<Icon className={props.iconClassName}/>);
	}

	return (
		<div
			className={classNames("full-tag", {
				"full-tag_hover": props.onClick !== undefined,
			})}
			onClick={props.onClick}
		>
			{props.icon && (
				<div className="full-tag__activity-icon">
					{renderIcon()}
				</div>
			)}

			<div className="full-tag__label">
				{props.children}
			</div>

			{props.onClick && (
				<div className="full-tag__close-icon">
					<IoCloseOutline/>
				</div>
			)}
		</div>
	);
}

FullTag.defaultProps = {
	iconClassName: "text-danger",
	size: 20,
};

export default FullTag;
