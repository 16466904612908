"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionToJSON = exports.QuestionFromJSONTyped = exports.QuestionFromJSON = void 0;
const _1 = require("./");
function QuestionFromJSON(json) {
    return QuestionFromJSONTyped(json, false);
}
exports.QuestionFromJSON = QuestionFromJSON;
function QuestionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'question': json['question'],
        'answerOptions': (json['answerOptions'].map(_1.AnswerOptionFromJSON)),
    };
}
exports.QuestionFromJSONTyped = QuestionFromJSONTyped;
function QuestionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'disabled': value.disabled,
        'question': value.question,
        'answerOptions': (value.answerOptions.map(_1.AnswerOptionToJSON)),
    };
}
exports.QuestionToJSON = QuestionToJSON;
