import React from "react";
import {Container} from "reactstrap";
import OutsideButton2 from "../../components/buttons/OutsideButton2";
import {FiChevronRight} from "react-icons/all";
import OutsideTextButton2 from "../../components/buttons/OutsideTextButton2";
import { Link } from "react-router-dom";

const UserQuestionnaireStart: React.FC = () => {

	return (
		<Container className="authenticated-user-page user-questionnaire-start">
			<div className="user-questionnaire-start_image-container">
				<img
					src="/images/survey-intro.png"
					alt="survey intro"
				/>
			</div>

			<div className="user-questionnaire-start_text-content">
				<h1>
					Questionnaire
				</h1>

				<p>
					Outside Activities recommends you activities and bundles based on a profile we build by asking you
					questions. We will begin by asking you questions at random from our question bank. The more
					questions you answer, the better your recommendations will be!
				</p>

				<p>
					Though we recommend answering ten questions for now, you may answer as many questions as you like, and continue the questionnaire at any point later.
				</p>

				<div className="user-questionnaire-start_text-content_start-link-container">
					<Link
						to="/user/questionnaire/question"
						className="user-questionnaire-start_text-content_start-link"
					>
						<OutsideButton2
							color="forestGreen"
							rightIcon={FiChevronRight}
						>
							Start
						</OutsideButton2>
					</Link>
				</div>

				<Link
					to="/user/home"
					className="user-questionnaire-start_text-content_skip-link"
				>
					Skip for now
				</Link>
			</div>
		</Container>
	);
};

export default UserQuestionnaireStart;
