import React, {ReactNode, useEffect, useState} from "react";
import {Container} from "reactstrap";
import NavBox from "../components/NavBox";
import {Answer, CompanyAdmin, GetAnswersResponse, Question, QuestionsApi, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import ManageQuestionnaireOptionsCell from "../components/tables/cells/ManageQuestionnaireOptionsCell";
import formatQuestionStatus from "../utils/formatQuestionStatus";
import BreadCrumbs from "../components/BreadCrumbs";
import {useWindowDimensions} from "../utils/hooks/useWindowDimensions";
import getConfig from "../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import OutsideTableContainer from "../components/tables/OutsideTableContainer";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: CompanyAdmin;
}

const QuestionnaireResultsPage: React.FC<IProps> = (props) => {

	const windowDimensions = useWindowDimensions();

	const [questionResults, setQuestionResults] = useState<GetAnswersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>({
		...defaultFrontendPagination,
		limit: 15,
	});

	useEffect(() => {
		getQuestionnaireResults().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Call to api to get Questionnaire results.
	 *
	 */
	async function getQuestionnaireResults(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new QuestionsApi(getConfig(props.fullToken)).getOwnAnswers({
				...frontendPagination,
			});

			setQuestionResults(res);
		} catch (e) {
			props.dispatch(addError(e))
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Renderer for the questionnaire options cells
	 */
	function makeOptionsCell(value: never, answer: Answer): ReactNode {
		return (
			<ManageQuestionnaireOptionsCell
				answer={answer}
				onDone={getQuestionnaireResults}
			/>
		);
	}

	return (
		<Container className="questionnaire-results-page">
			<div className="questionnaire-results-page__container">
				<div className="questionnaire-results-page__container__body-breadcrumbs__mobile">
					<BreadCrumbs
						crumbs={[
							{
								label: "Account",
								route: "/user/account/personal-info",
							},
							{
								label: "Questionnaire Results",
								route: "/user/account/questionnaire-results",
							},
						]}
					/>
				</div>

				<div className="card-page-split-layout-container_aside">
					<div className="card-page-col-left">
						<div className="questionnaire-results-page__container__navbox">
							<div className="questionnaire-results-page__container__navbox-spacer"/>

							<div className="questionnaire-results-page__container__navbox-position">
								<NavBox
									user={props?.currentUser}
								/>
							</div>
						</div>
					</div>

					<div className="card-page-col-right">
						<div className="questionnaire-results-page__container__body">
							<div className="questionnaire-results-page__container__body-container">
								<div className="questionnaire-results-page__container__body-breadcrumbs">
									<BreadCrumbs
										crumbs={[
											{
												label: "Account",
												route: "/user/account/personal-info",
											},
											{
												label: "Questionnaire Results",
												route: "/user/account/questionnaire-results",
											},
										]}
									/>
								</div>

								{/* TODO somehow clean this up later*/}
								<div
									className="questionnaire-results-page__container__body_table-container"
									style={{
										maxHeight: (windowDimensions.width > 575) ? (
											`${windowDimensions.height - 300}px`
										) : (
											`${windowDimensions.height - 545}px`
										),
										maxWidth: (windowDimensions.width > 575) ? (
											`${windowDimensions.width - (windowDimensions.width / 2.25)}px`
										) : (
											`${windowDimensions.width - 25}px`
										)
									}}
								>
									<OutsideTableContainer
										data={questionResults?.answers}
										pagination={{
											...questionResults?.paginationInfo,
											...frontendPagination,
										}}
										onPaginationChange={setFrontendPagination}
										columnOptions={[
											{
												key: "question",
												headerValue: "Question",
												showSortIcons: false,
												sortable: false,
												valueFormatter: (question: Question) => question.question,
											},
											{
												key: "answer",
												headerValue: "Your Answer",
												showSortIcons: false,
												sortable: false,
												valueFormatter: (answer: string = "") => answer?.length > 30 ? answer.substring(0, 30) + "..." : answer,
											},
											{
												key: "",
												headerValue: "Update",
												showSortIcons: false,
												sortable: false,
												cellRender: makeOptionsCell,
												headerCellClassName: "justify-content-center",
												rowCellClassName: "justify-content-center",
											},
										]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(QuestionnaireResultsPage);
