import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	className?: string;
}

const FrameOneButtonActions: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("frame-one-button-actions", props.className)}>
			{props.children}
		</div>
	);
};

export default FrameOneButtonActions;
