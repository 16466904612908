"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerToJSON = exports.PartnerFromJSONTyped = exports.PartnerFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PartnerFromJSON(json) {
    return PartnerFromJSONTyped(json, false);
}
exports.PartnerFromJSON = PartnerFromJSON;
function PartnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : _1.PhoneNumberFromJSON(json['phoneNumber']),
        'address': !runtime_1.exists(json, 'address') ? undefined : _1.AddressFromJSON(json['address']),
        'image': !runtime_1.exists(json, 'image') ? undefined : _1.AssetFromJSON(json['image']),
    };
}
exports.PartnerFromJSONTyped = PartnerFromJSONTyped;
function PartnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'phoneNumber': _1.PhoneNumberToJSON(value.phoneNumber),
        'address': _1.AddressToJSON(value.address),
        'image': _1.AssetToJSON(value.image),
    };
}
exports.PartnerToJSON = PartnerToJSON;
