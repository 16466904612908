import React, {ChangeEvent, useEffect, useState} from "react";
import {DialCode, UtilsApi} from "client";
import getConfig from "../../utils/getConfig";
import {connect} from "react-redux";
import {addError} from "../../redux/meta/MetaActions";
import {Input} from "reactstrap";
import {find} from "lodash";
import classNames from "classnames";

interface IProps {
	dispatch?: any;
	placeholder?: string;
	value: string;
	valueKey: keyof DialCode;
	onChange: (dialCodePart: string) => void;
}

const CountryCodeSelector: React.FC<IProps> = (props) => {

	const [dialCodes, setDialCodes] = useState<Array<DialCode>>(undefined);

	useEffect(() => {
		getDialCodes().then().catch();
	}, []);

	/**
	 * Call api to get list of dial codes, foregoing the loading indicator for better UX.
	 *
	 */
	async function getDialCodes(): Promise<void> {
		try {
			const res = await new UtilsApi(getConfig()).getListOfDialCodes();
			setDialCodes(res);
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	/**
	 * Find and return the correct dial code from the list based on user input.
	 *
	 * @param e
	 */
	function onChangeHelper(e: ChangeEvent<HTMLInputElement>): void {
		props.onChange(find(dialCodes, ["code", e.target.value])?.[props.valueKey]);
	}

	return (
		<Input
			type="select"
			placeholder={props.placeholder}
			value={props.value}
			onChange={onChangeHelper}
			className={classNames({"text-mediumGray": !props.value})}
		>
			{dialCodes === undefined ? (
				<option disabled>Loading...</option>
			) : (
				<React.Fragment>
					<option
						selected={!props.value}
						value=""
					>
						Select
					</option>

					{dialCodes.map((fullDialCode: DialCode, i: number) => {
						const isSelected: boolean = props.value === fullDialCode[props.valueKey];

						return (
							<option
								key={`country-code_${i}`}
								value={fullDialCode.code}
							>
								{isSelected ? fullDialCode.dialCode : `${fullDialCode.name} (${fullDialCode.dialCode})`}
							</option>
						);
					})}
				</React.Fragment>
			)}
		</Input>
	);
};

CountryCodeSelector.defaultProps = {
	placeholder: "CA",
};

export default connect()(CountryCodeSelector);
