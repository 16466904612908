import React, {ReactNode, useState} from "react";
import {Asset, Bundle} from "client";
import classNames from "classnames";

interface IProps {
	bundle: Bundle;
}

const PreviewBundleGallery: React.FC<IProps> = (props) => {

	const [selectedIndex, setSelectedIndex] = useState(0);

	// Combined array of the main thumbnail and the first 3 additional images.
	const combinedAssets: Array<Asset> = [props.bundle.thumbnail].concat(props.bundle.images.slice(0, 3));

	/**
	 * Render each of the combined gallery images and add functionality for "selecting" one.
	 *
	 * @param image
	 * @param i
	 */
	function renderGalleryImage(image: Asset, i: number): ReactNode {
		function onSelect(): void {
			setSelectedIndex(i);
		}

		return (
			<div
				key={`bundle-gallery-image_${i}`}
				onClick={onSelect}
				className={classNames("preview-bundle-gallery_gallery-container_item", {
					"preview-bundle-gallery_gallery-container_item_selected": selectedIndex === i,
				})}
			>
				<img
					src={image.url}
					alt="Bundle Gallery Image"
				/>
			</div>
		);
	}

	return (
		<div className="preview-bundle-gallery">
			<div
				className={classNames("preview-bundle-gallery_thumbnail-container", {
					"preview-bundle-gallery_thumbnail-container_capped": props.bundle?.images?.length > 0,
				})}
			>
				<img
					src={combinedAssets[selectedIndex]?.url || "/images/placeholder.png"}
					alt={props.bundle?.name}
				/>
			</div>

			{combinedAssets.length > 1 && (
				<div className="preview-bundle-gallery_gallery-container">
					{combinedAssets.map(renderGalleryImage)}
				</div>
			)}
		</div>
	);
};

export default PreviewBundleGallery;
