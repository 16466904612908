import React, {ChangeEvent, ChangeEventHandler, ReactNode, useState} from "react";
import {Asset, AssetsApi, EquipmentApi, TimeInterval, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Container} from "reactstrap";
import {
	defaultCreateEquipmentForm,
	ICreateEquipmentBodyFrontend
} from "../../components/modals/PartnerInventoryAddStockModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {NumberFormatValues} from "react-number-format";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import {cloneDeep} from "lodash";
import AddInventoryPicture from "../../components/inventory/AddInventoryPicture";
import AddInventoryDetails from "../../components/inventory/AddInventoryDetails";
import AddInventoryGallery from "../../components/inventory/AddInventoryGallery";
import AddInventoryAttributes from "../../components/inventory/AddInventoryAttributes";
import AddInventorySchedule from "../../components/inventory/AddInventorySchedule";
import {useHistory} from "react-router-dom";
import BreadCrumbs, {IBreadCrumbItem} from "../../components/BreadCrumbs";
import {filterAttributeTuples} from "../../utils/filterAttributeTuples";
import OutsideButton2 from "../../components/buttons/OutsideButton2";

const breadCrumbs: Array<IBreadCrumbItem> = [
	{
		label: "Inventory",
		route: "/partner/inventory",
	},
	{
		label: "Add",
		route: "/partner/inventory/add",
	}
];

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const PartnerAddInventory: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [createForm, setCreateForm] = useState<ICreateEquipmentBodyFrontend>(defaultCreateEquipmentForm);

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof ICreateEquipmentBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateForm({
				...createForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange for the consumable radio buttons.
	 *
	 * @param _consumable
	 */
	function consumableOnChange(_consumable: boolean): void {
		setCreateForm({
			...createForm,
			isConsumable: _consumable,
		});
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data for the quantity.
	 *
	 * @param values
	 */
	function numberFormatOnChange(values: NumberFormatValues): void {
		setCreateForm({
			...createForm,
			quantity: values.floatValue,
		});
	}

	/**
	 * Custom onChange for the thumbnail input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onThumbnailChange(e: ChangeEvent<HTMLInputElement> = undefined): Promise<void> {
		setCreateForm({
			...createForm,
			thumbnail: e ? (await addURLsToFiles(e?.target.files))[0] : undefined,
		});
	}

	/**
	 * Handle adding the new time interval.
	 *
	 * @param interval
	 */
	function onAddAvailability(interval: TimeInterval): void {
		const availabilityCopy: Array<TimeInterval> = cloneDeep(createForm.availability);
		availabilityCopy.push(interval);

		setCreateForm({
			...createForm,
			availability: availabilityCopy,
		});
	}

	/**
	 * Handle removing the specified time interval.
	 *
	 * @param index
	 */
	function onRemoveAvailability(index: number): void {
		const availabilityCopy: Array<TimeInterval> = cloneDeep(createForm.availability);
		availabilityCopy.splice(index, 1);

		setCreateForm({
			...createForm,
			availability: availabilityCopy,
		});
	}

	/**
	 * Custom onChange for the additional images input, to add usable URL.
	 *
	 * @param e
	 */
	async function onAdditionalImagesChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newImages = await addURLsToFiles(e?.target.files);

		setCreateForm({
			...createForm,
			images: createForm.images.concat(newImages),
		});
	}

	/**
	 * Splice the selected image from the array and update state.
	 *
	 * @param index
	 */
	function onRemoveAdditionalImage(index: number): void {
		const imagesCopy: Array<FileWithSRC> = cloneDeep(createForm?.images);
		imagesCopy.splice(index, 1);

		setCreateForm({
			...createForm,
			images: imagesCopy,
		});
	}

	/**
	 * Add the new attribute & value to the list.
	 *
	 * @param tuple
	 */
	function handleAddAttribute(tuple: [string, string]): void {
		const attributesCopy: Array<[string, string]> = cloneDeep(createForm?.attributes);
		attributesCopy.push(tuple);

		setCreateForm({
			...createForm,
			attributes: attributesCopy,
		});
	}

	/**
	 * Update the specific attribute tuple.
	 *
	 * @param index
	 * @param tuple
	 */
	function handleAttributeOnChange(index: number, tuple: [string, string]): void {
		const attributesCopy: Array<[string, string]> = cloneDeep(createForm?.attributes);
		attributesCopy[index] = tuple;

		setCreateForm({
			...createForm,
			attributes: attributesCopy,
		});
	}

	/**
	 * Remove the specified attribute.
	 *
	 * @param index
	 */
	function handleRemoveAttribute(index: number): void {
		const attributesCopy: Array<[string, string]> = cloneDeep(createForm?.attributes);
		attributesCopy.splice(index, 1);

		setCreateForm({
			...createForm,
			attributes: attributesCopy,
		});
	}

	/**
	 * Convert the assets to images in our system, then submit the createEquipment api with the returned asset ids,
	 * navigate to the view page afterwards.
	 *
	 * @param e
	 */
	async function submitNewStock(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			// Upload the thumbnail image
			let thumbnailIdRes: Asset;
			if (createForm.thumbnail) {
				thumbnailIdRes = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: createForm.thumbnail,
				});
			}

			// Upload the additional gallery images
			let galleryAssets: Array<Asset> = [];
			if (createForm.images.length > 0) {
				galleryAssets = await Promise.all(createForm.images.map(async (asset): Promise<Asset> => {
					return await new AssetsApi(getConfig(props.fullToken)).createAsset({
						asset,
					});
				}));
			}

			const equipment = await new EquipmentApi(getConfig(props.fullToken)).createEquipment({
				createEquipmentBody: {
					isConsumable: createForm.isConsumable,
					quantity: createForm.quantity || undefined,
					availability: createForm.availability,
					name: createForm.name || undefined,
					description: createForm.description || undefined,
					instructions: createForm.instructions || undefined,
					placeID: createForm.placeID,
					attributes: Object.fromEntries(filterAttributeTuples(createForm.attributes)),
					thumbnailID: thumbnailIdRes?._id,
					imageIDs: galleryAssets.map(a => a._id),
				},
			});

			history.push(`/partner/inventory/view?e=${equipment?._id}`);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	const saveButton: ReactNode = (
		<OutsideButton2
			color="safetyOrange"
			onClick={submitNewStock}
		>
			Save Item
		</OutsideButton2>
	);

	return (
		<Container className="authenticated-user-page">
			<BreadCrumbs crumbs={breadCrumbs}/>

			<div className="card-page-header-row-responsive">
				<h1 className="card-page-header-row_title">
					Add New Stock Item
				</h1>

				{saveButton}
			</div>

			<div className="card-page-split-layout-container">
				<div className="card-page-col-left">
					<AddInventoryDetails
						name={createForm.name}
						description={createForm.description}
						isConsumable={createForm.isConsumable}
						quantity={createForm.quantity}
						instructions={createForm.instructions}
						dynamicOnChange={dynamicOnChange}
						consumableOnChange={consumableOnChange}
						numberFormatOnChange={numberFormatOnChange}
					/>

					<AddInventoryAttributes
						attributes={createForm.attributes}
						handleAddAttribute={handleAddAttribute}
						handleAttributeOnChange={handleAttributeOnChange}
						handleRemoveAttribute={handleRemoveAttribute}
					/>
				</div>

				<div className="card-page-col-right">
					<AddInventoryPicture
						thumbnail={createForm.thumbnail}
						onThumbnailChange={onThumbnailChange}
					/>

					<AddInventorySchedule
						availability={createForm.availability}
						onAddAvailability={onAddAvailability}
						onRemoveAvailability={onRemoveAvailability}
					/>

					<AddInventoryGallery
						images={createForm.images}
						onAdditionalImagesChange={onAdditionalImagesChange}
						onRemoveAdditionalImage={onRemoveAdditionalImage}
					/>
				</div>
			</div>

			<div className="card-page-mobile-button-actions">
				{saveButton}
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(PartnerAddInventory);
