import React, {ReactNode} from "react";
import classNames from "classnames";
import {User} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface IProps {
    children: string;
    currentUser?: User;
    icon?: ReactNode;
    className?: string;
    active?: boolean;
    onClick?: () => void;
}

const SideBarButton: React.FC<IProps> = (props) => {

    const Icon: any = props.icon;

    function onClickHelper(): void {
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <button
            className={classNames("side-bar-button", props.className)}
            onClick={onClickHelper}
        >
            {Icon && (
                <Icon
                    color={props.active ? "safety-orange" : undefined}
                />
            )}

            <span className="biryani_bold">
				{props.children}
			</span>
        </button>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        currentUser: store.metaStore.currentUser,
        ...props,
    }
})(SideBarButton);
