"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckTokenExpirationResponseToJSON = exports.CheckTokenExpirationResponseFromJSONTyped = exports.CheckTokenExpirationResponseFromJSON = void 0;
function CheckTokenExpirationResponseFromJSON(json) {
    return CheckTokenExpirationResponseFromJSONTyped(json, false);
}
exports.CheckTokenExpirationResponseFromJSON = CheckTokenExpirationResponseFromJSON;
function CheckTokenExpirationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expired': json['expired'],
    };
}
exports.CheckTokenExpirationResponseFromJSONTyped = CheckTokenExpirationResponseFromJSONTyped;
function CheckTokenExpirationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expired': value.expired,
    };
}
exports.CheckTokenExpirationResponseToJSON = CheckTokenExpirationResponseToJSON;
