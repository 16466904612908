import {Asset, CompanyAdmin, PartnerAdmin, StandardUser, User, UserType} from "client";
import {FileWithSRC} from "./renderAssetsHelper";

export function isStandardUser(user: User): user is StandardUser {
    return user?.type === UserType.STANDARDUSER;
}

export function isCompanyAdmin(user: User): user is CompanyAdmin {
    return user?.type === UserType.COMPANYADMIN;
}

export function isPartnerAdmin(user: User): user is PartnerAdmin {
    return user?.type === UserType.PARTNERADMIN;
}

export function isAsset(asset: Asset | FileWithSRC): asset is Asset {
    if (!asset) {
        return false;
    }

    return asset.hasOwnProperty("redirectURL");
}
