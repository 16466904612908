import React from "react";
import classNames from "classnames";
import {useHover} from "../../utils/hooks/useHover";

interface IProps {
	icon: React.FC;
	rounded?: boolean;
	inverted?: boolean;
	invertedHoverColor?: string;
	className?: string;
}

const OutsideIconButton: React.FC<IProps> = (props) => {

	const [hoverRef, isHovered] = useHover<HTMLDivElement>();

	const Icon = props.icon;

	return (
		<div
			ref={hoverRef}
			className={classNames("outside-icon-button", props.className, {
				"outside-icon-button_rounded": props.rounded,
				"outside-icon-button_inverted": props.inverted,
				[`border-${props.invertedHoverColor}`]: props.inverted && isHovered,
				[`text-${props.invertedHoverColor}`]: props.inverted && isHovered,
			})}
		>
			<Icon/>
		</div>
	);
};

OutsideIconButton.defaultProps = {
	rounded: false,
	inverted: false,
	invertedHoverColor: "safetyOrange",
}

export default OutsideIconButton;
