import React, {ReactNode, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {CompanyAdmin, GetStandardUsersResponse, StandardUser, Token, User, UsersApi} from "client";
import getConfig from "../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AiOutlinePlus} from "react-icons/all";
import {Container, Input, Label} from "reactstrap";
import OutsideActivitiesButton from "../components/OutsideActivitiesButton";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import AddNewUserModal from "../components/modals/AddNewUserModal";
import PageHeader from "../components/PageHeader";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import ManageUsersDeleteCell from "../components/tables/cells/ManageUsersDeleteCell";
import CompanyPhoto from "../components/CompanyPhoto";
import BreadCrumbs, {IBreadCrumbItem} from "../components/BreadCrumbs";
import OutsideButton2 from "../components/buttons/OutsideButton2";
import OutsideTableContainer from "../components/tables/OutsideTableContainer";
import {valueOrBustDynamic} from "../utils/valueOrBust";
import CompanyAdminManageUsersProfileImageCell from "../components/CompanyAdminManageUsersProfileImageCell";
import {generateUserFullName} from "../utils/generateUserFullName";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: CompanyAdmin;
}

const CompanyManageUsers: React.FC<IProps> = (props) => {

	const [users, setUsers] = useState<GetStandardUsersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddNewUserModal, setShowAddNewUserModal] = useState<boolean>(false);

	useEffect(() => {
		readUsers().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Show or Hide the Create User Modal.
	 *
	 */
	function toggleAddNewUserModal(): void {
		setShowAddNewUserModal(!showAddNewUserModal);
	}

	/**
	 * Hide the Create User Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneAddNewUserModal(): void {
		setShowAddNewUserModal(false);
		readUsers().then().catch();
	}

	/**
	 * Calling API to get all the Users & save to state.
	 *
	 */
	async function readUsers(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getStandardUsers({
				...frontendPagination,
			});

			setUsers(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Profile Picture Cells.
	 *
	 * @param value
	 * @param user
	 */
	function makeImageCell(value: never, user: StandardUser): ReactNode {
		return (
			<CompanyAdminManageUsersProfileImageCell user={user}/>
		);
	}

	/**
	 * Renders the disabled boolean to a string.
	 *
	 * @param value
	 * @param user
	 */
	function makeDisabledCell(value: never, user: User) {
		return (
			user.disabled.toString()
		);
	}

	/**
	 * Renderer for the Delete User Cells
	 */
	function makeDeleteCell(value: never, user: User): ReactNode {
		return (
			<ManageUsersDeleteCell
				user={user}
				onDone={readUsers}
			/>
		);
	}

	return (
		<React.Fragment>
            <AddNewUserModal
                isOpen={showAddNewUserModal}
                onClose={toggleAddNewUserModal}
                onDone={onDoneAddNewUserModal}
            />

            <Container className="authenticated-user-page">
                <div className="table-page-header-row" style={{marginBottom: "5rem !important"}}>
                    <h1 className="table-page-header-row_title">Users</h1>

                    <OutsideButton2
                        color="safetyOrange"
                        onClick={toggleAddNewUserModal}
                    >
                        Create New User
                    </OutsideButton2>
                </div>

                <div className="partner-inventory-page_table-container">
                    <OutsideTableContainer
                        data={users?.standardUsers}
                        pagination={{
                            ...users?.paginationInfo,
                            ...frontendPagination,
                        }}
                        onPaginationChange={setFrontendPagination}
                        columnOptions={[
                            {
                                key: "image",
                                headerValue: undefined,
                                showSortIcons: false,
                                sortable: false,
                                cellRender: makeImageCell,
                            },
                            {
                                key: "",
                                headerValue: "Name",
                                showSortIcons: false,
                                sortable: false,
                                valueFormatter: (value: never, user: StandardUser) => generateUserFullName(user),
                            },
                            {
                                key: "email",
                                headerValue: "Email",
                                showSortIcons: false,
                                sortable: false,
                            },
                            {
                                key: "phoneNumber",
                                headerValue: "Phone number",
                                showSortIcons: false,
                                sortable: false,
                                valueFormatter: valueOrBustDynamic(formatPhoneNumber),
                            },
                            // {
                            //     key: "disabled",
                            //     headerValue: "Disabled",
                            //     showSortIcons: false,
                            //     sortable: false,
                            //     cellRender: makeDisabledCell,
                            // },
                            {
                                key: undefined,
                                headerValue: "Delete",
                                showSortIcons: false,
                                sortable: false,
                                cellRender: makeDeleteCell,
                                headerCellClassName: "justify-content-center",
                                rowCellClassName: "justify-content-center",
                            },
                        ]}
                    />
                </div>
            </Container>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser as CompanyAdmin,
		...props,
	}
})(CompanyManageUsers);
