import React, {ReactNode, useState} from "react";
import OutsideActivitiesButton from "../OutsideActivitiesButton";
import AddInventoryDateRangeModal from "./AddInventoryDateRangeModal";
import {TimeInterval} from "client";
import FullTag from "../FullTag";
import moment from "moment";
import OutsideButton2 from "../buttons/OutsideButton2";

interface IProps {
	availability: Array<TimeInterval>;
	onAddAvailability: (interval: TimeInterval) => void;
	onRemoveAvailability: (index: number) => void;
}

const AddInventorySchedule: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or show the modal.
	 *
	 */
	function toggleShowModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * When done selecting a time range, pass the interval to the parent form & close the modal.
	 *
	 * @param interval
	 */
	function onDoneModal(interval: TimeInterval): void {
		props.onAddAvailability(interval);
		setShowModal(false);
	}

	/**
	 * Render each interval & attach the onClick / remove functionality.
	 *
	 * @param _interval
	 * @param i
	 */
	function renderTimeInterval(_interval: TimeInterval, i: number): ReactNode {
		function removeHelper(): void {
			props.onRemoveAvailability(i)
		}

		return (
			<FullTag onClick={removeHelper}>
				{`${moment(_interval.start).format("MM/DD/YY")} → ${moment(_interval.end).format("MM/DD/YY")}`}
			</FullTag>
		);
	}

	return (
		<React.Fragment>
			<AddInventoryDateRangeModal
				isOpen={showModal}
				onDone={onDoneModal}
				onClose={toggleShowModal}
			/>

			<div className="outside-card add-inventory-schedule">
				<div className="outside-card-section">
					<h2 className="outside-card-header">
						Scheduling
					</h2>
				</div>

				<div className="outside-card-section">
					<div className="add-inventory-schedule_availabilities">
						{(props.availability && props.availability.length > 0) ? (
							<div className="add-inventory-schedule_availabilities_tags">
								{props.availability.map(renderTimeInterval)}
							</div>
						) : (
							<div className="add-inventory-schedule_availabilities_empty-message-container">
								<p>
									No Availabilities
								</p>
							</div>
						)}
					</div>

					<div className="add-inventory-schedule_button-container">
						<OutsideButton2
							color="safetyOrange"
							narrow={true}
							onClick={toggleShowModal}
						>
							Add Availability
						</OutsideButton2>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AddInventorySchedule;
