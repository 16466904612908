"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class AdminsApi extends runtime.BaseAPI {
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLoginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/admins/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminLoginBodyToJSON(requestParameters.adminLoginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLogin(requestParameters) {
        const response = await this.adminLoginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to create new admin accounts
     */
    async createAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateAdminBodyToJSON(requestParameters.createAdminBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AdminFromJSON(jsonValue));
    }
    /**
     * Used to create new admin accounts
     */
    async createAdmin(requestParameters) {
        const response = await this.createAdminRaw(requestParameters);
        return await response.value();
    }
}
exports.AdminsApi = AdminsApi;
