import React from "react";
import {Asset} from "client";
import AddEquipmentAdditionalImagesGallery from "../modals/modalComponents/AddEquipmentAdditionalImagesGallery";

interface IProps {
	images: Array<Asset>
}

const ViewInventoryItemGallery: React.FC<IProps> = (props) => {

	return (
		<div className="outside-card view-inventory-item-gallery">
			<div className="outside-card-section">
				<h2 className="outside-card-header">
					Gallery Images
				</h2>
			</div>

			<div className="outside-card-section">
				{(props.images && props.images.length > 0) ? (
					<AddEquipmentAdditionalImagesGallery images={props.images}/>
				) : (
					<p className="view-inventory-item-gallery_empty-message">
						No Gallery Images.
					</p>
				)}
			</div>
		</div>
	);
};

export default ViewInventoryItemGallery;
